/* eslint-disable no-bitwise */
import moment from 'moment';

export function getDayOfMonth(datetime) {
  let daysInMonth = moment(datetime).daysInMonth();
  const arrDaysInMonth = [];
  while (daysInMonth) {
    const current = moment(datetime).date(daysInMonth);
    arrDaysInMonth.push({ [moment(current).format('MM月DD')]: 0 });
    daysInMonth -= 1;
  }
  return arrDaysInMonth.reduce((a, b) => Object.assign(a, b), {});
}

export function getMonthOfYear(year = moment().format('YYYY')) {
  return Array.apply(0, Array(12))
    .map((_, i) => Object({ [moment().month(i).year(year).format('MM月')]: 0 }))
    .reduce((a, b) => Object.assign(a, b), {});
}

export const pad = (n) => (n > 9 ? `${n}` : `0${n}`);

export const durationDisplay = (counter) => {
  const days = ~~(counter / 86400);
  const remain = counter - days * 86400;
  const hrs = ~~(remain / 3600);
  const min = ~~((remain - hrs * 3600) / 60);
  const sec = ~~(remain % 60);
  return `${hrs > 0 ? `${pad(hrs)}:` : ''}${pad(min)}:${pad(sec)}`;
};
