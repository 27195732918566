import React, { useState } from 'react';
import { Spin, Result, Layout, Button, Image } from 'antd';
import { useQuery, useMutation } from '@apollo/client';
import StandardTable from 'components/StandardTable';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

import TopicModal from './TopicModal';
import { CREATE_TOPIC, GET_ALL_TOPIC, UPDATE_TOPIC } from '../schema';

const Topic = () => {
  const [modalVisible, handleModalVisible] = useState(false);
  const [selectedTopic, selectTopic] = useState();
  const { data, loading, error } = useQuery(GET_ALL_TOPIC);
  const [createTopic] = useMutation(CREATE_TOPIC, {
    onCompleted: () => {
      handleModalVisible(false);
    },
  });
  const [updateTopic] = useMutation(UPDATE_TOPIC, {
    onCompleted: () => {
      handleModalVisible(false);
    },
  });

  const handleSubmit = async (values, id) => {
    if (id) {
      updateTopic({
        variables: { id, input: values },
        refetchQueries: [GET_ALL_TOPIC, 'getAllTopic'],
      });
    } else {
      createTopic({
        variables: {
          input: values,
        },
        refetchQueries: [GET_ALL_TOPIC, 'getAllTopic'],
      });
    }
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Result status="error" title="Fail to fetch data" />;
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      key: 'image',
      render: (value) => <Image src={value} />,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (val, record) => (
        <>
          <EditOutlined
            style={{ fontSize: 20 }}
            onClick={() => {
              selectTopic(record);
              handleModalVisible(true);
            }}
          />
        </>
      ),
    },
  ];

  return (
    <Layout.Content className="flex flex-col">
      <StandardTable
        dataSource={data?.getAllTopic || []}
        loading={loading}
        columns={columns}
        rowKey="id"
        renderToolbar={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              selectTopic(null);
              handleModalVisible(true);
            }}
          >
            <PlusOutlined /> Add
          </Button>,
        ]}
        pagination={false}
        scroll={{ x: 375 }}
      />
      <TopicModal
        visible={modalVisible}
        data={selectedTopic}
        onCancel={() => handleModalVisible(false)}
        headerTitle="Topic"
        onSubmit={handleSubmit}
      />
    </Layout.Content>
  );
};

export default Topic;
