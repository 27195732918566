import { gql } from '@apollo/client';

export const CLOSE_ROOM_TICKET = gql`
  mutation AdminCloseRoom($adminCloseRoomId: Int!) {
    adminCloseRoom(id: $adminCloseRoomId) {
      id
    }
  }
`;

export const GET_LIST_ROOM_TICKET = gql`
  query adminGetRoomlist($filter: JSON, $sort: JSON, $pageSize: Int, $page: Int) {
    adminGetRoomList(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      total
      results {
        id
        title
        start
        status
        type
        totalTicket
        totalTicketSold
        price
        creator {
          id
          username
        }
      }
    }
  }
`;

export const GET_ROOM_TICKET = gql`
  query AdminGetRoomTicket($roomId: Int, $filter: JSON, $pageSize: Int, $page: Int) {
    adminGetRoomTicket(roomId: $roomId, filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        orderId
        refundOrderId
        paymentMethod
        status
        created
        user {
          username
          id
          firstName
          lastName
          email
        }
      }
      currentPage
      pageSize
      total
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation AdminUpdateRoomTicket($adminUpdateRoomTicketId: Int, $input: UpdateRoomTicketInput) {
    adminUpdateRoomTicket(id: $adminUpdateRoomTicketId, input: $input) {
      id
      roomId
    }
  }
`;

export const REOPEN_ROOM = gql`
  mutation AdminReopenTicketRoom($adminReopenTicketRoomId: Int!) {
    adminReopenTicketRoom(id: $adminReopenTicketRoomId) {
      id
    }
  }
`;
