import { useMutation } from '@apollo/client';
import { Modal, Switch, message } from 'antd';
import { CREATE_COUPON, GET_LIST_COUPON, UPDATE_COUPON } from 'common/coupon.gql';
import { DateInput, InputFormik, NumberInput, SelectInput, TextFieldInput } from 'components/Input';
import { FastField, Field, Formik } from 'formik';
import { capitalize, pick } from 'lodash';
import React, { memo } from 'react';
import { COUPON_STATUS, COUPON_TYPE } from 'utils/constant';

import * as Yup from 'yup';

const DEFAULT_VALUES = {
  status: COUPON_STATUS.active,
  type: COUPON_TYPE.all,
};

const validationSchema = Yup.object().shape({
  code: Yup.string().required(),
  discount: Yup.number().required(),
  limit: Yup.number().required(),
});

const ModalCoupon = ({ opened, onCancel, initialValues, editable }) => {
  const [doCreate, { loading: createLoading }] = useMutation(CREATE_COUPON, {
    refetchQueries: [GET_LIST_COUPON],
    onError: (error) => message.error(error?.message),
    onCompleted: () => {
      message.success('Created');
      onCancel();
    },
  });

  const [doUpdate, { loading: updateLoading }] = useMutation(UPDATE_COUPON, {
    onCompleted: () => {
      message.success('Updated');
      onCancel();
    },
    onError: (error) => message.error(error?.message),
  });

  const onSubmit = (values) => {
    const input = pick(values, [
      'code',
      'description',
      'discount',
      'maximum',
      'limit',
      'expiredAt',
      'type',
      'targetId',
      'status',
    ]);
    if (editable) doUpdate({ variables: { adminUpdateCouponId: initialValues.id, input } });
    else doCreate({ variables: { input } });
  };

  return (
    <Formik
      initialValues={{ ...DEFAULT_VALUES, ...initialValues, description: initialValues?.description || '' }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, submitForm }) => {
        return (
          <Modal
            bodyStyle={{ minHeight: 350 }}
            title={editable ? 'Edit' : 'Create'}
            visible={opened}
            confirmLoading={createLoading || updateLoading}
            onCancel={() => !createLoading && !updateLoading && onCancel(false)}
            onOk={submitForm}
            okText="Save"
            destroyOnClose
            centered
          >
            <div className="mb-5">
              <strong>Admin ID</strong>: {initialValues?.creatorId}
            </div>
            <FastField required title="Code" name="code" component={InputFormik} />
            <FastField title="Description" name="description" component={TextFieldInput} />
            <div className="grid grid-cols-3 gap-3">
              <FastField
                title="Discount (%)"
                required
                min={0}
                max={99}
                className="!w-full"
                name="discount"
                component={NumberInput}
              />
              <FastField
                title="Maximum (VND)"
                className="!w-full"
                min={0}
                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                name="maximum"
                component={NumberInput}
              />
              <FastField title="Limit" min={0} required className="!w-full" name="limit" component={NumberInput} />
            </div>
            <FastField title="Expired at" name="expiredAt" showTime component={DateInput} allowEmpty />

            <div>
              {values.type !== COUPON_TYPE.all && (
                <div className="text-gray-400">
                  * Only <strong className="capitalize">{values.type}</strong> with id{' '}
                  <strong>{values?.targetId || 'Please select Target ID'}</strong> can use this coupon
                </div>
              )}
              <div className="grid grid-cols-2 gap-3">
                <FastField
                  title="Type"
                  name="type"
                  component={SelectInput}
                  options={Object.keys(COUPON_TYPE).map((k) => ({ label: capitalize(k), value: COUPON_TYPE[k] }))}
                />
                <Field
                  title="Target ID"
                  disabled={values.type === COUPON_TYPE.all}
                  name="targetId"
                  className="!w-full"
                  component={NumberInput}
                />
              </div>
            </div>

            <div>
              <fieldset className="flex gap-2">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="font-bold" htmlFor="status">
                  Active
                </label>
                <Switch
                  id="status"
                  label=""
                  checked={values?.status === COUPON_STATUS.active}
                  onChange={(v) => setFieldValue('status', v ? COUPON_STATUS.active : COUPON_STATUS.inactive)}
                />
              </fieldset>
              <p className="text-gray-400 mt-1 h-6">
                {values?.status === COUPON_STATUS.inactive &&
                  'This coupon has been disabled, users will not be able to use it'}
              </p>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(ModalCoupon);
