import React from 'react';
import { Button, Select, Spin, Tooltip, message } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { gql, useQuery } from '@apollo/client';
import { useGetSuggestCategory } from 'utils/hooks/usePlaylist';

const ALL_CATEGORY = gql`
  query GetAllPlaylistCategory {
    getAllPlaylistCategory {
      id
      title
      slug
      priority
    }
  }
`;

const { Option } = Select;

const PlaylistCategorySelect = ({
  error,
  handleChange,
  renderList,
  title,
  disabled,
  paramsSuggest,
  onSuggest,
  ...rest
}) => {
  const { doRequest: fetchListSuggest, loading: suggestLoading } = useGetSuggestCategory();
  const { data: dataCategory, loading } = useQuery(ALL_CATEGORY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const result = dataCategory?.getAllPlaylistCategory;
  const renderListData = () => {
    if (typeof renderList === 'function') {
      return renderList();
    }
    if (!loading && result) {
      return result?.map((element) => (
        <Option key={element.id} value={element.id}>
          {element.title}
        </Option>
      ));
    }
    return null;
  };

  const handleGetSuggest = () => {
    if (!paramsSuggest?.title && !paramsSuggest?.description) {
      message.error('Please enter a title or description');
    } else {
      fetchListSuggest(paramsSuggest, onSuggest);
    }
  };

  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">{title || 'Category'}</span>
      <div className="flex items-stretch">
        <Select
          onChange={handleChange}
          style={{ width: '100%', border: !!error && '2px solid red' }}
          loading={loading}
          mode="multiple"
          placeholder="Playlist category"
          notFoundContent={loading ? <Spin size="small" /> : null}
          disabled={disabled}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }}
          {...rest}
        >
          {renderListData()}
        </Select>

        <Tooltip title="Recommend">
          <Button
            onClick={handleGetSuggest}
            loading={suggestLoading}
            icon={<KeyOutlined />}
            type="primary"
            className="aspect-square"
          />
        </Tooltip>
      </div>
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">{error}</div>
    </div>
  );
};

export default PlaylistCategorySelect;
