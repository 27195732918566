import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, message } from 'antd';
import { ADMIN_GET_ALL_CATEGORY } from 'containers/Playlist/schema';
import React, { useEffect, useState } from 'react';
import { GET_MASTER_SETTING_LIST, UPDATE_CORE_SETTING } from '../schema';

const MAX_SELECT = 5;

const BookCategory = () => {
  const { data, loading } = useQuery(GET_MASTER_SETTING_LIST, {
    variables: { keys: ['book_category'] },
    fetchPolicy: 'network-only',
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { data: allCategory, loading: allCategoryLoading } = useQuery(ADMIN_GET_ALL_CATEGORY);
  const [updateSetting, { loading: updateLoading }] = useMutation(UPDATE_CORE_SETTING, {
    onCompleted: () => message.success('Change success!'),
    onError: (err) => message.error(err?.message),
  });

  const bookCategoryConfig = data?.getMasterSettingList?.[0];

  const onSelectChange = (rows) => {
    if (rows.length > MAX_SELECT) return;
    setSelectedRowKeys(rows);
  };

  const onSave = () => {
    return updateSetting({
      variables: { id: bookCategoryConfig?.id, value: JSON.stringify(selectedRowKeys), type: typeof selectedRowKeys },
    });
  };

  useEffect(() => {
    if (bookCategoryConfig?.id) {
      setSelectedRowKeys(JSON.parse(`${bookCategoryConfig?.value || {}}`));
    }
  }, [bookCategoryConfig?.id, bookCategoryConfig?.value]);

  return (
    <div className="flex gap-2 max-w-md">
      <Select
        mode="tags"
        loading={allCategoryLoading || loading}
        placeholder="Please select book categories"
        onChange={onSelectChange}
        value={selectedRowKeys}
        style={{ width: '100%' }}
        options={
          allCategory?.getAllPlaylistCategory
            ?.filter?.((i) => ![1, 2, 3].includes(i.priority))
            ?.map?.((i) => ({ label: i?.title, value: i?.slug })) || []
        }
      />
      <Button type="primary" loading={updateLoading} className="mb-4" onClick={onSave}>
        Save
      </Button>
    </div>
  );
};

export default BookCategory;
