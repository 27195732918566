import AuthorizedTabs from 'components/Authorized/AuthorizedTabs';
import React from 'react';
import TTS from 'components/tts';

const tablist = [
  {
    key: 'tts',
    tab: 'TTS',
    component: <TTS />,
  },
];

const Tools = () => {
  return <AuthorizedTabs tabs={tablist} />;
};

export default Tools;
