import { Button, Upload } from 'antd';
import { FileMarkdownOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { getToken } from 'store/apolloClient';

const UPLOAD_PATH = `${process.env.REACT_APP_API_URI}/upload`;

const UploadAnimationFormik = ({ form, field, className = '', title }) => {
  const { name } = field;
  const { errors, setFieldValue } = form || {};

  const onChange = async (info) => {
    if (info?.file?.status === 'done' && info?.file?.response?.uploaded) {
      setFieldValue(name, info?.file?.response?.url);
    }
  };

  const onRemoveItem = () => setFieldValue(name, null);

  const authorization = getToken() ? `Bearer ${getToken()}` : null;

  return (
    <div className={classNames('relative', className)}>
      {title && <div className="text-gray mb-2 font-bold h-5 text-18">{title}</div>}
      <Upload
        accept=".json"
        maxCount={1}
        name="upload"
        headers={{ authorization }}
        multiple={false}
        action={UPLOAD_PATH}
        onChange={onChange}
        onRemove={onRemoveItem}
      >
        <Button style={{ display: 'flex', alignItems: 'center' }} icon={<FileMarkdownOutlined />}>
          Upload file animation
        </Button>
      </Upload>

      {errors?.[name] && <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">{errors[name]}</div>}
    </div>
  );
};

export default UploadAnimationFormik;
