import React from 'react';
import { Form, Select, Spin } from 'antd';
import { gql, useQuery } from '@apollo/client';

const ALL_TOPIC = gql`
  query getAllTopic {
    getAllTopic {
      id
      title
    }
  }
`;

const { Option } = Select;

const TopicSelect = ({ handleChange, renderTopicList, title, disabled, value, showTitle = true }) => {
  const { data, loading } = useQuery(ALL_TOPIC);
  const result = data?.getAllTopic;
  const renderTopic = () => {
    if (typeof renderTopicList === 'function') {
      return renderTopicList();
    }
    if (!loading && result) {
      return result?.map((element) => (
        <Option key={element.id} value={element.id}>
          {element.title} <br />
        </Option>
      ));
    }
    return null;
  };

  return (
    <div className="flex flex-col">
      {showTitle && <span className="text-gray mb-2 font-bold h-5 text-18">{title || 'Topic'}</span>}
      <Select
        onChange={handleChange}
        mode="multiple"
        showSearch
        value={value}
        style={{ width: '100%' }}
        filterOption={false}
        placeholder="Please select topic"
        notFoundContent={loading ? <Spin size="small" /> : null}
        disabled={disabled}
      >
        {renderTopic()}
      </Select>
    </div>
  );
};

export default TopicSelect;
