import { Alert } from 'antd';
import React from 'react';
import { useLogin } from 'utils/hooks/useLogin';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoginForm from './components/LoginForm';

import styles from './styles.module.css';

const { UserName, Password, Submit } = LoginForm;

const LoginMessage = ({ content }) => <Alert style={{ marginBottom: 24 }} message={content} type="error" showIcon />;
LoginMessage.propTypes = {
  content: PropTypes.string,
};

const Login = () => {
  const { loginUser, submitting, error } = useLogin();
  const handleSubmit = (values) => {
    loginUser(values);
  };

  return (
    <div className={styles.main}>
      <LoginForm onSubmit={handleSubmit}>
        {error && !submitting && <LoginMessage content="Wrong username or password" />}
        <UserName
          name="username"
          placeholder="Username"
          rules={[
            {
              required: true,
              message: 'Enter valid username',
            },
          ]}
        />
        <Password
          name="password"
          placeholder="Password"
          rules={[
            {
              required: true,
              message: 'Enter password',
            },
          ]}
        />
        <div className={styles.pullRight}>
          <Link to="/password/forgot">Forgot Password</Link>
        </div>
        <Submit className="w-full h-6 mt-4" loading={submitting}>
          Login
        </Submit>
      </LoginForm>
    </div>
  );
};

export default Login;
