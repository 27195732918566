import React, { useState, useCallback } from 'react';
import { Layout, Card, Button, message, Spin, Popconfirm } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { OPERATOR, ROOM_STATUS, ROOM_TYPE } from 'utils/constant';
import { debounce, omitBy, isUndefined, isNull } from 'lodash';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import moment from 'moment';
import TopicSelect from 'components/TopicSelect';
import { Formik, Form, FastField, Field } from 'formik';
import { DateInput, InputFormik, SelectInput, TextFieldInput } from 'components/Input';
import UploadImageInput from 'components/UploadImageInput';
import CampSelect from 'components/CampSelect';
import { GET_ROOM, ALL_USERS, EDIT_ROOM, CLO_ROOMS } from '../schema';
import { roomSchema } from '../schemaValidation';

const TabRoomInfo = () => {
  const { id } = useParams();

  const [input, setInput] = useState('');
  const [fetching, setFetching] = useState(false);

  const { data, loading } = useQuery(GET_ROOM, {
    variables: {
      id: parseInt(id, 10),
    },
  });

  const [adminCloseRoom] = useMutation(CLO_ROOMS);

  const [editRoom, { loading: mutationLoading }] = useMutation(EDIT_ROOM, {
    onCompleted() {
      message.success('Room updated!');
    },
    onError() {
      message.error('Update fail!');
    },
  });

  const generateFilter = (value) => {
    const newFilter = [{ field: 'username', operator: OPERATOR.contains, value }];
    return newFilter;
  };

  const { data: dataUser } = useQuery(ALL_USERS, {
    variables: {
      page: 0,
      pageSize: 10,
      ...(input ? { filter: generateFilter(input) } : {}),
    },
  });

  const onFinish = (values, { setSubmitting }) => {
    const inputForm = {
      title: values.title,
      description: values.description,
      banner: values.banner,
      roomTopic: values.topic || [],
      bannerLink: values.bannerLink,
      ...(values.type === ROOM_TYPE.TICKET ? { totalTicket: values.totalTicket || 0 } : {}),
      start: values.start,
      end: values.end,
      campId: values.campId,
      image: values.image,
      allowChat: values.allowChat,
      hostIds: values.host?.map((e) => e.value) || [],
      allowRecording: values.allowRecording && [ROOM_TYPE.PUBLIC, ROOM_TYPE.TICKET].includes(values.type),
    };
    editRoom({
      variables: {
        id: parseInt(id, 10),
        input: omitBy(inputForm, (v) => isUndefined(v) || isNull(v) || v === ''),
      },
    });
    setSubmitting(false);
  };

  const handleChange = (value) => {
    setInput(value);
    setFetching(false);
  };

  const callDebounce = useCallback(debounce(handleChange, 800), []);

  const searchHost = (event) => {
    setFetching(true);
    callDebounce(event);
  };

  if (!data?.room || loading) {
    return null;
  }

  const initialValues = {
    title: data?.room?.title || '',
    description: data?.room?.description || '',
    banner: data?.room?.banner || null,
    bannerLink: data?.room?.bannerLink || '',
    status: data?.room?.status || 'open',
    type: data?.room?.type || 'public',
    totalTicket: data?.room?.totalTicket || 0,
    image: data?.room?.image || null,
    price: data?.room?.price || 0,
    allowRecording: data?.room?.allowRecording || false,
    recordingUrl: data?.room?.recordingUrl || '',
    topic: data?.room?.topic?.map((item) => item.id) || [],
    campId: data?.room?.campId,
    start: data?.room?.start ? moment(data?.room?.start) : null,
    end: data?.room?.end ? moment(data?.room?.end) : null,
    created: moment(data?.created),
    allowChat: data?.room?.allowChat,
    host:
      data?.room?.speakers?.map?.((e) => {
        return { value: e.userId, label: e.user.username, key: e.userId };
      }) || [],
  };

  const closeRoom = () => {
    adminCloseRoom({
      variables: { id: data.room.id },
    });
  };

  return (
    <div>
      <div>
        <div>
          <div className="font-bold mb-2 text-green-600">ID: {data?.room?.id}</div>
          <Formik validationSchema={roomSchema} initialValues={initialValues} onSubmit={onFinish}>
            {({ values, isValid, isSubmitting, isDirty, resetForm, submitForm }) => {
              return (
                <Form className="grid  grid-cols-2 gap-y-2 gap-x-8">
                  <FastField name="title" title="Title" component={InputFormik} />
                  <FastField name="topic">
                    {({ form, field }) => (
                      <TopicSelect
                        value={values[field.name]}
                        handleChange={(value) => form.setFieldValue(field.name, value)}
                      />
                    )}
                  </FastField>
                  <FastField
                    autoSize={{ minRows: 6 }}
                    name="description"
                    title="Description"
                    component={TextFieldInput}
                  />
                  <div className="w-full h-42 flex gap-10">
                    <FastField
                      name="banner"
                      title="Banner"
                      size="8rem"
                      component={UploadImageInput}
                      className="flex flex-col h-full"
                      value={values?.banner}
                    />
                    <FastField
                      name="image"
                      title="Image"
                      size="8rem"
                      component={UploadImageInput}
                      useCrop
                      className="flex flex-col h-full"
                      value={values?.image}
                    />
                  </div>
                  <FastField name="bannerLink" title="Banner Link" component={InputFormik} />
                  <FastField disabled name="recordingUrl" title="Recording url" component={InputFormik} />
                  <FastField
                    name="start"
                    title="Start"
                    component={DateInput}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                  />
                  <Field
                    name="end"
                    title="End"
                    component={DateInput}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                    disabledDate={(current) => current && current <= moment(values?.start).startOf('day')}
                  />
                  <FastField
                    name="created"
                    title="Created"
                    disabled
                    component={DateInput}
                    showTime={{ format: 'HH:mm' }}
                    format="DD/MM/YYYY HH:mm"
                  />
                  <Field name="campId">
                    {({ form, field }) => (
                      <CampSelect
                        className="flex flex-col"
                        name="campId"
                        value={values?.campId}
                        showSearch
                        handleChange={(camp) => form.setFieldValue(field.name, camp)}
                      />
                    )}
                  </Field>
                  {/* FastField not update options when data change */}
                  <Field
                    name="host"
                    title="Host"
                    component={SelectInput}
                    options={
                      !fetching
                        ? dataUser?.adminGetUserList?.results?.map((host) => ({ label: host.username, value: host.id }))
                        : []
                    }
                    labelInValue
                    mode="multiple"
                    filterOption={false}
                    placeholder="Please select hosts"
                    onSearch={searchHost}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                  />

                  <div className="flex items-end gap-2">
                    <div className="grow">
                      <FastField name="type" title="Type" disabled component={InputFormik} />
                    </div>

                    {values?.type === ROOM_TYPE.TICKET && (
                      <>
                        <Field
                          min={data?.room?.totalTicket || 0}
                          name="totalTicket"
                          placeholder="Total ticket"
                          type="number"
                          component={InputFormik}
                        />
                        <Field name="price" placeholder="Price" disabled component={InputFormik} />
                      </>
                    )}
                  </div>

                  <FastField
                    name="status"
                    title="Status"
                    options={[
                      { label: 'Open', value: 'open' },
                      { label: 'Live', value: 'live' },
                      { label: 'Completed', value: 'completed' },
                    ]}
                    disabled
                    component={SelectInput}
                  />
                  <div className="flex items-center gap-8">
                    <Field name="allowRecording" title="Allow recording">
                      {({ form, field }) =>
                        [ROOM_TYPE.PUBLIC, ROOM_TYPE.TICKET].includes(values?.type) && (
                          <div className="flex flex-col items-center">
                            <span className="text-gray mb-1 font-bold h-5 text-18">Allow recording</span>
                            <Checkbox
                              disabled={data?.room?.status === ROOM_STATUS.COMPLETED}
                              checked={values[field.name] || false}
                              onChange={(check) => form.setFieldValue(field.name, check.target.checked)}
                            />
                          </div>
                        )
                      }
                    </Field>

                    <Field name="allowChat" title="Allow chat">
                      {({ form, field }) => (
                        <div className="flex flex-col items-center">
                          <span className="text-gray mb-1 font-bold h-5 text-18">Allow chat</span>
                          <Checkbox
                            checked={values[field.name] || false}
                            disabled={data?.room?.status === ROOM_STATUS.COMPLETED}
                            onChange={(check) => form.setFieldValue(field.name, check.target.checked)}
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="col-span-2 text-right">
                    <Popconfirm
                      title="Are you sure to close this room?"
                      onConfirm={() => closeRoom()}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button type="primary" danger className="mr-3">
                        Close this room
                      </Button>
                    </Popconfirm>
                    <Button htmlType="button" onClick={() => resetForm()} style={{ marginRight: 8 }}>
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting || mutationLoading}
                      disabled={!isValid || isSubmitting || isDirty}
                    >
                      Update
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default TabRoomInfo;
