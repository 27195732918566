import { gql } from '@apollo/client';

//#region FRAGMENT

const FRAGMENT_CORE_SETTING = gql`
  fragment CoreSetting on CoreSetting {
    id
    name
    key
    value
    type
  }
`;

const FRAGMENT_MEDIA = gql`
  fragment Media on Media {
    id
    name
    altText
    link
    thumbnailUrl
    height
    width
    type
    status
  }
`;

const FRAGMENT_COUPON = gql`
  fragment Coupon on Coupon {
    id
    code
    description
    expiredAt
    discount
    maximum
    limit
    type
    status
    creatorId
    targetId
    created
    updated
  }
`;

const FRAGMENT_DECORATION = gql`
  fragment Decoration on Decoration {
    id
    adminId
    border
    title
    type
  }
`;

export { FRAGMENT_MEDIA, FRAGMENT_CORE_SETTING, FRAGMENT_COUPON, FRAGMENT_DECORATION };
