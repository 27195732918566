import React from 'react';
import { Modal } from 'antd';
import { FastField, Formik } from 'formik';
import UploadImageInput from 'components/UploadImageInput';
import { InputFormik } from 'components/Input';
import { addBannerSchema } from 'common/validationSchema';

const BannerModal = ({ visible, onCancel, onSubmit, data }) => {
  const isEdit = !!data;

  const handleSubmit = (values) => {
    onSubmit(values, data?.index);
  };

  return (
    <Formik validationSchema={addBannerSchema} initialValues={isEdit ? data : {}} onSubmit={handleSubmit}>
      {({ values, submitForm }) => {
        return (
          <Modal
            visible={visible}
            title="Banner"
            onCancel={onCancel}
            onOk={submitForm}
            okText={isEdit ? 'Save' : 'Create'}
          >
            <form>
              <div className="h-32 mb-2">
                <FastField
                  name="image"
                  size="8rem"
                  component={UploadImageInput}
                  className="h-full block"
                  value={values?.image}
                />
              </div>
              <div className="mt-10">
                <FastField name="url" title="URL" component={InputFormik} />
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default BannerModal;
