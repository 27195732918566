import React from 'react';
import { Layout } from 'antd';
import HistoryTable from './components/HistoryTable';

const HistoryInfo = ({ user }) => {
  return (
    <Layout className="flex flex-col space-y-7">
      <HistoryTable user={user} />
    </Layout>
  );
};

export default HistoryInfo;
