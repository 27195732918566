import { Alert } from 'antd';
import React from 'react';
import { useForgot } from 'utils/hooks/useLogin';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LoginForm from './components/LoginForm';

import styles from './styles.module.css';

const { UserName, Submit } = LoginForm;

const LoginMessage = ({ content, type = 'error' }) => (
  <Alert style={{ marginBottom: 24 }} message={content} type={type} showIcon />
);
LoginMessage.propTypes = {
  content: PropTypes.string,
  type: PropTypes.string,
};

const Forgot = () => {
  const { forgotPassword, submitting, message, success } = useForgot();
  const handleSubmit = (values) => {
    forgotPassword(values);
  };

  return (
    <div className={styles.main}>
      <LoginForm onSubmit={handleSubmit}>
        {message && !submitting && <LoginMessage content={message} />}
        {success && !submitting && <LoginMessage content="Please check your email！" type="success" />}
        <UserName
          name="username"
          placeholder="Username"
          rules={[
            {
              required: true,
              message: 'Enter a valid username',
            },
          ]}
        />
        <div className={styles.pullRight}>
          <Link to="/login">Already have account</Link>
        </div>
        <Submit loading={submitting}>Submit</Submit>
      </LoginForm>
    </div>
  );
};

export default Forgot;
