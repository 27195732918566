/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { Checkbox } from 'antd';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';

// Type:
//     Single: Single check box
//     Group: Group check box

const CheckBoxInput = (props) => {
  const { field, form, value, title, className, type = 'single', keyValue, keyName, ...rest } = props;

  const { name } = field;

  const { setFieldValue } = form;

  const handleChange = (values) => {
    const getValueChecked = typeof values !== 'object' ? values : values.target.checked;
    setFieldValue(name, getValueChecked);
  };

  const Wrapper = (checkProps) =>
    type === 'group' ? <Checkbox.Group {...checkProps} /> : <Checkbox {...checkProps} />;

  const RenderCheckBox = ({ option }) => <Checkbox value={option[keyValue] || option.value}>{option.name}</Checkbox>;

  return (
    <div>
      <div className={className}>
        {title && <span className="text-gray font-bold h-5 text-18">{title}</span>}
        <div>
          <Wrapper {...field} {...rest} onChange={handleChange}>
            {type === 'group' &&
              value?.map((option) => (
                <RenderCheckBox key={`checkbox_${option[keyValue] || option.value}`} option={option} />
              ))}
          </Wrapper>
        </div>
      </div>
      <div className="position-absolute  text-red-500 mt-0.5 mb-1 font-normal h-5">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

CheckBoxInput.prototype = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
  keyName: PropTypes.string,
  keyValue: PropTypes.string,
  options: PropTypes.array,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default CheckBoxInput;
