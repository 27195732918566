import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import cl from 'classnames';
import { ErrorMessage } from 'formik';

const { Option } = Select;

const SelectInput = ({ field, form, options = [], title = '', className = '', ...rest }) => {
  const { errors, setFieldValue } = form;
  const { name } = field;

  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">{title}</span>
      <Select
        {...rest}
        {...field}
        style={errors[name] ? { border: '2px solid red' } : {}}
        className={cl(className)}
        onChange={(v) => {
          setFieldValue(name, v);
        }}
      >
        {options.map(({ label, value }) => (
          <Option value={value} key={`option__${title}__${value}`}>
            {label}
          </Option>
        ))}
      </Select>
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

SelectInput.prototype = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

SelectInput.defaultProps = {
  type: '',
  lable: '',
  placeholder: '',
  disabled: '',
  title: '',
};

export default SelectInput;
