import React, { useEffect, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Layout, Tag, Popconfirm, message, Button } from 'antd';
import { Link } from 'react-router-dom';
import StandardTable from 'components/StandardTable';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { PAYMENT_REQUEST_STATUS, OPERATOR } from 'utils/constant';
import moment from 'moment';

import useFilter from 'utils/hooks/useFilter';

import { GET_MASTER_SETTING_LIST } from 'containers/MasterSetting/schema';
import PaymentRequestModal from './components/PaymentRequestModal';

const DEFAULT_PAGE_SIZE = 30;

const GET_LIST_PAYMENT = gql`
  query adminGetPaymentRequestList($filter: JSON, $pageSize: Int, $page: Int) {
    adminGetPaymentRequestList(filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        user {
          id
          username
        }
        status
        amount
        created
        bankName
        bankAccountNo
        bankAccountName
      }
      total
      pageSize
      currentPage
    }
  }
`;

const ADMIN_CREATE_PAYMENT = gql`
  mutation adminCreatePaymentRequest($input: AdminPaymentRequestInput!) {
    adminCreatePaymentRequest(input: $input) {
      id
    }
  }
`;

const ADMIN_DELETE_PAYMENT = gql`
  mutation adminDeletePaymentRequest($id: Int!) {
    adminDeletePaymentRequest(id: $id) {
      id
    }
  }
`;

const ADMIN_UPDATE_PAYMENT = gql`
  mutation AdminUpdatePaymentRequest($adminUpdatePaymentRequestId: Int!, $input: AdminPaymentRequestInput) {
    adminUpdatePaymentRequest(id: $adminUpdatePaymentRequestId, input: $input) {
      id
      status
      amount
      payDate
    }
  }
`;

const DATE_FORMAT = 'DD/MM/YYYY';

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    switch (filter) {
      case 'status':
        newFilter.push({ field: filter, operator: OPERATOR.in, value: filters[filter] });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const PaymentRequest = () => {
  const [modalVisible, handleModalVisible] = useState(false);
  const { data: masterSettingList } = useQuery(GET_MASTER_SETTING_LIST, {
    variables: { keys: ['vnd_rate'] },
  });
  const exchangePrice = masterSettingList?.getMasterSettingList?.[0]?.value || 'Loading...';

  const { data, refetch, loading } = useQuery(GET_LIST_PAYMENT, {
    variables: { page: 0, pageSize: DEFAULT_PAGE_SIZE },
  });

  const filter = useFilter(['status', 'page', 'pageSize']);
  const { pageSize = 30, status, page = 1 } = filter.querySearch;

  useEffect(() => {
    if (filter.querySearch) {
      refetch({
        page: Number(page - 1),
        pageSize: Number(pageSize),
        filter: generateFilter(filter.querySearch),
      });
    }
  }, [pageSize, page, status]);

  const handleChangeTable = (pagination, filters) => {
    const { current, pageSize: pS } = pagination;
    filter.onSearch({ page: current, pageSize: pS, ...filters });
  };

  const [createPaymentRequest, { loading: creating }] = useMutation(ADMIN_CREATE_PAYMENT);
  const [updatePaymentRequest] = useMutation(ADMIN_UPDATE_PAYMENT, {
    onError(e) {
      message.error(e?.message);
    },
  });
  const [deletePaymentRequest] = useMutation(ADMIN_DELETE_PAYMENT);

  const handleCreateRequest = async (values) => {
    createPaymentRequest({
      variables: {
        input: values,
      },
      refetchQueries: [GET_LIST_PAYMENT, 'adminGetPaymentRequestList'],
    });
  };

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: 'User',
      dataIndex: 'user',
      render: (val) => <Link to={`user/info/${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Bank',
      dataIndex: 'bankName',
    },
    {
      title: 'Account name',
      dataIndex: 'bankAccountName',
    },
    {
      title: 'Account number',
      dataIndex: 'bankAccountNo',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value) => `${value / 100}$`,
    },
    {
      title: 'Exchange Price',
      render: () => exchangePrice,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      render: (value) => {
        return moment(value).format(DATE_FORMAT);
      },
    },
    // {
    //   title: 'Paid',
    //   dataIndex: 'payDate',
    //   render: (value) => {
    //     return moment(value).format(DATE_FORMAT);
    //   },
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: Object.keys(PAYMENT_REQUEST_STATUS).map((key) => ({
        text: PAYMENT_REQUEST_STATUS[key],
        value: key,
      })),
      render: (value, record) => {
        const isPaid = value === 'completed';
        console.log({ record });
        return (
          <Popconfirm
            title="Mark this request as completed?"
            disabled={isPaid}
            onConfirm={() => {
              updatePaymentRequest({
                variables: {
                  adminUpdatePaymentRequestId: record.id,
                  input: { userId: record.user.id, status: 'completed' },
                },
              });
            }}
          >
            <Tag style={{ cursor: isPaid ? 'default' : 'pointer' }} color={isPaid ? 'green' : '#f50'}>
              {value}
            </Tag>
          </Popconfirm>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (val) => (
        <>
          <Popconfirm
            title="Are you sure to delete this request?"
            onConfirm={() =>
              deletePaymentRequest({
                variables: { id: val },
                refetchQueries: [GET_LIST_PAYMENT, 'adminGetPaymentRequestList'],
              })
            }
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const pagination = {
    current: data?.adminGetPaymentRequestList?.currentPage + 1 || 1,
    pageSize: data?.adminGetPaymentRequestList?.pageSize || DEFAULT_PAGE_SIZE,
    total: data?.adminGetPaymentRequestList?.total,
  };
  return (
    <Layout.Content className="flex flex-col md:p-4 p-2 space-y-4">
      <StandardTable
        dataSource={data?.adminGetPaymentRequestList?.results || []}
        loading={loading}
        columns={columns}
        rowKey="id"
        renderToolbar={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              handleModalVisible(true);
            }}
          >
            <PlusOutlined /> Add
          </Button>,
        ]}
        scroll={{ x: 375 }}
        pagination={pagination}
        onChange={handleChangeTable}
      />
      {modalVisible && (
        <PaymentRequestModal
          isLoading={creating}
          visible={modalVisible}
          onCancel={() => handleModalVisible(false)}
          headerTitle="Payment"
          onSubmit={handleCreateRequest}
        />
      )}
    </Layout.Content>
  );
};

export default PaymentRequest;
