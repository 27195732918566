import { uploadRecording } from 'utils/request';

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const customRequest = async ({ onSuccess, onError, file, onProgress }) => {
  try {
    const res = await uploadRecording(file, {
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    });
    onSuccess(res);
  } catch (err) {
    console.log('Eroor: ', err);
    onError({ err });
  }
};
