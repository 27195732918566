import { useQuery } from '@apollo/client';
import { EditOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import StandardTable from 'components/StandardTable';
import { useMemo, useState } from 'react';
import useFilter from 'utils/hooks/useFilter';
import { ADMIN_GET_ALL_CATEGORY } from './schema';
import ModalCreateCategory from './components/CreateCategory';

const Category = () => {
  const { querySearch, onSearch } = useFilter(['search']);
  const { data, loading } = useQuery(ADMIN_GET_ALL_CATEGORY, {
    fetchPolicy: 'cache-and-network',
  });

  const [valCurrent, setValCurrent] = useState(null);

  const listCategory = useMemo(() => {
    if (!Array.isArray(data?.getAllPlaylistCategory)) return [];
    if (!querySearch?.search?.trim?.()) return data.getAllPlaylistCategory;
    return data.getAllPlaylistCategory.filter((i) =>
      i.title.toLowerCase().includes(querySearch.search.toLocaleLowerCase())
    );
  }, [data?.getAllPlaylistCategory, querySearch?.search]);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      width: 150,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      sort: true,
    },
    {
      title: '',
      render: (val) => <Button icon={<EditOutlined />} onClick={() => setValCurrent(val)} />,
    },
  ];

  return (
    <div>
      <StandardTable
        rowKey="id"
        loading={loading}
        renderToolbar={() => [
          <Input
            key="search"
            placeholder="Search by title"
            defaultValue={querySearch?.search}
            onChange={({ target }) => onSearch({ search: target.value })}
          />,
          // <Button type="primary" key="primary" onClick={() => setValCurrent({})}>
          //   Create
          // </Button>,
        ]}
        dataSource={listCategory}
        columns={columns}
      />

      {!!valCurrent && (
        <ModalCreateCategory visible={!!valCurrent} data={valCurrent} onDismiss={() => setValCurrent(null)} />
      )}
    </div>
  );
};

export default Category;
