import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Menu, Spin } from 'antd';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { authState } from 'store/atom';
import { useLogin } from 'utils/hooks/useLogin';

import HeaderDropdown from './HeaderDropdown';
import styles from './styles.module.css';

const AvatarDropdown = () => {
  const history = useHistory();
  const { logoutUser } = useLogin();
  const auth = useRecoilValue(authState);

  const onMenuClick = (event) => {
    const { key } = event;

    if (key === 'logout') {
      logoutUser();
      return;
    }

    history.push(`/account/${key}`);
  };

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]} onClick={onMenuClick}>
      <Menu.Item key="profile">
        <UserOutlined />
        Profile
      </Menu.Item>
      <Menu.Item key="logout">
        <LogoutOutlined />
        Logout
      </Menu.Item>
    </Menu>
  );
  return auth.admin && auth.admin?.id ? (
    <HeaderDropdown overlay={menuHeaderDropdown}>
      <span className={`${styles.action} ${styles.account}`}>
        <span>{auth.admin.username} &nbsp;&nbsp;</span>
        <Avatar size="small" className={styles.avatar} icon={<UserOutlined />} alt="avatar" />
      </span>
    </HeaderDropdown>
  ) : (
    <span className={`${styles.action} ${styles.account}`}>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );
};

export default AvatarDropdown;
