import React from 'react';
import styles from './styles.module.css';

const GlobalFooter = ({ copyright }) => (
  <div className={styles.globalFooter}>
    <div className={styles.copyright}>{copyright || 'Hosukuru 2020'}</div>
  </div>
);

export default GlobalFooter;
