import { useQuery } from '@apollo/client';
import { Input } from 'antd';
import { useState } from 'react';
import { OPERATOR, PLAYLIST_TYPE } from 'utils/constant';
import useFilter from 'utils/hooks/useFilter';
import PlaylistTable from './components/PlaylistTable';
import { ADMIN_GET_PLAYLIST } from './schema';

const IPP = 20;
const generateFilter = (filters) => {
  const newFilter = [{ field: 'type', operator: OPERATOR.in, value: [PLAYLIST_TYPE.USER, PLAYLIST_TYPE.TOPIC] }];
  Object.keys(filters).forEach((filter) => {
    switch (filter) {
      case 'title':
        newFilter.push({ field: filter, operator: OPERATOR.fts, value: filters[filter] });
        break;
      case 'status':
      case 'type':
        newFilter.push({ field: filter, operator: OPERATOR.in, value: filters[filter] });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const Default = () => {
  const filter = useFilter(['page', 'pageSize', 'title', 'sort']);
  const { pageSize = IPP, page = 1, sort = 'desc', title } = filter.querySearch;
  const [sortState, updateSortState] = useState([]);
  const { data } = useQuery(ADMIN_GET_PLAYLIST, {
    variables: {
      page: Number(page - 1),
      pageSize: Number(pageSize),
      filter: generateFilter(filter.querySearch),
      sort: [{ field: 'created', value: sort }],
    },
  });

  const onEnterSearch = (event) => {
    filter.onSearch({ title: event.target.value, page: 1 });
  };
  const onSearch = (value) => {
    filter.onSearch({ title: value, page: 1 });
  };
  const handleTableChange = (pagination, filters, options) => {
    const { current, pageSize: currentPageSize } = pagination;
    filter.onSearch({ page: current, pageSize: currentPageSize, ...filters });
    if (options?.field === 'created') filter.onSearch({ sort: options.order === 'ascend' ? 'asc' : 'desc' });
  };

  return (
    <div>
      <Input.Search
        placeholder="Search playlist"
        style={{ width: 400 }}
        onPressEnter={onEnterSearch}
        onSearch={onSearch}
        defaultValue={title}
      />
      <PlaylistTable
        data={data?.adminGetPlaylist?.results}
        pagination={{
          reset: true,
          showSizeChanger: true,
          defaultPageSize: pageSize,
          current: Number(page),
          total: data?.adminGetPlaylist?.total || 0,
          pageSizeOptions: ['5', '10', '20', '30'],
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Default;
