const botConfig = {
  token: '5571258112:AAFkon85gmR1xUQemwTGIW-fm-4ur6n8cg0',
  chatId: -1977528781,
};
const apiRoot = `https://api.telegram.org/bot${botConfig.token}`;

export const telegramApi = {
  getBotUpdates: () => {
    fetch(`${apiRoot}/getUpdates`).then((response) => response.json());
  },
  sendMessage: (message, parseMode) => {
    fetch(`${apiRoot}/sendMessage`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        text: message,
        parse_mode: parseMode,
        chat_id: botConfig.chatId,
      }),
    });
  },
};
