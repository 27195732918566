import React from 'react';
import { Modal, message } from 'antd';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useFlag } from 'utils/hooks';
import { ERROR } from 'utils/constant';
import AdminAddEditForm from './AdminAddEditForm';
import { CREATE_ADMIN, ALL_ADMIN, UPDATE_ADMIN } from '../../schema';

const AdminModal = ({ children, adminData, type = 'add' }) => {
  const [openModal, doOpenModal, doCloseModal] = useFlag(false);
  const { id: adminId } = adminData || {};

  const onError = (err) => {
    if (err.message === ERROR.ADMIN.USERNAME_OR_EMAIL_EXISTED) {
      message.error('Username or email already exist.');
    } else {
      message.error(err.message || 'Something went wrong, please try again later.');
    }
  };

  const [addAdmin, { loading: addLoading }] = useMutation(CREATE_ADMIN, {
    onCompleted: () => {
      doCloseModal();
      message.success('Admin successfully added!');
    },
    onError,
  });

  const [updateAmin, { loading: updateLoading }] = useMutation(UPDATE_ADMIN, {
    onError,
    onCompleted: () => {
      doCloseModal();
      message.success('Admin successfully updated!');
    },
  });

  const handleAddEditAdmin = async (values) => {
    try {
      if (type === 'add') {
        await addAdmin({
          variables: { input: values },
          refetchQueries: [{ query: ALL_ADMIN }],
        });
      } else if (type === 'edit') {
        await updateAmin({
          variables: { id: adminId, input: values },
          refetchQueries: [{ query: ALL_ADMIN }],
        });
      }
    } catch (err) {
      //
    }
  };

  return (
    <>
      {children(doOpenModal)}
      {openModal && (
        <Modal
          footer={null}
          title={type === 'add' ? 'Add Admin' : 'Edit Admin'}
          width={856}
          visible={openModal}
          onCancel={doCloseModal}
        >
          <AdminAddEditForm
            type={type}
            close={doCloseModal}
            adminData={adminData}
            loading={addLoading || updateLoading}
            doAddEdit={handleAddEditAdmin}
          />
        </Modal>
      )}
    </>
  );
};

AdminModal.propTypes = {
  type: PropTypes.string,
  children: PropTypes.any,
  adminData: PropTypes.objectOf(PropTypes.any),
};
export default AdminModal;
