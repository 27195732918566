import { gql } from '@apollo/client';

export const GET_LIST_GAME_ROOM = gql`
  query adminGetGameList($filter: JSON, $pageSize: Int, $page: Int) {
    adminGetGameList(filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        name
        description
        backgroundImage
        questions {
          id
          gameType
          content
          category
          created
        }
        created
        image
      }
      total
      currentPage
      pageSize
    }
  }
`;

export const GET_LIST_QUESTION = gql`
  query adminGetGameQuestionList($filter: JSON, $pageSize: Int, $page: Int, $sort: JSON) {
    adminGetGameQuestionList(filter: $filter, pageSize: $pageSize, page: $page, sort: $sort) {
      results {
        id
        gameType
        content
        category
        created
        updated
      }
      total
      currentPage
      pageSize
    }
  }
`;

export const UPDATE_GAME = gql`
  mutation AdminUpdateGame($gameId: Int!, $input: GameInput!) {
    adminUpdateGame(gameId: $gameId, input: $input) {
      id
    }
  }
`;

export const CREATE_GAME = gql`
  mutation AdminCreateGame($input: GameInput!) {
    adminCreateGame(input: $input) {
      id
    }
  }
`;

export const DELETE_GAME = gql`
  mutation AdminDeleteGame($gameId: Int!) {
    adminDeleteGame(gameId: $gameId) {
      id
    }
  }
`;
