import React from 'react';
import { Form, Row, Col, Button, message } from 'antd';
import { FastField, Formik } from 'formik';
import UploadImageInput from 'components/UploadImageInput';
import { DateInput, InputFormik, NumberInput, SelectInput, TextFieldInput } from 'components/Input';
import PlaylistCategorySelect from 'components/PlaylistCategorySelect';
import { playlistValidationSchema } from 'common/validationSchema';
import { useMutation } from '@apollo/client';
import {
  PLAYLIST_TYPE,
  SERIES_PRODUCT_ID_OPTIONS,
  BOOK_COVER_RATIO,
  PLAYLIST_STATUS,
  THUMBNAIL_SHARING_RATIO,
  PURCHASE_TYPE,
} from 'utils/constant';
import AuthorSelect from 'components/AuthorSelect';
import { Link } from 'react-router-dom';
import TopicSelect from 'components/TopicSelect';
import capitalize from 'lodash/capitalize';
import { telegramApi } from 'utils/helpers/telegram';
import { UPDATE_PLAYLIST } from '../schema';

const FORM = {
  image: 'image',
  name: 'title',
  type: 'type',
  status: 'status',
  description: 'description',
  category: 'categoryId',
  topicId: 'topicId',
  thumbnail: 'thumbnail',
  purchasedType: 'purchasedType',
};

const PlaylistEdit = ({ data }) => {
  const [updatePlaylist, { loading: updating }] = useMutation(UPDATE_PLAYLIST, {
    onCompleted: () => message.success('Updated!'),
    onError: () => message.error('Error!'),
  });

  const doSubmit = (values) => {
    const { price, iosProductId, androidProductId, purchasedType, ...rest } = values;
    const variables = {
      adminUpdatePlaylistId: data?.id,
      input: { ...rest },
    };
    if (values.type === PLAYLIST_TYPE.AUDIOBOOK) {
      variables.input = {
        ...variables.input,
        price,
        iosProductId: iosProductId || '',
        androidProductId: androidProductId || '',
      };

      // Send notify to telegram when series published
      const previousStatus = data?.status;
      if (previousStatus !== PLAYLIST_STATUS.PUBLISHED && values.status === PLAYLIST_STATUS.PUBLISHED) {
        telegramApi.sendMessage(
          // eslint-disable-next-line max-len
          `📗|<b><i>${values.title}</i></b>| has just been published\nPreview: https://app.getonmic.com/playlist/${data.id}`,
          'html'
        );
      }
    }

    updatePlaylist({ variables });
  };

  const initialValues = {
    price: data?.price || 0,
    iosProductId: data?.iosProductId,
    androidProductId: data?.androidProductId,
    slug: data?.slug,
    [FORM.status]: data?.status,
    authorId: data?.author?.id,
    [FORM.image]: data?.image,
    [FORM.name]: data?.title,
    [FORM.description]: data?.description,
    [FORM.type]: data?.type,
    [FORM.topicId]: data?.topic?.map?.((i) => i?.id),
    [FORM.category]: data?.category?.map?.((i) => i?.id),
    [FORM.thumbnail]: data?.thumbnail,
    [FORM.purchasedType]: data?.purchasedType,
    pubDate: data?.pubDate,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={doSubmit} validationSchema={playlistValidationSchema}>
      {({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {
        return (
          <Form onSubmit={submitForm}>
            <Row className="mb-10" gutter={40}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="font-bold mb-2">Avatar playlist</div>
                <div className="w-full">
                  <FastField
                    value={values[FORM.image]}
                    useCrop
                    aspect={data?.type === PLAYLIST_TYPE.AUDIOBOOK ? BOOK_COVER_RATIO : 1}
                    name={FORM.image}
                    component={UploadImageInput}
                    height="auto"
                    width={270}
                  />
                </div>

                <fieldset>
                  <div className="font-bold mb-2">Thumbnail sharing (w:1200 x h:630)</div>
                  <div className="w-full">
                    <FastField
                      value={values[FORM.thumbnail]}
                      useCrop
                      aspect={THUMBNAIL_SHARING_RATIO}
                      name={FORM.thumbnail}
                      component={UploadImageInput}
                      width={270}
                      height="auto"
                    />
                  </div>
                </fieldset>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                {data?.user?.id && (
                  <div className="flex items-center gap-4 mb-4">
                    <strong>Creator</strong>
                    <Link to={`/user/info/${data?.user?.id}`}>{data?.user?.username}</Link>
                  </div>
                )}

                <div className="grid grid-cols-2 gap-5">
                  <FastField title="Name" required name={FORM.name} component={InputFormik} />
                  <FastField title="Type" name={FORM.type} disabled component={InputFormik} />
                </div>
                <FastField
                  title="Status"
                  name={FORM.status}
                  component={SelectInput}
                  options={Object.values(PLAYLIST_STATUS).map((i) => ({ label: capitalize(i), value: i }))}
                />
                {data?.type === PLAYLIST_TYPE.AUDIOBOOK && (
                  <div>
                    <div className="grid grid-cols-3 gap-2">
                      {/* <FastField title="Price" name="price" component={NumberInput} /> */}
                      <FastField title="Price (VND)" className="!w-full" name="price" component={NumberInput} />
                      <FastField
                        title="IOS (coin)"
                        name="iosProductId"
                        component={SelectInput}
                        className="w-full"
                        options={SERIES_PRODUCT_ID_OPTIONS}
                      />
                      <FastField
                        title="Android (coin)"
                        name="androidProductId"
                        component={SelectInput}
                        className="w-full"
                        options={SERIES_PRODUCT_ID_OPTIONS}
                      />
                    </div>
                    <AuthorSelect
                      isInvalid={errors?.authorId}
                      required
                      defaultValue={values?.authorId}
                      handleChange={(value) => setFieldValue('authorId', value)}
                      title="Author"
                      description="Author of audiobook"
                    />
                    <FastField
                      title="Purchased type"
                      name={FORM.purchasedType}
                      disabled
                      options={Object.values(PURCHASE_TYPE).map((v) => ({ label: v, value: v }))}
                      component={SelectInput}
                    />
                  </div>
                )}
                <div className="mb-6">
                  <TopicSelect
                    value={values?.[FORM.topicId]}
                    handleChange={(value) => setFieldValue(FORM.topicId, value)}
                  />
                </div>

                <FastField title="Description" rows={4} name={FORM.description} component={TextFieldInput} />
                <PlaylistCategorySelect
                  value={values[FORM.category]}
                  error={errors[FORM.category]}
                  handleChange={(v) => setFieldValue(FORM.category, v)}
                  paramsSuggest={{ title: values?.title, description: values?.description }}
                  onSuggest={(categories) => {
                    const arrayId = categories.map((i) => i.id);
                    if (arrayId.length) setFieldValue(FORM.category, arrayId);
                  }}
                />

                <FastField title="Publish Date" name="pubDate" showTime component={DateInput} allowEmpty />

                <Button
                  type="primary"
                  className="float-right"
                  onClick={submitForm}
                  disabled={!isValid || isDirty || updating}
                  loading={updating}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PlaylistEdit;
