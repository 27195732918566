/* eslint-disable no-param-reassign */
import { useMutation } from '@apollo/client';
import { Checkbox, message, Modal } from 'antd';
import { InputFormik } from 'components/Input';
import UploadAudioFormik from 'components/Input/UploadAudioFormik';
import { FastField, Field, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { BUY_INTRO, PLAYLIST_INPUT_TYPE } from 'utils/constant';
import { LexoRank } from 'lexorank';
import * as Yup from 'yup';
import { ADMIN_EDIT_EPISODE, CREATE_PLAYLIST_ITEM } from '../schema';

const episodeSchema = Yup.object().shape({
  title: Yup.string().required('Field is required'),
  isFree: Yup.boolean(),
  audioUrl: Yup.string().required('Recording is required').nullable(),
});

const EpisodeModal = ({ onDismiss, opened, data = {}, refetch, lastOrder }) => {
  const { id } = useParams();
  const onPromiseFinish = () => {
    message.success('Created!');
    refetch();
    onDismiss();
  };
  const [editItem, { loading: editLoading }] = useMutation(ADMIN_EDIT_EPISODE, {
    onCompleted: () => {
      message.success('Created!');
      onDismiss();
    },
    onError: () => message.error('Error!'),
  });
  const [createItem, { loading: itemCreating }] = useMutation(CREATE_PLAYLIST_ITEM, {
    onCompleted: onPromiseFinish,
    onError: () => message.error('Error!'),
  });

  const doSubmit = (values) => {
    if (!data?.id) {
      const variables = {
        playlistItemInput: {
          playlistId: Number(id),
          type: PLAYLIST_INPUT_TYPE.EPISODE,
          order: (lastOrder ? LexoRank.parse(lastOrder).genNext() : LexoRank.min()).toString(),
        },
        episodeInput: {
          playlistId: Number(id),
          title: values.title,
          audioUrl: values.audioUrl,
          isFree: values.isFree,
          duration: Math.floor(values.duration),
        },
      };
      createItem({ variables });
    } else {
      values.duration = Math.floor(values.duration);
      if (values.audioUrl === BUY_INTRO) {
        delete values.audioUrl;
        delete values.duration;
      }
      const variables = {
        adminEditEpisodeId: data?.episode?.id,
        input: { ...values },
      };
      editItem({ variables });
    }
  };

  const initialValues = {
    title: data?.episode?.title || '',
    audioUrl: data?.episode?.audioUrl || '',
    isFree: data?.episode?.isFree || false,
    duration: data?.episode?.duration || 0,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={doSubmit} validationSchema={episodeSchema}>
      {({ values, submitForm, dirty }) => {
        return (
          <Modal
            bodyStyle={{ minHeight: 350 }}
            title="Episode"
            visible={opened}
            onCancel={onDismiss}
            onOk={submitForm}
            okButtonProps={{ loading: itemCreating || editLoading, disabled: !dirty }}
            okText="Save"
            destroyOnClose
            centered
          >
            <FastField required component={InputFormik} name="title" title="Title" />

            <Field name="isFree">
              {({ field }) => (
                <fieldset className="mb-6 grid">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label className="select-none text-sm font-bold mr-2" htmlFor={field.name}>
                    Free
                  </label>
                  <Checkbox id={field.name} checked={field.value} onChange={field.onChange} />
                </fieldset>
              )}
            </Field>

            <Field
              title="Audio url"
              required
              name="audioUrl"
              fieldNameDuration="duration"
              component={UploadAudioFormik}
            />

            {values?.audioUrl && <audio src={values?.audioUrl} controls />}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EpisodeModal;
