import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import cl from 'classnames';
import { ErrorMessage } from 'formik';

const InputFormik = ({
  field,
  readOnly,
  form,
  type,
  placeholder = '',
  title = '',
  disabled,
  className = '',
  required,
}) => {
  const { errors } = form;
  const { name } = field;

  return (
    <div className="flex flex-col">
      {title && (
        <span className="text-gray mb-2 font-bold h-5 text-18">
          {title} {required && <span className="text-red-400">*</span>}
        </span>
      )}
      {type === 'password' ? (
        <Input.Password
          style={errors[name] ? { border: '2px solid red' } : {}}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          className={cl(className)}
          {...field}
        />
      ) : (
        <Input
          style={errors[name] ? { border: '2px solid red' } : {}}
          type={type}
          readOnly={readOnly}
          placeholder={placeholder}
          disabled={disabled}
          className={cl(className)}
          {...field}
        />
      )}
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

InputFormik.prototype = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

InputFormik.defaultProps = {
  type: '',
  lable: '',
  placeholder: '',
  disabled: '',
  title: '',
};

export default InputFormik;
