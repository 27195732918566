import { Button, Checkbox, Input, Switch } from 'antd';
import StandardTable from 'components/StandardTable';
import { EditOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import moment from 'moment';
import { formatPriceVnd } from 'utils/helpers/table';
import { COUPON_STATUS } from 'utils/constant';
import { UPDATE_COUPON } from 'common/coupon.gql';
import { useMutation } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import ModalCoupon from './ModalCoupon';

const TableCoupon = ({ querySearch, onSearch, data = [], onTableChange, loading, total = 0 }) => {
  const [itemSelected, setItemSelected] = useState(null);
  const [doUpdate] = useMutation(UPDATE_COUPON);

  const handleToggleActive = useDebouncedCallback(
    (id, status) => {
      doUpdate({
        variables: { adminUpdateCouponId: id, input: { status } },
      });
    },
    250,
    { leading: false, trailing: true }
  );

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      fixed: 'left',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      width: 80,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      minWidth: 150,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: [
        { text: 'Playlist', value: 'playlist' },
        { text: 'Ticket', value: 'ticket' },
        { text: 'Package', value: 'package' },
      ],
    },
    {
      title: 'Discount (%)',
      dataIndex: 'discount',
    },
    {
      title: 'Maximum (VND)',
      dataIndex: 'maximum',
      sorter: true,
      render: (v) => formatPriceVnd(v),
    },
    {
      title: 'Limit',
      dataIndex: 'limit',
    },
    {
      title: 'Active',
      dataIndex: 'status',
      render: (v, record) => (
        <Switch
          checked={v === COUPON_STATUS.active}
          onChange={(checked) =>
            handleToggleActive(record?.id, checked ? COUPON_STATUS.active : COUPON_STATUS.inactive)
          }
        />
      ),
    },
    {
      title: 'Expired at',
      dataIndex: 'expiredAt',
      sorter: true,
      render: (value) => {
        const remain = moment(value).diff(moment(), 'hour');
        const isExpired = moment(value).isBefore(moment());
        if (isExpired) return 'Expired';
        if (!value) return '-- / -- / ----';
        return (
          <div>
            <div>{moment(value).format('HH:mm, DD/MM/YYYY')}</div>
            <p className="text-red-400 text-xs">{remain > 0 ? `${remain} hours remaining` : 'Upcoming expiration'}</p>
          </div>
        );
      },
    },
    {
      title: 'Created',
      dataIndex: 'created',
      sorter: true,
      render: (value) => moment(value).format('HH:mm, DD/MM/YYYY'),
    },
    {
      title: '',
      width: 100,
      render: (value) => (
        <div className="flex justify-center items-center gap-4">
          <Button icon={<EditOutlined />} type="text" onClick={() => setItemSelected(value)} />
        </div>
      ),
    },
  ];

  return (
    <div>
      {!!itemSelected && (
        <ModalCoupon
          editable={!!Object.keys(itemSelected || {}).length}
          initialValues={itemSelected}
          opened={!!itemSelected}
          onCancel={setItemSelected}
        />
      )}
      <StandardTable
        renderToolbar={() => [
          <strong key="total">Total: {total}</strong>,
          <Input
            defaultValue={querySearch?.search}
            key="search"
            placeholder="Search"
            onChange={({ target }) => onSearch({ search: target.value, page: 0 })}
          />,
          <div key="checkbox" className="gap-2 bg-slate-100 h-8 px-2 py-1 space-x-2">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="leading-none" htmlFor="showExpired">
              Show expired
            </label>
            <Checkbox
              id="showExpired"
              title="Expired"
              value={!!querySearch?.expired}
              onChange={(e) => onSearch({ expired: e.target.checked ? true : '', page: 0 })}
            />
          </div>,
          <Button type="primary" key="primary" onClick={() => setItemSelected({})}>
            Add
          </Button>,
        ]}
        rowClassName={(record) => {
          const isExpired = moment(record?.expiredAt).isBefore(moment());
          return isExpired || !record?.expiredAt ? 'text-gray-300' : '';
        }}
        pagination={{
          showSizeChanger: false,
          total,
          pageSize: 20,
        }}
        onChange={onTableChange}
        loading={loading}
        dataSource={data}
        columns={columns}
      />
    </div>
  );
};

export default TableCoupon;
