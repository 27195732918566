import React, { useState } from 'react';
import { Spin } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { OPERATOR } from 'utils/constant';
import { useDebounce } from 'use-debounce';
import SelectInput from './SelectInput';

const ALL_USERS = gql`
  query adminGetUserList($filter: JSON, $pageSize: Int, $page: Int) {
    adminGetUserList(filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        username
      }
    }
  }
`;

const UserSelectFormik = ({ disabled, mode, ...selectProps }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue] = useDebounce(searchTerm, 500, { leading: false, trailing: true });

  const { data: dataUser, loading } = useQuery(ALL_USERS, {
    variables: {
      page: 0,
      pageSize: 10,
      ...(searchValue ? { filter: [{ field: 'username', operator: OPERATOR.contains, value: searchValue }] } : {}),
    },
  });
  const results = dataUser?.adminGetUserList?.results;

  return (
    <SelectInput
      options={!loading ? results?.map?.((host) => ({ label: host.username, value: host.id })) : []}
      labelInValue
      showSearch
      style={{ width: '100%' }}
      filterOption={false}
      placeholder="Please select hosts"
      onSearch={setSearchTerm}
      notFoundContent={loading ? <Spin size="small" /> : null}
      disabled={disabled}
      {...(mode ? { mode } : {})}
      {...selectProps}
    />
  );
};

export default UserSelectFormik;
