import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Image, Layout, message, Popconfirm } from 'antd';
import StandardTable from 'components/StandardTable';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import useFilter from 'utils/hooks/useFilter';
import { getOperationName } from '@apollo/client/utilities';
import { DELETE_GAME, GET_LIST_GAME_ROOM } from './schema';
import RoomDetailModal from './GameDetailModal';
import CreateGameModal from './CreateGameModal';

const DEFAULT_PAGE_SIZE = 30;

const DATE_FORMAT = 'DD/MM/YYYY, HH:MM';

const GameRoom = () => {
  const { data: adminGetGameList, loading } = useQuery(GET_LIST_GAME_ROOM);

  const [deleteGame, { loading: deleting }] = useMutation(DELETE_GAME, {
    refetchQueries: [GET_LIST_GAME_ROOM],
  });

  const [gameSelected, setGameSelected] = useState(null);

  const [isCreating, setIsCreating] = useState(false);

  const filter = useFilter(['page', 'status']);

  const data = adminGetGameList?.[getOperationName(GET_LIST_GAME_ROOM)];

  const handleChangeTable = (pagination, filters) => {
    const { current, pageSize: pS } = pagination;
    filter.onSearch({ page: current, pageSize: pS, ...filters });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Thumbnail',
      dataIndex: 'image',
      render: (val) => <Image className="rounded-sm" src={val} height={100} width={100} />,
    },
    {
      title: 'Game',
      key: 'game',
      width: '30%',
      render: (val) => (
        <Button type="link" onClick={() => setGameSelected(val)}>
          <span className="underline">{val?.name}</span>
        </Button>
      ),
    },
    {
      title: 'Description',
      key: 'description',
      width: '30%',
      dataIndex: 'description',
    },
    {
      title: 'Created',
      key: 'created',
      width: '20%',
      dataIndex: 'created',
      render: (value) => moment(value).format(DATE_FORMAT),
    },
    {
      title: '',
      width: '10%',
      render: (value) => (
        <Popconfirm
          title="Are you sure to delete this game?"
          onConfirm={() => deleteGame({ variables: { gameId: value?.id } })}
          okButtonProps={{ loading: deleting, disabled: deleting }}
        >
          <Button icon={<DeleteOutlined />} danger />
        </Popconfirm>
      ),
    },
  ];

  return (
    <Layout.Content className="flex flex-col md:p-4 p-2 space-y-4">
      <Card className="overflow-auto">
        <StandardTable
          dataSource={data?.results || []}
          loading={loading}
          columns={columns}
          rowKey="id"
          pagination={{
            showSizeChanger: false,
            total: data?.total || 0,
            pageSize: DEFAULT_PAGE_SIZE,
          }}
          renderToolbar={() => [
            <Button type="primary" key="primary" onClick={() => setIsCreating(true)}>
              <PlusOutlined /> Add
            </Button>,
          ]}
          onChange={handleChangeTable}
        />
        {isCreating && <CreateGameModal visible={isCreating} onCancel={() => setIsCreating(false)} />}
        {gameSelected && (
          <RoomDetailModal gameData={gameSelected} visible={!!gameSelected} onCancel={() => setGameSelected(null)} />
        )}
      </Card>
    </Layout.Content>
  );
};

export default GameRoom;
