import React from 'react';
import { Button, Form, Input, Layout, message, Spin } from 'antd';
import { FastField, Formik } from 'formik';
import * as Yup from 'yup';
import { NumberInput, SelectInput } from 'components/Input';
import { useMutation, useQuery } from '@apollo/client';
import { GET_MASTER_SETTING_LIST, UPDATE_CORE_SETTING } from '../schema';

const MAX = 300;
const MIN = 200;

const ExchangeSchema = Yup.object().shape({
  rate: Yup.number().required().min(MIN).max(MAX),
});

const Exchange = () => {
  const { data, loading } = useQuery(GET_MASTER_SETTING_LIST, {
    variables: { keys: ['vnd_rate'] },
  });

  const rateData = data?.getMasterSettingList?.[0];

  const initialValues = {
    rate: Number(rateData?.value),
  };

  const [updateSetting] = useMutation(UPDATE_CORE_SETTING, {
    onCompleted: () => {
      message.success('Change success!');
    },
    onError: (err) => {
      message.error(err?.message);
    },
  });

  const onSubmit = (values) => {
    return updateSetting({
      variables: { id: rateData.id, value: `${values.rate}` },
    });
  };

  if (loading && !data) return <Spin />;

  return (
    <Layout.Content className="flex flex-col">
      <Formik initialValues={initialValues} validationSchema={ExchangeSchema} onSubmit={onSubmit}>
        {({ handleSubmit, isSubmitting, isValid, isDirty, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <div className="flex flex-nowrap gap-x-3 items-center">
                  <span className="font-semibold">{rateData?.name} usd/vnd:</span>
                  <FastField min={MIN} max={MAX} name="rate" value={values.rate} component={NumberInput} />
                </div>
              </div>
              <Button htmlType="submit" loading={isSubmitting} disabled={!isValid || isSubmitting || isDirty}>
                Save
              </Button>
            </form>
          );
        }}
      </Formik>
    </Layout.Content>
  );
};

export default Exchange;
