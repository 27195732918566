import React from 'react';
import { Image, Modal } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import pick from 'lodash/pick';
import UploadImage from 'components/UploadImage';
import { InputFormik } from 'components/Input';

const TopicSchema = Yup.object().shape({
  title: Yup.string().required(),
  slug: Yup.string()
    .matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/)
    .required(),
  image: Yup.string().required(),
});

const TopicModal = ({ visible, onCancel, onSubmit, data, headerTitle }) => {
  const isEdit = !!data;

  return (
    <Formik
      initialValues={isEdit ? pick(data, ['title', 'slug', 'image']) : {}}
      validationSchema={TopicSchema}
      enableReinitialize
      onSubmit={(values) => {
        onSubmit(values, data?.id);
      }}
    >
      {({ values, submitForm, setFieldValue }) => {
        const handleAdd = async (url) => {
          if (url) {
            setFieldValue('image', url);
          }
        };
        return (
          <Modal
            visible={visible}
            title={headerTitle}
            onCancel={onCancel}
            onOk={submitForm}
            okText={isEdit ? 'Save' : 'Create'}
          >
            <Form onSubmit={submitForm} layout="vertical">
              <FastField type="text" title="Title" name="title" component={InputFormik} />
              <FastField type="text" title="Slug" name="slug" component={InputFormik} />
              <UploadImage onFinish={handleAdd}>
                <Image
                  src={values?.image}
                  placeholder={<PictureOutlined style={{ fontSize: '120px' }} />}
                  preview={false}
                  height="120px"
                />
              </UploadImage>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default TopicModal;
