import React, { useState } from 'react';
import { Empty, Select, Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { OPERATOR } from 'utils/constant';
import PropTypes from 'prop-types';
import { useDebouncedCallback } from 'use-debounce';
import { ALL_CAMP } from './schema';

const { Option } = Select;

const CampSelect = ({ showTitle = true, className, handleChange, renderCampList, title, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const generateFilter = (input) => {
    return [{ field: 'title', operator: OPERATOR.contains, value: input }];
  };
  const { data, loading } = useQuery(ALL_CAMP, {
    variables: {
      ...(searchTerm ? { filter: generateFilter(searchTerm) } : {}),
    },
  });

  const result = data?.adminGetCampList?.results;

  // Begin search
  const changeSearchTerm = (input) => {
    setSearchTerm(input);
  };
  const callDebounce = useDebouncedCallback(changeSearchTerm, 500, {
    leading: false,
  });
  const searchHost = (input) => {
    callDebounce(input);
  };
  // End search

  const renderCamp = () => {
    if (loading) return null;
    if (typeof renderCampList === 'function') {
      return renderCampList();
    }
    if (result) {
      return result.map((element) => (
        // eslint-disable-next-line react/jsx-indent
        <Option key={element.id} value={element.id}>
          {element.title} <br />
        </Option>
      ));
    }
    return null;
  };

  return (
    <div className={className}>
      {showTitle && <span className="text-gray mb-2 font-bold h-5 text-18">{title || 'Camp'}</span>}
      <Select
        placeholder="Please select camp"
        onSearch={searchHost} // Add props showSearch to active
        onChange={handleChange}
        filterOption={false}
        className="w-full"
        notFoundContent={
          loading ? (
            <div className="text-center">
              <Spin size="small" tip="Searching..." />
            </div>
          ) : (
            <Empty />
          )
        }
        {...rest}
      >
        {renderCamp()}
      </Select>
    </div>
  );
};

CampSelect.prototype = {
  handleChange: PropTypes.func,
  renderCampList: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default CampSelect;
