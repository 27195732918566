import React, { useState } from 'react';
import { Layout, Card, Input } from 'antd';
import { gql, useQuery } from '@apollo/client';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';
// eslint-disable-next-line import/no-cycle
import WaveTable from './components/WaveTable';

const { Search } = Input;

export const ALL_WAVE = gql`
  query adminGetWave($filter: JSON, $pageSize: Int, $page: Int, $sort: JSON) {
    adminGetWave(filter: $filter, pageSize: $pageSize, page: $page, sort: $sort) {
      currentPage
      pageSize
      total
      results {
        id
        description
        created
        user {
          id
          username
        }
      }
    }
  }
`;

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    const value = filters[filter];
    if (typeof value === 'string' && !value.trim()) return;
    switch (filter) {
      case 'description':
        newFilter.push({
          operator: OPERATOR.or,
          value: [
            { field: 'description', operator: OPERATOR.contains, value },
            ...(!Number.isNaN(+value) ? [{ field: 'id', operator: OPERATOR.eq, value: Number(value) }] : []),
          ],
        });
        break;
      case 'status':
      case 'type':
        newFilter.push({ field: filter, operator: OPERATOR.in, value });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const Wave = ({ location }) => {
  const [sortState, updateSortState] = useState([{ field: 'created', value: 'desc' }]);
  const filter = useFilter(['type', 'status', 'page', 'pageSize', 'description']);
  const { pageSize = 30, page = 0 } = filter.querySearch;
  const { data, loading } = useQuery(ALL_WAVE, {
    variables: {
      page: Number(page),
      pageSize: Number(pageSize),
      sort: sortState,
      filter: generateFilter(filter.querySearch),
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      updateSortState([{ field: sorter?.field, value: 'asc' }]);
    }
    if (sorter?.order === 'descend') {
      updateSortState([{ field: sorter?.field, value: 'desc' }]);
    }
    const { current, pageSize: currentPageSize } = pagination;
    filter.onSearch({ page: current - 1, pageSize: currentPageSize, ...filters });
  };

  const onEnterSearch = (event) => {
    filter.onSearch({ description: event.target.value, page: 0 });
  };
  const onSearch = (event) => {
    filter.onSearch({ description: event, page: 0 });
  };

  return (
    <Layout.Content>
      <Card>
        <Search
          defaultValue={filter.querySearch?.description}
          placeholder="Search Wave"
          style={{ width: 400 }}
          onPressEnter={onEnterSearch}
          onSearch={onSearch}
        />
        <WaveTable
          data={data?.adminGetWave?.results}
          loading={loading}
          pagination={{
            reset: true,
            defaultPageSize: Number(pageSize),
            showSizeChanger: true,
            current: Number(page + 1),
            total: data?.adminGetWave?.total || 0,
            pageSizeOptions: ['5', '10', '20', '30'],
          }}
          onChange={handleTableChange}
        />
      </Card>
    </Layout.Content>
  );
};

export default Wave;
