import { gql } from '@apollo/client';

export const ADMIN_GET_PLAYLIST = gql`
  query AdminGetPlaylist($filter: JSON, $page: Int, $pageSize: Int, $sort: JSON) {
    adminGetPlaylist(filter: $filter, page: $page, pageSize: $pageSize, sort: $sort) {
      results {
        id
        title
        slug
        description
        status
        type
        image
        thumbnail
        price
        iosProductId
        androidProductId
        purchasedType
        pubDate
        author {
          id
          name
          avatar
        }
        category {
          id
          title
          slug
        }
        user {
          id
          username
        }
        topic {
          id
          slug
          title
        }
        created
      }
      total
      pageSize
      currentPage
    }
  }
`;

export const ADMIN_GET_ALL_CATEGORY = gql`
  query GetAllPlaylistCategory {
    getAllPlaylistCategory {
      id
      title
      slug
      priority
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation AdminCreatePlaylistCategory($input: PlaylistCategoryInput!) {
    adminCreatePlaylistCategory(input: $input) {
      id
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation AdminUpdatePlaylistCategory($adminUpdatePlaylistCategoryId: Int!, $input: PlaylistCategoryInput!) {
    adminUpdatePlaylistCategory(id: $adminUpdatePlaylistCategoryId, input: $input) {
      id
    }
  }
`;

export const CREATE_PLAYLIST = gql`
  mutation AdminCreatePlaylist($input: PlaylistInput!) {
    adminCreatePlaylist(input: $input) {
      id
      status
      title
      price
    }
  }
`;

export const IMPORT_RSS_PLAYLIST = gql`
  mutation AdminImportRssPlaylist($rssUrl: String!, $creatorId: Int!) {
    adminImportRssPlaylist(rssUrl: $rssUrl, creatorId: $creatorId) {
      id
      title
      slug
      description
      type
      image
      category {
        id
        title
        slug
      }
      user {
        id
        username
      }
      topic {
        id
        slug
        title
      }
      created
    }
  }
`;

export const ADMIN_DELETE_PLAYLIST = gql`
  mutation AdminDeletePlaylist($adminDeletePlaylistId: Int!) {
    adminDeletePlaylist(id: $adminDeletePlaylistId) {
      id
    }
  }
`;

export const ADMIN_GET_AUTHOR = gql`
  query GetListAuthor($filter: JSON, $sort: JSON, $page: Int, $pageSize: Int) {
    getListAuthor(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
      results {
        id
        name
        description
        avatar
      }
    }
  }
`;

export const ADMIN_CREATE_AUTHOR = gql`
  mutation AdminCreateAuthor($input: AuthorInput!) {
    adminCreateAuthor(input: $input) {
      id
    }
  }
`;

export const ADMIN_UPDATE_AUTHOR = gql`
  mutation AdminUpdateAuthor($adminUpdateAuthorId: Int!, $input: AuthorInput!) {
    adminUpdateAuthor(id: $adminUpdateAuthorId, input: $input) {
      id
    }
  }
`;

export const ADMIN_DELETE_AUTHOR = gql`
  mutation AdminDeleteAuthor($adminDeleteAuthorId: Int!) {
    adminDeleteAuthor(id: $adminDeleteAuthorId) {
      id
    }
  }
`;
