import { gql } from '@apollo/client';

const ALL_ADMIN = gql`
  query adminGetListAdmin {
    adminGetListAdmin {
      id
      username
      email
      roleId
      status
      role {
        id
        name
      }
      lastActivedAt
    }
  }
`;

const CREATE_ADMIN = gql`
  mutation AdminCreateAdmin($input: AdminInput!) {
    adminCreateAdmin(input: $input) {
      id
      username
      email
      roleId
    }
  }
`;

const UPDATE_ADMIN = gql`
  mutation Mutation($id: Int!, $input: AdminInput!) {
    adminUpdateAdmin(id: $id, input: $input) {
      id
      username
      roleId
      email
    }
  }
`;

const DELETE_ADMIN = gql`
  mutation AdminDeleteAdmin($id: Int!) {
    adminDeleteAdmin(id: $id) {
      id
    }
  }
`;

const ALL_ROLES = gql`
  query adminGetRoles($filter: JSON) {
    adminGetRoles(filter: $filter) {
      id
      code
      name
      permissions {
        id
        code
        name
        group
        allow
      }
    }
  }
`;

const CREATE_ROLE = gql`
  mutation AdminCreateRole($code: String!, $name: String!, $permissionIds: [Int!]!) {
    adminCreateRole(code: $code, name: $name, permissionIds: $permissionIds) {
      code
      name
      id
      permissions {
        id
        code
        name
        group
        allow
      }
    }
  }
`;

const UPDATE_ROLE = gql`
  mutation AdminUpdateRole($id: Int!, $code: String!, $name: String!, $permissions: [InputRolePermission]) {
    adminUpdateRole(id: $id, code: $code, name: $name, permissions: $permissions) {
      id
      code
      name
      permissions {
        id
        allow
        group
        name
        code
      }
    }
  }
`;

const DELETE_ROLE = gql`
  mutation AdminDeleteRole($id: Int!) {
    adminDeleteRole(id: $id) {
      id
    }
  }
`;

const ALL_PERMISSIONS = gql`
  query AdminGetPermissions($filter: JSON) {
    adminGetPermissions(filter: $filter) {
      id
      code
      name
      group
      allow
    }
  }
`;

const ROLE_DETAIL = gql`
  query AdminGetRoleDetail($id: Int!) {
    adminGetRoleDetail(id: $id) {
      id
      code
      name
      permissions {
        id
        code
        name
        group
        allow
      }
    }
  }
`;

export {
  ALL_ADMIN,
  CREATE_ROLE,
  CREATE_ADMIN,
  DELETE_ADMIN,
  UPDATE_ADMIN,
  ALL_ROLES,
  DELETE_ROLE,
  UPDATE_ROLE,
  ROLE_DETAIL,
  ALL_PERMISSIONS,
};
