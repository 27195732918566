import React from 'react';
import { Image, Modal } from 'antd';
import PictureOutlined from '@ant-design/icons/lib/icons/PictureOutlined';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import pick from 'lodash/pick';
import { InputFormik, TextFieldInput, SelectInput } from 'components/Input';
import UploadImage from 'components/UploadImage';
import UserSelect from 'components/UserSelect';
import TopicSelect from 'components/TopicSelect';
import { CAMP_STATUS, CAMP_TYPE } from 'utils/constant';

const CampSchema = Yup.object().shape({
  title: Yup.string().required().max(50),
  description: Yup.string(),
});

const CampModal = ({ visible, onCancel, onSubmit, data, headerTitle }) => {
  const isEdit = !!data;

  const initialValues = isEdit
    ? {
        ...pick(data, [
          'title',
          'avatar',
          'description',
          'type',
          'status',
          'creatorId',
          'isOpenMembership',
          'isOpenHostRoom',
          'campRule',
        ]),
        campTopic: data?.topic?.map((item) => item.id),
      }
    : {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CampSchema}
      enableReinitialize
      onSubmit={(values) => {
        onSubmit(values, data?.id);
      }}
    >
      {({ values, submitForm, setFieldValue }) => {
        const handleAdd = async (url) => {
          if (url) {
            setFieldValue('avatar', url);
          }
        };
        return (
          <Modal
            visible={visible}
            title={headerTitle}
            onCancel={onCancel}
            onOk={submitForm}
            okText={isEdit ? 'Save' : 'Create'}
          >
            <Form onSubmit={submitForm} layout="vertical">
              <FastField type="text" title="Title" name="title" component={InputFormik} />
              <FastField type="text" title="Description" name="description" component={TextFieldInput} />
              <div className="flex flex-col">
                <span className="text-gray mb-2 font-bold h-5 text-18">Avatar</span>
                <UploadImage onFinish={handleAdd}>
                  <Image
                    src={values?.avatar}
                    placeholder={<PictureOutlined style={{ fontSize: '120px' }} />}
                    preview={false}
                    height="120px"
                  />
                </UploadImage>
              </div>
              <FastField
                name="type"
                component={SelectInput}
                options={Object.keys(CAMP_TYPE).map((t) => ({ label: CAMP_TYPE[t], value: t }))}
                title="Type"
              />
              <FastField
                name="status"
                component={SelectInput}
                options={Object.keys(CAMP_STATUS).map((t) => ({ label: CAMP_STATUS[t], value: t }))}
                title="Status"
              />
              <UserSelect
                handleChange={(value) => setFieldValue('creatorId', value)}
                title="Creator"
                disabled={isEdit}
              />
              <br />
              <TopicSelect value={values?.campTopic} handleChange={(value) => setFieldValue('campTopic', value)} />
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default CampModal;
