import { useMutation } from '@apollo/client';
import { Modal } from 'antd';
import { CREATE_DECORATION, UPDATE_DECORATION } from 'common/decoration.gql';
import { InputFormik, SelectInput } from 'components/Input';
import UploadImageInput from 'components/UploadImageInput';
import { FastField, Formik } from 'formik';
import { pick } from 'lodash';
import React from 'react';
import { DECORATION_TYPE } from 'utils/constant';
import * as Yup from 'yup';

const DEFAULT_VALUES = {
  type: DECORATION_TYPE.subscription,
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required(),
  border: Yup.string().required(),
});

const ModalBorderDecor = ({ values = {}, opened, onDismiss, onSuccess }) => {
  const [createDecoration, { loading: createLoading }] = useMutation(CREATE_DECORATION);
  const [updateDecoration, { loading: updateLoading }] = useMutation(UPDATE_DECORATION);

  const recordId = values?.id;
  const isEdit = !!recordId;

  const onSubmit = (formValues) => {
    if (!isEdit) {
      createDecoration({
        variables: {
          input: formValues,
        },
        onCompleted: onSuccess,
      });
    } else {
      updateDecoration({
        variables: {
          adminUpdateDecorationId: recordId,
          input: pick(formValues, ['title', 'border', 'type']),
        },
        onCompleted: onSuccess,
      });
    }
  };

  return (
    <Formik initialValues={{ ...DEFAULT_VALUES, ...values }} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ submitForm }) => {
        return (
          <Modal
            bodyStyle={{ minHeight: 350 }}
            title={isEdit ? 'Edit' : 'Create'}
            visible={opened}
            transitionName="none"
            maskTransitionName="none"
            confirmLoading={createLoading || updateLoading}
            onCancel={!createLoading || !updateLoading ? onDismiss : () => {}}
            onOk={submitForm}
            okText={isEdit ? 'Save' : 'Create'}
            destroyOnClose
            centered
          >
            <FastField required title="Title" name="title" component={InputFormik} />
            <FastField
              title="Type"
              name="type"
              component={SelectInput}
              options={Object.keys(DECORATION_TYPE).map((k) => ({ label: k, value: DECORATION_TYPE[k] }))}
            />

            <FastField
              required
              title="Image (w:288 x h:288)"
              name="border"
              component={UploadImageInput}
              width={150}
              height={150}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ModalBorderDecor;
