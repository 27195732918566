const { gql } = require('@apollo/client');

const GET_ROOM = gql`
  query getRommById($id: Int!) {
    room(id: $id) {
      id
      title
      description
      banner
      image
      bannerLink
      totalTicket
      price
      creator {
        id
        username
      }
      topic {
        id
        title
      }
      start
      end
      type
      status
      allowRecording
      allowChat
      campId
      recordingUrl
      created
      speakers {
        id
        userId
        role
        user {
          id
          username
        }
      }
    }
  }
`;

const EDIT_ROOM = gql`
  mutation adminEditRoom($id: Int!, $input: AdminCreateRoomInput!) {
    adminEditRoom(id: $id, input: $input) {
      id
      title
      description
      banner
      bannerLink
      creator {
        id
        username
      }
      topic {
        id
      }
      start
      type
      status
      allowRecording
      campId
      speakers {
        id
        userId
        role
      }
    }
  }
`;

const ALL_USERS = gql`
  query adminGetUserList($filter: JSON, $pageSize: Int, $page: Int) {
    adminGetUserList(filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        username
      }
    }
  }
`;

const CLO_ROOMS = gql`
  mutation adminCloseRoom($id: Int!) {
    adminCloseRoom(id: $id) {
      id
      status
    }
  }
`;

export { GET_ROOM, EDIT_ROOM, ALL_USERS, CLO_ROOMS };
