import useUrlState from '@ahooksjs/use-url-state';
import { Card, Result } from 'antd';
import React, { memo, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';

const AuthorizedTabs = ({ tabs = [], defaultTab }) => {
  const auth = useRecoilValue(authState);
  const [tabState, setTabState] = useUrlState({ tab: defaultTab || tabs[0].key });
  const { permissions = [] } = auth;
  const tabSelected = tabs?.find?.((i) => i.key === tabState.tab);

  const currentTab = useMemo(() => {
    const authority = tabSelected?.authority;
    if (authority) {
      const found = permissions.find((item) => item.code === authority);
      if (!found)
        return <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this tab." />;
    }
    return tabSelected.component;
  }, [tabSelected]);

  return (
    <div>
      <Card
        bordered
        activeTabKey={tabState?.tab}
        onTabChange={(key) =>
          setTabState({
            tab: key,
          })
        }
        tabList={tabs}
      >
        {currentTab}
      </Card>
    </div>
  );
};

export default memo(AuthorizedTabs);
