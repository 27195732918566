import { EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { message, Tag, Popconfirm, Tooltip } from 'antd';
import React from 'react';
import StandardTable from 'components/StandardTable';
import { Link } from 'react-router-dom';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { gql, useMutation } from '@apollo/client';

const DEL_ROOMS = gql`
  mutation adminDeleteRoom($id: Int!) {
    adminDeleteRoom(id: $id)
  }
`;
const CLO_ROOMS = gql`
  mutation adminCloseRoom($id: Int!) {
    adminCloseRoom(id: $id) {
      id
      status
    }
  }
`;

export const TYPE_COLOR = {
  private: 'purple',
  public: 'green',
  social: 'orange',
};

const STATUS_COLOR = {
  completed: '',
  open: 'green',
  live: 'red',
};

const RoomTable = ({ data, pagination, onChange, fetchRoom }) => {
  const [adminDeleteRoom] = useMutation(DEL_ROOMS);
  const [adminCloseRoom] = useMutation(CLO_ROOMS);

  const deleteRoom = (id) => {
    adminDeleteRoom({
      variables: { id },
    });
    fetchRoom();
    message.success('Room deleted!');
  };
  const closeRoom = (id) => {
    adminCloseRoom({ variables: { id } });
    message.success('Room closed!');
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 250,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      key: 'start',
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (val) => <span>{moment(val).format('HH:mm DD/MM/YYYY')}</span>,
    },
    {
      title: 'Room type',
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'Public', value: 'public' },
        { text: 'Social', value: 'social' },
        { text: 'Private', value: 'private' },
        { text: 'Ticket', value: 'ticket' },
      ],
      render: (val) => <Tag color={TYPE_COLOR[val]}>{val}</Tag>,
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: 'Open', value: 'open' },
        { text: 'Live', value: 'live' },
        { text: 'Completed', value: 'completed' },
      ],
      defaultFilteredValue: ['open', 'live'],
      render: (val) => <Tag color={STATUS_COLOR[val]}>{val}</Tag>,
      sorter: true,
    },
    {
      title: 'Chat',
      dataIndex: 'allowChat',
      key: 'allowChat',
      render: (val) => <Checkbox checked={val} disabled />,
    },
    {
      title: 'REC',
      dataIndex: 'allowRecording',
      key: 'allowRecording',
      render: (val) => <Checkbox checked={val} disabled />,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (val, record) => (
        <>
          <Link key="edit" to={`/room/edit/${val}`}>
            <EditOutlined />
          </Link>
          <Popconfirm
            title={`Are you sure to delete ${record.title}?`}
            onConfirm={() => deleteRoom(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
          </Popconfirm>
          <Popconfirm
            title={`Are you sure to close ${record.title}?`}
            onConfirm={() => closeRoom(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip placement="top" title="Close Room">
              <CloseCircleOutlined style={{ fontSize: 20, color: 'green', marginLeft: 15 }} />
            </Tooltip>
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <StandardTable
        headerTitle="Room"
        rowKey="id"
        dataSource={data}
        columns={columns}
        allowSelectRow
        pagination={pagination}
        onChange={onChange}
      />
    </div>
  );
};

export default RoomTable;
