import { useMutation } from '@apollo/client';
import { Button, Image, Input, Modal, Spin, message } from 'antd';
import UserSelect from 'components/UserSelect';
import { getRssParser } from 'utils/request';
import { useState } from 'react';
import { IMPORT_RSS_PLAYLIST, ADMIN_GET_PLAYLIST } from '../schema';

const ImportRss = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onToggle = () => setIsOpen((pre) => !pre);
  const [importRss, { loading: importing }] = useMutation(IMPORT_RSS_PLAYLIST, {
    onError: () => {},
    refetchQueries: [ADMIN_GET_PLAYLIST],
  });
  const [creatorId, setCreatorId] = useState(null);
  const [rssState, setRssState] = useState({ url: null, loading: false, data: null });
  const handleSetRssState = (value) => setRssState((pre) => ({ ...pre, ...value }));

  const handleParser = async (url) => {
    handleSetRssState({ loading: true });
    getRssParser(url)
      .then(({ data }) => {
        handleSetRssState({ loading: false, data, url });
      })
      .catch((error) => {
        console.log({ error });
        message.error('View rss fail');
        handleSetRssState({ loading: false, url });
      });
  };

  const handleSubmit = async () => {
    const result = await importRss({
      variables: { rssUrl: rssState.url, creatorId },
    });
    if (result?.errors?.message) message.error(result.errors.message);
    else setIsOpen(false);
  };

  return (
    <>
      <Button onClick={onToggle}>RSS</Button>
      <Modal
        title="Import RSS"
        centered
        visible={isOpen}
        onCancel={onToggle}
        okText="Import"
        okButtonProps={{
          loading: importing,
          disabled: !creatorId || !rssState.data || !rssState.url || importing,
          onClick: handleSubmit,
        }}
        width={800}
      >
        <div className="space-y-3">
          <Input.Search
            placeholder="Enter your RSS Feed URL"
            allowClear
            enterButton="GET"
            loading={rssState.loading}
            onSearch={handleParser}
            onPressEnter={(e) => handleParser(e.target.value)}
          />
          <UserSelect title="Creator" handleChange={setCreatorId} />

          <div className="h-96 overflow-auto border rounded-md relative">
            {rssState.loading && (
              // eslint-disable-next-line max-len
              <div className="flex items-center justify-center h-full absolute top-0 left-0 w-full select-none bg-slate-100/60 z-10">
                <Spin />
              </div>
            )}
            {rssState.data && (
              <div className="p-6">
                <div className="flex gap-4">
                  <Image
                    src={rssState?.data?.image?.url || rssState?.data?.itunes?.image}
                    height={160}
                    width={160}
                    alt={rssState?.data?.image?.title || rssState?.data?.title}
                  />
                  <div className="space-y-2">
                    <p className="font-bold text-4xl min-w-0 break-words m-0">{rssState?.data?.title}</p>
                    <p>
                      <b>{rssState?.data?.items?.length || 0}</b> episodes
                    </p>
                    <div>
                      Category:{' '}
                      <ul className="inline-flex flex-wrap gap-2 m-0">
                        {rssState?.data?.itunes?.categories?.map?.((cate) => (
                          <li key={cate} className="border rounded-xl px-2">
                            {cate}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <p>Author: {rssState?.data?.itunes?.author}</p>
                  </div>
                </div>
                <div className="bg-slate-50 p-4 rounded-md mt-4">
                  <strong className="text-xl">Introduce</strong>
                  <p className="min-w-0 break-words whitespace-pre-wrap">{rssState?.data?.description}</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImportRss;
