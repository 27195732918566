import { Button, Modal, Upload, message } from 'antd';
import { memo, useState } from 'react';
import { InboxOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DraggableContainer, { DraggableBodyRow } from 'components/Draggable';
import { LexoRank } from 'lexorank';
import { customRequest } from 'utils/helpers';
import client from 'store/apolloClient';
import { PLAYLIST_INPUT_TYPE } from 'utils/constant';
import { Field, FieldArray, Formik } from 'formik';
import { CREATE_PLAYLIST_ITEM } from '../schema';

const removeExtension = (fileName) => fileName.replace(/(.m4a|.mp3|.m3u8)$/gi, '');

const min = LexoRank.min();
const genLexorank = (prevRank) => (!prevRank ? min : LexoRank.parse(prevRank).genNext());
const { confirm } = Modal;

const AddMultipleRecord = ({ opened, onCancel, playlistId, onSuccess, lastOrder }) => {
  const [uploading, setUploading] = useState(false);

  const doCreateItems = async (values) => {
    setUploading(true);
    Promise.allSettled(
      values.map(async (i, index) => {
        const variables = {
          playlistItemInput: {
            playlistId,
            type: PLAYLIST_INPUT_TYPE.EPISODE,
            order: i.order,
          },
          episodeInput: {
            playlistId,
            title: removeExtension(i.name),
            audioUrl: i?.response?.recordingUrl,
            isFree: !lastOrder && index === 0,
            duration: Math.floor(i?.response?.duration),
          },
        };
        const result = await client.mutate({ mutation: CREATE_PLAYLIST_ITEM, variables });
        return result;
      })
    ).then((result) => {
      const allSuccess = result.every((i) => i.status === 'fulfilled');
      if (allSuccess) {
        message.success('Created');
        onSuccess?.();
      } else {
        message.error('Something went wrong');
      }
      onCancel();
      setUploading(false);
    });
  };

  const onSubmit = (values) => {
    confirm({
      centered: true,
      title: 'Important!',
      icon: <ExclamationCircleOutlined />,
      content: 'Once generated, the order of the episodes cannot be changed. Please double-check before confirming.',
      onOk: () => {
        const filesCompleted = values.files.reduce((pre, cur, curIndex) => {
          if (cur.status === 'done') {
            const last = lastOrder || min.toString();
            const prevOrder = curIndex === 0 ? last : pre[curIndex - 1]?.order; // get last rank for first item
            cur.order = genLexorank(prevOrder).toString();
            pre.push(cur);
          }
          return pre;
        }, []);
        doCreateItems(filesCompleted);
      },
    });
  };

  return (
    <Formik initialValues={{ files: [] }} onSubmit={onSubmit}>
      {({ setFieldValue, values, handleSubmit }) => {
        const { files } = values || {};
        const onChange = ({ file, fileList: newFileList }) => {
          setFieldValue('files', newFileList);
          if (file.status === 'uploading') setUploading(true);
          if (['done', 'error'].includes(file?.status)) {
            if (newFileList.every((f) => f?.status === 'done' || f?.status === 'error')) {
              setUploading(false);
            }
          }
        };

        return (
          <Modal
            okButtonProps={{ disabled: uploading, loading: uploading }}
            title="Add episodes"
            visible={opened}
            centered
            onOk={handleSubmit}
            onCancel={() => !uploading && onCancel()}
            okText="Add"
          >
            <form>
              <div className="text-red-400 mb-2">
                * IMPORTANT: Once generated, the episode cannot be changed. Please double-check before confirming.
              </div>
              <FieldArray
                name="files"
                render={({ move }) => (
                  <DraggableContainer>
                    <Upload.Dragger
                      fileList={files}
                      accept="audio/*"
                      multiple
                      onChange={onChange}
                      customRequest={customRequest}
                      itemRender={(originNode, file) => {
                        const index = files.indexOf(file);
                        return (
                          <DraggableBodyRow
                            index={index}
                            type="DragableUploadList"
                            moveRow={move}
                            className="border p-1.5 pb-3 border-t-transparent"
                          >
                            <div className="flex items-center gap-2">
                              <span>Title:</span>
                              <Field
                                name={`files[${index}].name`}
                                className="border-b w-full outline-none"
                                placeholder="Episodes title"
                              />
                            </div>

                            {originNode}
                          </DraggableBodyRow>
                        );
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single or bulk upload. Strictly prohibit from uploading company data or other band
                        files
                      </p>
                    </Upload.Dragger>
                  </DraggableContainer>
                )}
              />
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(AddMultipleRecord);
