import { useMutation, useQuery } from '@apollo/client';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Popconfirm, Spin, message } from 'antd';
import StandardTable from 'components/StandardTable';
import { useMemo, useState } from 'react';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';
import { ADMIN_DELETE_AUTHOR, ADMIN_GET_AUTHOR } from './schema';
import ModalAuthor from './components/ModalAuthor';

const Author = () => {
  const { querySearch, onSearch } = useFilter(['search']);
  const [author, setAuthor] = useState(null);

  const _filter = useMemo(() => {
    return [{ field: 'name', operator: OPERATOR.contains, value: querySearch?.search }].filter((i) => !!i.value);
  }, [querySearch?.search]);

  const { data, loading } = useQuery(ADMIN_GET_AUTHOR, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: _filter,
    },
  });
  const [deleteAuthor] = useMutation(ADMIN_DELETE_AUTHOR, {
    refetchQueries: [ADMIN_GET_AUTHOR],
    onCompleted: () => message.success('Deleted'),
  });

  const listCategory = data?.getListAuthor?.results || [];

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      render: (record) => (
        <div className="flex items-center gap-2">
          <img height={30} width={30} src={record?.avatar} alt={record?.name} className="rounded-full" />
          <span>{record?.name}</span>
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Action',
      width: 100,
      align: 'center',
      render: (val) => (
        <div className="flex items-center gap-2">
          <Button type="text" icon={<EditOutlined />} onClick={() => setAuthor(val)} />
          <Popconfirm
            // eslint-disable-next-line react/destructuring-assignment
            title="Are you sure to delete?"
            onConfirm={() => deleteAuthor({ variables: { adminDeleteAuthorId: val?.id } })}
            okText="Yes"
            cancelText="No"
          >
            <Button type="text" icon={<DeleteOutlined />} danger />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <StandardTable
        rowKey="id"
        loading={loading}
        renderToolbar={() => [
          <Input
            key="search"
            placeholder="Search by name"
            defaultValue={querySearch?.search}
            onChange={({ target }) => onSearch({ search: target.value })}
          />,
          <Button type="primary" key="primary" onClick={() => setAuthor({})}>
            Create
          </Button>,
        ]}
        dataSource={listCategory}
        columns={columns}
      />

      {!!author && <ModalAuthor visible={!!author} defaultValue={author} onDismiss={() => setAuthor(null)} />}
    </div>
  );
};

export default Author;
