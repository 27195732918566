import { gql } from '@apollo/client';

export const ALL_CAMP = gql`
  query AdminGetCampList($filter: JSON) {
    adminGetCampList(filter: $filter) {
      results {
        id
        title
      }
    }
  }
`;
