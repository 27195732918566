import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Layout, Card, Button, Typography } from 'antd';

const { Title } = Typography;

const BAN_USER = gql`
  mutation adminbanuser($id: Int!, $willBan: Boolean!) {
    adminBanUser(id: $id, willBan: $willBan) {
      username
      id
      email
      firstName
      lastName
      phone
      gender
      birthday
      website
      status
      note
    }
  }
`;

const ActionInfo = ({ user }) => {
  const [bannedUser, { loading }] = useMutation(BAN_USER);

  const handleBanUser = () => {
    bannedUser({
      variables: {
        id: user?.id,
        willBan: !(user?.status === 'banned'),
      },
    });
  };

  return (
    <Layout className="flex flex-col space-y-7">
      <Card>
        <Title level={4}>Ban User</Title>
        <Button type={user?.status === 'banned' ? 'ghost' : 'danger'} onClick={handleBanUser} disabled={loading}>
          {user?.status === 'banned' ? 'Unban' : 'Ban'}
        </Button>
      </Card>
    </Layout>
  );
};

export default ActionInfo;
