/* eslint-disable react/jsx-key */
import React from 'react';
import { Space, Button, Popconfirm, message } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { useQuery, useMutation } from '@apollo/client';
import StandardTable from 'components/StandardTable';
import { ALL_ROLES, DELETE_ROLE } from 'containers/AdminManagement/schema';
import RoleModal from './RoleModal';

const RoleContainer = () => {
  const { data: roleData, loading, refetch } = useQuery(ALL_ROLES, {
    fetchPolicy: 'no-cache',
  });

  const [deleteRole] = useMutation(DELETE_ROLE, {
    onCompleted: () => refetch?.(),
    onError: (err) => {
      if (err.message === 'ROLE_NOT_FOUND') {
        message.error('Role not found.');
      } else {
        message.error(err.message);
      }
    },
  });

  const handleDeleteRole = (roleId) => {
    deleteRole({
      variables: { id: roleId },
    });
  };

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: '',
      render: (item) => (
        <Space size="middle">
          <Popconfirm
            // eslint-disable-next-line react/destructuring-assignment
            title={`Are you sure to delete ${item.name}?`}
            onConfirm={() => handleDeleteRole(item.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className="flex justify-center items-center" ghost danger icon={<DeleteOutlined />} />
          </Popconfirm>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          <RoleModal refetch={refetch} roleData={item} type="edit">
            {(doOpenModal) => (
              <Button
                onClick={doOpenModal}
                className="flex justify-center items-center"
                ghost
                type="primary"
                icon={<EditOutlined />}
              />
            )}
          </RoleModal>
        </Space>
      ),
    },
  ];

  return (
    <StandardTable
      renderToolbar={() => [
        <RoleModal refetch={refetch} roleData={null} type="add">
          {(doOpenRoleModal) => (
            <Button type="primary" key="primary" onClick={doOpenRoleModal}>
              <PlusOutlined /> Add
            </Button>
          )}
        </RoleModal>,
      ]}
      headerTitle="Role Management"
      rowKey="id"
      dataSource={roleData?.adminGetRoles}
      columns={columns}
      loading={loading}
      pagination={{
        hideOnSinglePage: true,
        // pageSize: 1000,
      }}
    />
  );
};

export default RoleContainer;
