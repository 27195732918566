import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import { ErrorMessage } from 'formik';

const RadioInput = ({ field, options = [], title = '', disabled = false }) => {
  const { name } = field;
  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">{title}</span>
      <Radio.Group
        {...field}
        className="h-10 shadow-none flex items-center"
        size="large"
        options={options}
        disabled={disabled}
      />
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

RadioInput.prototype = {
  field: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default RadioInput;
