import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Tabs, Card } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';

import BaseInfo from './BaseInfo';
import OtherInfo from './OtherInfo';
import ActionInfo from './ActionInfo';
import HistoryInfo from './HistoryInfo';

const { TabPane } = Tabs;

const GET_USER = gql`
  query adminGetUser($adminGetUserId: Int!) {
    adminGetUser(id: $adminGetUserId) {
      username
      id
      email
      firstName
      lastName
      phone
      gender
      birthday
      website
      status
      verifiedAccount
      verifiedCreator
      note
      pendingEarning
      earning
      point
      emailVerified
      premiumType
      premiumExp
    }
  }
`;

const UserInfo = () => {
  const { id } = useParams();
  const { data, error, loading } = useQuery(GET_USER, {
    variables: { adminGetUserId: Number(id) },
  });

  const [tabKey, setTabKey] = useState('base');

  if (loading || !data?.adminGetUser) {
    return null;
  }

  return (
    <Layout className="flex flex-col px-4 gap-y-8">
      <Card>
        <Tabs type="card" defaultActiveKey="base" activeKey={tabKey} onTabClick={(key) => setTabKey(key)}>
          <TabPane tab="Account" key="base">
            <BaseInfo user={data?.adminGetUser} />
          </TabPane>
          <TabPane tab="Other" key="other">
            <OtherInfo user={data?.adminGetUser} />
          </TabPane>
          <TabPane tab="History" key="history">
            <HistoryInfo user={data?.adminGetUser} />
          </TabPane>
          <TabPane tab="Action" key="action">
            <ActionInfo user={data?.adminGetUser} />
          </TabPane>
        </Tabs>
      </Card>
    </Layout>
  );
};

export default UserInfo;
