import React from 'react';
import { Typography, Layout, Row } from 'antd';

const { Title } = Typography;

const Dashboard = () => {
  return <Layout.Content />;
};

export default Dashboard;
