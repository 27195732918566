import React from 'react';
import { RecoilRoot, useRecoilTransactionObserver_UNSTABLE as useRecoilObserver } from 'recoil';
import routes from 'common/routes';
import RouterComponent from 'common/router/Router';
import { ConfigProvider } from 'antd';
import enUSIntl from 'antd/lib/locale/en_US';

import { ApolloProvider } from '@apollo/client';
import client from 'store/apolloClient';
import { RECOIL_KEY } from 'utils/constant';

import registerPersistAtoms from 'store/atom';

// Stylesheets
//issues:https://github.com/ant-design/ant-design/issues/33327
// import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';

const Storage = window.localStorage;

function PersistenceObserver() {
  useRecoilObserver(({ snapshot }) => {
    registerPersistAtoms.forEach((atom) => {
      const atomValue = snapshot.getLoadable(atom).contents;
      const storage = Storage.getItem(atom.key);
      try {
        Storage.setItem(atom.key, JSON.stringify({ ...JSON.parse(storage), ...atomValue }));
      } catch (error) {
        Storage.setItem(atom.key, JSON.stringify(atomValue));
        console.log('Parsed err', error);
      }
    });
  });

  return null;
}

const App = () => {
  const initializeState = ({ set }) => {
    for (const [key, value] of Object.entries(Storage)) {
      if (Object.values(RECOIL_KEY).includes(key)) {
        try {
          set({ key }, JSON.parse(value));
        } catch (err) {
          console.log('Parsed err', err);
        }
      }
    }
  };

  return (
    <RecoilRoot initializeState={initializeState}>
      <PersistenceObserver />
      <ApolloProvider client={client}>
        <ConfigProvider locale={enUSIntl}>
          <RouterComponent routes={routes} defaultTitle="Onmic" />
        </ConfigProvider>
      </ApolloProvider>
    </RecoilRoot>
  );
};

export default App;
