/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import cl from 'classnames';
import { ErrorMessage } from 'formik';

const NumberInput = ({ field, form, title = '', className = '', disabled, max, min, required, ...rest }) => {
  const { errors, setFieldValue } = form;
  const { name, value } = field;

  const handleChange = (_value) => {
    setFieldValue(name, _value);
  };

  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">
        {title} {required && <span className="text-red-400">*</span>}
      </span>
      <InputNumber
        min={min}
        max={max}
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
        className={cl(
          'w-full text-black rounded-sm border-primary shadow-none focus:shadow-none focus:border-active hover:border-active border',
          className
        )}
        style={errors[name] ? { border: '2px solid red' } : {}}
        {...rest}
      />
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

NumberInput.prototype = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool,
};

export default NumberInput;
