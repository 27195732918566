import React from 'react';
import { Popover, Tooltip, Checkbox, Tree } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { genColumnKey } from 'utils/helpers/table';
import styles from './styles.module.css';

const GroupCheckboxList = ({ localColumns, columnsMap, setColumnsMap }) => {
  const treeData = localColumns.map(({ key, dataIndex, title, ...rest }) => {
    const columnKey = genColumnKey(key, dataIndex, rest.index);
    const config = columnsMap[columnKey] || { show: true };
    return { title, key: columnKey, show: config.show };
  });
  const onCheck = (checkedKeys) => {
    const columnKeyMap = localColumns.reduce((acc, cur) => {
      acc[cur.key] = {
        ...columnsMap[cur.key],
        show: checkedKeys.includes(cur.key),
      };
      return acc;
    }, {});
    setColumnsMap(columnKeyMap);
  };
  const listDom = (
    <Tree
      checkable
      checkedKeys={treeData.filter((n) => n.show).map((n) => n.key)}
      onCheck={onCheck}
      treeData={treeData}
    />
  );

  return <div className={styles.columnSettingList}>{listDom}</div>;
};

const ColumnSetting = ({ columns, columnsMap, setColumnsMap }) => {
  const setAllSelectAction = (show = true) => {
    const columnKeyMap = {};
    columns.forEach(({ key, dataIndex }) => {
      const columnKey = genColumnKey(key, dataIndex);
      if (columnKey) {
        columnKeyMap[columnKey] = {
          show,
        };
      }
    });
    setColumnsMap(columnKeyMap);
  };

  const selectedKeys = Object.values(columnsMap).filter((value) => !value || value.show === false);

  const indeterminate = selectedKeys.length > 0 && selectedKeys.length !== columns.length;

  return (
    <Popover
      arrowPointAtCenter
      title={
        <div>
          <Checkbox
            indeterminate={indeterminate}
            checked={selectedKeys.length === 0 && selectedKeys.length !== columns.length}
            onChange={(e) => {
              if (e.target.checked) {
                setAllSelectAction();
              } else {
                setAllSelectAction(false);
              }
            }}
          >
            Column Display
          </Checkbox>
          <a
            role="presentation"
            onClick={() => {
              setColumnsMap({});
            }}
          >
            Reset
          </a>
        </div>
      }
      trigger="click"
      placement="bottomRight"
      content={<GroupCheckboxList localColumns={columns} columnsMap={columnsMap} setColumnsMap={setColumnsMap} />}
    >
      <SettingOutlined
        style={{
          fontSize: 16,
        }}
      />
    </Popover>
  );
};

export default ColumnSetting;
