import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Spin, Form, Button } from 'antd';
import { ErrorMessage, FastField, Formik } from 'formik';
import * as Yup from 'yup';
import { gql, useLazyQuery } from '@apollo/client';
import { POINT_HISTORY_TYPE, OPERATOR } from 'utils/constant';
import banks from 'utils/bank.json';
import { InputFormik } from 'components/Input';
import { UndoOutlined } from '@ant-design/icons';

const { Option } = Select;

const GeneralSchema = Yup.object().shape({
  userId: Yup.number().required(),
  amount: Yup.number().required(),
  bankName: Yup.string().nullable(),
  bankCode: Yup.number().nullable(),
  bankAccountNo: Yup.number().nullable(),
  bankAccountName: Yup.string().nullable(),
  bankAcountType: Yup.string().nullable(),
  branchName: Yup.string().nullable(),
});

const ALL_USERS = gql`
  query adminGetUserList($filter: JSON, $pageSize: Int, $page: Int) {
    adminGetUserList(filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        username
        earning
      }
    }
  }
`;

const PaymentRequestModal = ({ visible, headerTitle, isLoading, onCancel = () => {}, onSubmit = () => {} }) => {
  const [getUser, { data: dataUser, loading }] = useLazyQuery(ALL_USERS);
  const result = dataUser?.adminGetUserList?.results;

  const initialValues = {
    userId: null,
    amount: null,
  };

  const handleSearchUser = (value) => {
    if (value?.trim()) {
      getUser({
        variables: {
          page: 0,
          pageSize: 10,
          filter: [{ field: 'username', operator: OPERATOR.contains, value }],
        },
      });
    }
  };

  const renderUserList = () => {
    if (!loading && result) {
      return result?.map((element) => (
        <Option key={element.id} value={element.id} disabled={element.earning < 100}>
          {element.username} <br />
          {element.earning / 100}$
        </Option>
      ));
    }
    return null;
  };

  const handleSubmit = (values) => {
    if (isLoading) return;
    onSubmit(values);
    onCancel();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur validationSchema={GeneralSchema}>
      {({ values, isSubmitting, isDirty, resetForm, setFieldValue, submitForm }) => {
        const handleChangeUser = (value) => {
          const payUser = result.find((e) => e.id === parseInt(value, 10));
          setFieldValue('userId', payUser.id);
          setFieldValue('amount', payUser.earning);
        };

        const handleChangeBank = (value) => {
          const bankSelect = banks.banksnapas.find((i) => i.bankCode === value);
          setFieldValue('bankName', bankSelect.shortName);
          setFieldValue('bankCode', value);
        };
        return (
          <Modal
            title={headerTitle}
            visible={visible}
            closable={false}
            centered
            onCancel={onCancel}
            onOk={submitForm}
            okButtonProps={{ disabled: isSubmitting || isDirty || isLoading, loading: isLoading || isSubmitting }}
          >
            <Form className="flex flex-col gap-4">
              <div>
                <p className="font-bold">User</p>
                <Select
                  value={values?.userId}
                  onChange={handleChangeUser}
                  showSearch
                  style={{ width: '100%' }}
                  filterOption={false}
                  placeholder="Select user"
                  onSearch={handleSearchUser}
                  notFoundContent={loading ? <Spin size="small" /> : null}
                >
                  {renderUserList()}
                </Select>
                <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
                  <ErrorMessage name="userId" />
                </div>
              </div>

              <div>
                <p className="font-bold">Bank</p>
                <Select
                  value={values?.bankCode}
                  onChange={handleChangeBank}
                  showSearch
                  style={{ width: '100%' }}
                  filterOption={(input, option) =>
                    option.children.findIndex((i) =>
                      i.props.children?.toLowerCase?.()?.includes?.(input.toLowerCase())
                    ) > -1
                  }
                  placeholder="Select bank"
                >
                  {banks.banksnapas.map((i) => (
                    <Select.Option key={i?.bankCode} value={i?.bankCode}>
                      <b>{i?.shortName}</b>
                      <br />
                      <span className="opacity-60">{i?.en_name}</span>
                    </Select.Option>
                  ))}
                </Select>
                <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
                  <ErrorMessage name="bankCode" />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <FastField name="bankAccountNo" title="Card number" placeholder="123*****" component={InputFormik} />
                <FastField
                  name="bankAccountName"
                  title="Full name (on the card)"
                  placeholder="Hello World"
                  component={InputFormik}
                />
              </div>

              <div className="flex justify-end">
                <Button type="link" onClick={resetForm}>
                  <div className="flex items-center gap-2">
                    <UndoOutlined /> Reset
                  </div>
                </Button>
              </div>
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

PaymentRequestModal.propTypes = {
  visible: PropTypes.bool,
  headerTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PaymentRequestModal;
