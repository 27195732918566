import { gql } from '@apollo/client';

const FRAGMENT_MASTER_SETTING = gql`
  fragment MasterSetting on MasterSetting {
    id
    name
    key
    value
    type
  }
`;

export const GET_MASTER_SETTING_LIST = gql`
  query getMasterSettingList($keys: [String!]!) {
    getMasterSettingList(keys: $keys) {
      ...MasterSetting
    }
  }
  ${FRAGMENT_MASTER_SETTING}
`;

export const UPDATE_CORE_SETTING = gql`
  mutation updateMasterSetting($id: Int!, $name: String, $value: String!, $type: MasterSettingType) {
    updateMasterSetting(id: $id, name: $name, value: $value, type: $type) {
      ...MasterSetting
    }
  }
  ${FRAGMENT_MASTER_SETTING}
`;

export const GET_ALL_TOPIC = gql`
  query getAllTopic {
    getAllTopic {
      id
      title
      slug
      image
    }
  }
`;

export const CREATE_TOPIC = gql`
  mutation adminCreateTopic($input: TopicInput!) {
    adminCreateTopic(input: $input) {
      id
      title
      slug
      image
    }
  }
`;

export const UPDATE_TOPIC = gql`
  mutation adminUpdateTopic($id: Int!, $input: TopicInput!) {
    adminUpdateTopic(id: $id, input: $input) {
      id
      title
      slug
      image
    }
  }
`;

// LIST GIFT
export const GET_LIST_GIFT = gql`
  query AdminGetListGift($filter: JSON, $sort: JSON, $page: Int, $pageSize: Int) {
    adminGetListGift(filter: $filter, sort: $sort, page: $page, pageSize: $pageSize) {
      results {
        id
        point
        image
        animationUrl
        isDeleted
        order
      }
      pageSize
      total
      currentPage
    }
  }
`;

export const CREATE_GIFT = gql`
  mutation CreateGift($input: GiftInput!) {
    createGift(input: $input) {
      id
    }
  }
`;

export const UPDATE_GIFT = gql`
  mutation UpdateGift($updateGiftId: Int!, $input: EditGiftInput!) {
    updateGift(id: $updateGiftId, input: $input) {
      id
      point
      image
      animationUrl
      isDeleted
      order
    }
  }
`;

export const DELETE_GIFT = gql`
  mutation DeleteGift($deleteGiftId: Int!) {
    deleteGift(id: $deleteGiftId) {
      id
    }
  }
`;

export const UNDELETE_GIFT = gql`
  mutation UndeleteGift($undeleteGiftId: Int!) {
    undeleteGift(id: $undeleteGiftId) {
      id
    }
  }
`;
