import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Table, Tag } from 'antd';
import { useLazyQuery, useQuery } from '@apollo/client';
import { DeleteOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import moment from 'moment';

import { OPERATOR, PLAYLIST_TYPE } from 'utils/constant';
import { Link, useParams } from 'react-router-dom';
import { getOperationName } from '@apollo/client/utilities';
import useFilter from 'utils/hooks/useFilter';
import { TYPE_COLOR } from 'containers/Room/components/RoomTable';
import RecordModal from './RecordModal';
import { GET_PLAYLIST_ITEMS } from '../schema';
import EpisodeModal from './EpisodeModal';
import AddMultipleRecord from './AddMultipleRecord';
import ModalDelete from './ModalDelete';

const DATE_FORMAT = 'DD/MM/YYYY';

const RecordTable = ({ data: playlistData }) => {
  const { id: playlistId } = useParams();
  const [itemDelete, setItemDelete] = useState(null);
  const [idOpenRecord, setIdOpenRecord] = useState(null);
  const [fetchLastItems, { data: lastItems, loading: lastItemLoading }] = useLazyQuery(GET_PLAYLIST_ITEMS, {
    fetchPolicy: 'network-only',
    variables: {
      pageSize: 1,
      filter: [{ field: 'playlistId', operator: OPERATOR.eq, value: playlistId }],
      sort: [{ field: 'order', value: 'desc' }],
    },
  });
  const { querySearch, onSearch } = useFilter(['page', 'order']);
  const [isOpenMultipleRecord, setIsOpenMultipleRecord] = useState(null);

  const lastOrder = lastItems?.adminGetPlaylistItems?.results?.[0]?.order;

  const _variables = useMemo(
    () => ({
      page: Number(querySearch?.page || 0),
      pageSize: 20,
      filter: [{ field: 'playlistId', operator: OPERATOR.eq, value: playlistId }],
      sort: [{ field: 'order', value: querySearch?.order || 'asc' }],
    }),
    [playlistId, querySearch?.order, querySearch?.page]
  );

  const { data, refetch, loading } = useQuery(GET_PLAYLIST_ITEMS, {
    variables: _variables,
    skip: !playlistId,
  });

  const { results: listData, total, currentPage, pageSize } = data?.[getOperationName(GET_PLAYLIST_ITEMS)] || {};

  const itemSelected = useMemo(() => listData?.find?.((i) => i.id === idOpenRecord), [idOpenRecord]);

  const handleChangePage = (pagination, _, sorter) => {
    const { current } = pagination;
    if (sorter?.column?.title) onSearch({ page: 0, order: sorter?.order === 'descend' ? 'desc' : 'asc' });
    else onSearch({ page: current - 1 });
  };

  const tableColumns = useMemo(() => {
    let column = [
      {
        title: '#',
        width: 80,
        render: (id, val, index) => _variables.page * 20 + index + 1,
      },
      {
        title: 'Id',
        dataIndex: 'id',
        width: 80,
      },
    ];

    if (playlistData?.type === PLAYLIST_TYPE.AUDIOBOOK) {
      column = column.concat([
        {
          title: 'Title',
          sorter: true,
          dataIndex: 'episode',
          render: (record) => record?.title,
        },
        {
          title: 'Description',
          dataIndex: 'episode',
          render: (record) => record?.description,
        },
        {
          title: 'Free',
          dataIndex: 'episode',
          render: (record) => <Checkbox checked={record?.isFree} disabled />,
        },
        {
          title: 'Published at',
          dataIndex: 'episode',
          width: 160,
          render: (record) => moment(record?.pubDate).format('HH:mm, DD/MM/YYYY'),
        },
      ]);
    } else {
      column = column.concat([
        {
          title: 'Title',
          dataIndex: 'room',
          render: (room) => <Link to={`/room/edit/${room?.id}`}>{room?.title}</Link>,
        },
        {
          title: 'Description',
          dataIndex: 'room',
          render: (room) => room?.description,
        },
        {
          title: 'Type',
          dataIndex: 'room',
          render: (room) => <Tag color={TYPE_COLOR[room?.type]}>{room?.type}</Tag>,
        },
        {
          title: 'Creator',
          dataIndex: 'room',
          render: (value) => <Link to={`/user/info/${value?.creator?.id}`}>{value?.creator?.username}</Link>,
        },
        {
          title: 'Created',
          dataIndex: 'created',
          render: (value) => moment(value).format(DATE_FORMAT),
        },
      ]);
    }

    column.push({
      title: '',
      width: 100,
      render: (val) => (
        <div className="flex items-center">
          {val?.episode && (
            <Button
              type="text"
              onClick={() => setIdOpenRecord(val.id)}
              icon={<EditOutlined style={{ fontSize: 20 }} />}
            />
          )}
          <Button
            type="text"
            onClick={() => setItemDelete(val)}
            icon={<DeleteOutlined style={{ fontSize: 20, color: 'red' }} />}
          />
        </div>
      ),
    });

    return column;
  }, [listData, playlistData?.type]);

  const EditItemModal = useMemo(() => (playlistData?.type === PLAYLIST_TYPE.AUDIOBOOK ? EpisodeModal : RecordModal), [
    playlistData?.type,
  ]);

  const handleAddRecord = async () => {
    await fetchLastItems({
      onCompleted: () => setIdOpenRecord({}),
    });
  };

  const handleAddMultipleRecord = async () => {
    await fetchLastItems({
      onCompleted: () => setIsOpenMultipleRecord(true),
    });
  };

  return (
    <div>
      <div className="flex items-center gap-2 mb-2">
        <Button
          disabled={lastItemLoading}
          type="primary"
          onClick={handleAddRecord}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <PlusOutlined /> Add
        </Button>

        <Button onClick={handleAddMultipleRecord} loading={lastItemLoading}>
          Add multiple episodes
        </Button>

        {isOpenMultipleRecord && (
          <AddMultipleRecord
            opened={isOpenMultipleRecord}
            onCancel={() => setIsOpenMultipleRecord(false)}
            onSuccess={refetch}
            playlistId={Number(playlistId)}
            lastOrder={lastOrder}
          />
        )}
      </div>
      <Table
        rowKey={(record) => `${record?.id}-${record?.type}`}
        columns={tableColumns || []}
        loading={loading}
        dataSource={listData}
        pagination={{
          total,
          showSizeChanger: false,
          defaultPageSize: pageSize || 20,
          current: (currentPage || 0) + 1,
        }}
        onChange={handleChangePage}
      />
      {idOpenRecord && (
        <EditItemModal
          refetch={() => refetch(_variables)}
          data={itemSelected}
          opened={!!idOpenRecord}
          lastOrder={lastOrder}
          onDismiss={() => setIdOpenRecord(null)}
        />
      )}

      {!!itemDelete && (
        <ModalDelete
          item={itemDelete}
          opened
          onDimiss={() => setItemDelete(null)}
          onSuccess={() => {
            setItemDelete(null);
          }}
        />
      )}
    </div>
  );
};

export default RecordTable;
