import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useMutation } from '@apollo/client';
import { CREATE_ROLE, UPDATE_ROLE, ALL_ROLES } from 'containers/AdminManagement/schema';
import RoleAddEditForm from './RoleAddEditForm';

const RoleFormWrapper = ({ refetch, roleData, type, close }) => {
  const onError = (err) => message.error(err.message || 'Something went wrong, please try again later.');

  const [addRole, { loading: addLoading }] = useMutation(CREATE_ROLE, {
    onCompleted: () => {
      refetch?.();
      close();
      message.success('Role successfully added!');
    },
    onError,
    refetchQueries: [{ query: ALL_ROLES }],
  });

  const [updateRole, { loading: updateLoading }] = useMutation(UPDATE_ROLE, {
    onCompleted: () => {
      refetch?.();
      message.success('Role successfully updated!');
    },
    onError,
  });

  const handleAddEditRole = async (values) => {
    const { code, name, permissions } = values || {};
    try {
      if (type === 'add') {
        await addRole({
          variables: {
            code,
            name,
            permissionIds: permissions
              .map((d) => {
                if (d.allow) return d.permissionId;
                return null;
              })
              .filter(Boolean),
          },
        });
      } else if (type === 'edit') {
        await updateRole({
          variables: {
            id: roleData.id,
            code,
            name,
            permissions,
          },
        });
      }
    } catch (err) {
      //
    }
  };
  return (
    <RoleAddEditForm
      close={close}
      roleData={roleData}
      type={type}
      doAddEdit={handleAddEditRole}
      loading={addLoading || updateLoading}
    />
  );
};

RoleFormWrapper.propTypes = {
  close: PropTypes.func,
  type: PropTypes.string,
  refetch: PropTypes.func,
  roleData: PropTypes.objectOf(PropTypes.any),
};
export default RoleFormWrapper;
