import { useMutation } from '@apollo/client';
import { message, Modal, Radio } from 'antd';
import { DateInput, InputFormik, TextFieldInput, SelectInput } from 'components/Input';
import UploadAudioFormik from 'components/Input/UploadAudioFormik';
import UserSelectFormik from 'components/Input/UserSelectFormik';
import RecordingSelect from 'components/RecordingSelect';
import UploadImageInput from 'components/UploadImageInput';
import { FastField, Field, Formik } from 'formik';
import moment from 'moment';
import { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PLAYLIST_INPUT_TYPE, PRICE_TICKET, ROOM_STATUS, ROOM_TYPE } from 'utils/constant';
import * as Yup from 'yup';
import { CREATE_PLAYLIST_ITEM } from '../schema';

const METHOD_TYPE = {
  CREATE: 1,
  EDIT: 2,
};

const FORM = {
  title: 'title',
  description: 'description',
  creatorId: 'creatorId',
  hostIds: 'hostIds',
  start: 'start',
  end: 'end',
  recordingUrl: 'recordingUrl',
  image: 'image',
  type: 'type',
  created: 'created',
  totalTicket: 'totalTicket',
  price: 'price',
};

const recordSchema = Yup.object().shape({
  [FORM.title]: Yup.string().required('Field is required'),
  [FORM.description]: Yup.string().nullable(),
  [FORM.creatorId]: Yup.object().required('Field is required'),
  [FORM.hostIds]: Yup.array().nullable(),
  [FORM.start]: Yup.date().required('Field is required').nullable(),
  [FORM.end]: Yup.date().required('Field is required').nullable(),
  [FORM.recordingUrl]: Yup.string().required('Field is required'),
  [FORM.image]: Yup.string().nullable(),
  [FORM.type]: Yup.string().required('Field is required'),
  [FORM.price]: Yup.number(),
  [FORM.created]: Yup.date().nullable(),
});

const formatHostIds = (speakers) => {
  return speakers.map((e) => ({ value: e.userId, label: e.user.username, key: e.userId }));
};

const MethodCreate = memo(({ method, setMethod, setValues }) => {
  const onSelectRecording = (v, options, data) => {
    const rs = data?.find?.((i) => i?.id === v);

    if (rs)
      setValues({
        roomId: rs?.id,
        title: rs?.title,
        description: rs?.description,
        start: rs?.start ? moment(rs?.start) : null,
        end: rs?.end ? moment(rs?.end) : null,
        created: moment(rs?.created),
        image: rs?.image,
        type: rs?.type,
        price: rs?.price,
        recordingUrl: rs?.recordingUrl,
        creatorId: { value: rs.creator.id, label: rs.creator.username, key: rs.creator.id },
        hostIds: formatHostIds(rs?.speakers || []),
      });
    setMethod(METHOD_TYPE.EDIT);
  };

  const onCreateNew = () => {
    setValues({ type: ROOM_TYPE.PUBLIC });
    setMethod(METHOD_TYPE.CREATE);
  };

  return (
    <div className="flex flex-col mb-4">
      <Radio checked={method === METHOD_TYPE.EDIT}>
        <div className="flex gap-2 items-center">
          <span className="mr-4">Select Recording</span>
          <div className="w-72">
            <RecordingSelect handleChange={onSelectRecording} />
          </div>
        </div>
      </Radio>
      <Radio onClick={onCreateNew} checked={method === METHOD_TYPE.CREATE}>
        Create new
      </Radio>
    </div>
  );
});

const RecordModal = ({ onDismiss, opened, data = {}, refetch }) => {
  const { id } = useParams();
  const [method, setMethod] = useState(METHOD_TYPE.CREATE);
  const isDisabled = method === METHOD_TYPE.EDIT;

  const [createItem, { loading: itemCreating }] = useMutation(CREATE_PLAYLIST_ITEM, {
    onCompleted: () => {
      message.success('Created!');
      refetch();
      onDismiss();
    },
    onError: () => message.error('Error!'),
  });

  const doSubmit = (values) => {
    const hostIds = values?.hostIds?.map?.((i) => i?.value || i);
    const creatorId = values?.creatorId?.value || values?.creatorId;
    const created = values?.created || moment().toISOString();

    const variables = {
      playlistItemInput: {
        playlistId: Number(id),
        type: PLAYLIST_INPUT_TYPE.ROOM,
      },
      roomInput: {
        ...values,
        hostIds,
        creatorId,
        created,
        duration: Math.floor(values?.duration),
        ...(values.type === ROOM_TYPE.TICKET
          ? { [FORM.totalTicket]: values.totalTicket, [FORM.price]: values.price }
          : {}),
      },
    };

    if (values?.roomId) {
      variables.playlistItemInput.roomId = Number(values.roomId);
      delete variables.roomInput;
    }

    return createItem({ variables });
  };

  const initialValues = {
    [FORM.title]: '',
    [FORM.description]: '',
    [FORM.creatorId]: '',
    [FORM.hostIds]: [],
    [FORM.start]: null,
    [FORM.end]: null,
    [FORM.recordingUrl]: '',
    [FORM.created]: null,
    [FORM.price]: PRICE_TICKET[0].value,
    [FORM.type]: ROOM_TYPE.PUBLIC,
  };

  return (
    <Formik initialValues={{ ...data, ...initialValues }} onSubmit={doSubmit} validationSchema={recordSchema}>
      {({ values, setValues, submitForm }) => {
        return (
          <Modal
            bodyStyle={{ minHeight: 350 }}
            title="Create new record"
            width="70%"
            visible={opened}
            onCancel={onDismiss}
            onOk={submitForm}
            okButtonProps={{ loading: itemCreating, disabled: itemCreating }}
            okText="Save"
            destroyOnClose
            centered
          >
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <MethodCreate method={method} setMethod={setMethod} setValues={setValues} />
              </div>

              <FastField
                className="flex flex-col"
                title="Image"
                name={FORM.image}
                useCrop
                component={UploadImageInput}
              />

              <div>
                <FastField disabled={isDisabled} component={InputFormik} name={FORM.title} title="Name" />
                <FastField
                  disabled={isDisabled}
                  component={TextFieldInput}
                  name={FORM.description}
                  title="Desciption"
                />
              </div>

              <Field title="Creator" name={FORM.creatorId} component={UserSelectFormik} disabled={isDisabled} />

              <Field
                title="Host"
                name={FORM.hostIds}
                mode="multiple"
                component={UserSelectFormik}
                disabled={isDisabled}
              />

              <div className="grid grid-cols-2 col-span-2 gap-4">
                <FastField
                  name={FORM.start}
                  disabled={isDisabled}
                  title="Start"
                  component={DateInput}
                  showTime={{ format: 'HH:mm' }}
                  format="DD/MM/YYYY HH:mm"
                />

                <Field
                  name={FORM.end}
                  disabled={isDisabled || !values?.[FORM.start]}
                  title="End"
                  component={DateInput}
                  showTime={{ format: 'HH:mm' }}
                  format="DD/MM/YYYY HH:mm"
                  disabledDate={(current) => current && current <= moment(values?.[FORM.start]).startOf('day')}
                />
              </div>

              <FastField
                name={FORM.created}
                disabled={isDisabled}
                title="Created"
                component={DateInput}
                showTime={{ format: 'HH:mm' }}
                format="DD/MM/YYYY HH:mm"
              />

              <div className="flex flex-1 items-end gap-2">
                <div className="grow">
                  <Field
                    title="Type"
                    name={FORM.type}
                    disabled={isDisabled}
                    component={SelectInput}
                    options={[
                      { label: ROOM_TYPE.PUBLIC, value: ROOM_TYPE.PUBLIC },
                      { label: ROOM_TYPE.TICKET, value: ROOM_TYPE.TICKET },
                    ]}
                  />
                </div>
                {values?.type === ROOM_TYPE.TICKET && (
                  <>
                    <Field
                      disabled={isDisabled}
                      placeholder="Total ticket"
                      name={FORM.totalTicket}
                      component={InputFormik}
                      type="number"
                    />
                    <Field
                      disabled={isDisabled}
                      placeholder="Price"
                      name={FORM.price}
                      component={SelectInput}
                      options={PRICE_TICKET}
                    />
                  </>
                )}
              </div>

              <Field
                title="Recording url"
                fieldNameDuration="duration"
                name={FORM.recordingUrl}
                component={method === METHOD_TYPE.CREATE ? UploadAudioFormik : InputFormik}
                disabled={isDisabled}
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default RecordModal;
