import * as Yup from 'yup';

export const roomSchema = Yup.object().shape({
  title: Yup.string(),
  description: Yup.string(),
  banner: Yup.string().nullable(true),
  bannerLink: Yup.string().url(),
  start: Yup.date(),
  campId: Yup.number().nullable(true),
  type: Yup.string(),
  status: Yup.string(),
  allowRecording: Yup.boolean(),
});
