import { Checkbox, Modal, message } from 'antd';
import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { DELETE_PLAYLIST_ITEM } from '../schema';

const ModalDelete = ({ item, opened, onDimiss, onSuccess }) => {
  const isPodcast = !!item?.room?.id;
  const { id, room, episode } = item || {};
  const [isDeleteRoom, setIsDeleteRoom] = useState(false);
  const [deleteItem, { loading }] = useMutation(DELETE_PLAYLIST_ITEM);

  const handleDeleteItem = () => {
    return deleteItem({
      variables: { adminDeletePlaylistItemId: id, isDeleteRoom },
      onCompleted: () => {
        message.success('Delete success!');
        onSuccess();
      },
      update: (cache) => {
        const normalizedId = cache.identify({ __typename: 'PlaylistItem', id });
        if (normalizedId) {
          cache.evict({ id: normalizedId });
          cache.gc();
        }
      },
      onError: () => message.error('Delete error!'),
    });
  };

  return (
    <Modal
      title="Delete"
      visible={opened}
      transitionName="none"
      maskTransitionName="none"
      onCancel={onDimiss}
      onOk={handleDeleteItem}
      okButtonProps={{ loading, disabled: loading, danger: true }}
      okText="Delete"
      destroyOnClose
      centered
    >
      <p className="mb-2 opacity-60">{room?.title || episode?.title}</p>

      {isPodcast && (
        <fieldset>
          <p className="m-0">
            <strong>NOTE:</strong> Mỗi tập podcast đều liên kết với 1 room, nếu muốn xoá cả room được liên kết thì tick
            vào ô dưới
          </p>
          <div className="mt-1 space-x-2">
            <Checkbox checked={isDeleteRoom} onChange={({ target }) => setIsDeleteRoom(target.checked)} id="room" />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="room">Delete room of this episode?</label>
          </div>
        </fieldset>
      )}
    </Modal>
  );
};

export default ModalDelete;
