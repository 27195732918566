import { gql, useLazyQuery } from '@apollo/client';

const GET_SUGGEST_CATEGORY = gql`
  query getSuggestCategory($title: String!, $description: String!) {
    getSuggestCategory(title: $title, description: $description) {
      id
      title
      slug
      priority
      emoji
    }
  }
`;

export const useGetSuggestCategory = () => {
  const [doSuggest, { loading }] = useLazyQuery(GET_SUGGEST_CATEGORY);

  const doRequest = ({ title, description } = {}, onCompleted = () => {}) => {
    doSuggest({
      variables: { title, description },
      onCompleted: (results) => onCompleted?.(results?.getSuggestCategory),
    });
  };

  return { doRequest, loading };
};
