import React from 'react';
import { Typography, Layout } from 'antd';
import { string, object, node } from 'prop-types';

import PointTable from './components/PointTable';
import EarningTable from './components/EarningTable';
import UserReportTable from './components/UserReportTable';

const { Title } = Typography;

const OtherItem = ({ title, children }) => (
  <div>
    {title && (
      <Title level={4} className="p-4">
        {title}
      </Title>
    )}
    <div className="px-4">{children}</div>
  </div>
);

const OtherInfoTab = ({ user }) => {
  return (
    <Layout className="flex flex-col space-y-7">
      <OtherItem title="Point History">
        <PointTable user={user} />
      </OtherItem>
      <OtherItem title="Earning History">
        <EarningTable user={user} />
      </OtherItem>
      <OtherItem title="Report">
        <UserReportTable userId={user.id} />
      </OtherItem>
    </Layout>
  );
};

OtherItem.propTypes = { title: string, children: node };
OtherInfoTab.propTypes = {
  id: string,
  user: object,
};

export default OtherInfoTab;
