import { FRAGMENT_DECORATION } from './fragment';

const { gql } = require('@apollo/client');

export const GET_LIST_DECORATION = gql`
  query GetListDecoration($page: Int, $pageSize: Int, $sort: JSON, $filter: JSON) {
    getListDecoration(page: $page, pageSize: $pageSize, sort: $sort, filter: $filter) {
      currentPage
      pageSize
      results {
        ...Decoration
      }
      total
    }
  }
  ${FRAGMENT_DECORATION}
`;

export const CREATE_DECORATION = gql`
  mutation AdminCreateDecoration($input: DecorationInput!) {
    adminCreateDecoration(input: $input) {
      ...Decoration
    }
  }
  ${FRAGMENT_DECORATION}
`;

export const UPDATE_DECORATION = gql`
  mutation AdminUpdateDecoration($adminUpdateDecorationId: Int!, $input: DecorationUpdateInput!) {
    adminUpdateDecoration(id: $adminUpdateDecorationId, input: $input) {
      ...Decoration
    }
  }
  ${FRAGMENT_DECORATION}
`;

export const DELETE_DECORATION = gql`
  mutation AdminDeleteDecoration($adminDeleteDecorationId: Int!) {
    adminDeleteDecoration(id: $adminDeleteDecorationId)
  }
`;
