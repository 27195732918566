import { PlusOutlined, EditOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Button, message, Tag, Popconfirm } from 'antd';
import React, { useState, useRef } from 'react';
import StandardTable from 'components/StandardTable';
import { gql, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { ADMIN_CREATE_CAMP, ADMIN_UPDATE_CAMP, DELETE_CAMP } from '../schema';

import CampModal from './CampModal';

const TYPE_COLOR = {
  private: 'purple',
  public: 'green',
};

const STATUS_COLOR = {
  active: 'green',
  pending: 'blue',
  deleted: 'red',
  inactive: 'gray',
};

const CampTable = ({ data, pagination, onChange, fetchCamp }) => {
  const [modalVisible, handleModalVisible] = useState(false);
  const [selectedCamp, selectCamp] = useState(null);
  const [adminCreateCamp] = useMutation(ADMIN_CREATE_CAMP, {
    onCompleted: () => {
      handleModalVisible(false);
    },
  });
  const [adminUpdateCamp] = useMutation(ADMIN_UPDATE_CAMP, {
    onCompleted: () => {
      handleModalVisible(false);
    },
  });
  const [adminDeleteCamp] = useMutation(DELETE_CAMP);

  const handleSubmit = async (values, id) => {
    if (id) {
      await adminUpdateCamp({
        variables: { id, input: values },
      });
    } else {
      await adminCreateCamp({
        variables: {
          input: values,
        },
      });
      fetchCamp();
    }
  };

  const deleteCamp = (id) => {
    adminDeleteCamp({
      variables: { id },
    });
    fetchCamp();
    message.success('Camp deleted!');
  };

  const handleCloseModal = () => {
    handleModalVisible(false);
    selectCamp(null);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 250,
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator',
      render: (val) => (
        <Link key="edit" to={`/user/info/${val.id}`}>
          {val.username}
        </Link>
      ),
    },
    {
      title: 'Camp type',
      dataIndex: 'type',
      key: 'type',
      filters: [
        { text: 'Public', value: 'public' },
        { text: 'Private', value: 'private' },
      ],
      render: (val) => <Tag color={TYPE_COLOR[val]}>{val}</Tag>,
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Pending', value: 'pending' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Deleted', value: 'deleted' },
      ],
      render: (val) => <Tag color={STATUS_COLOR[val]}>{val}</Tag>,
    },
    {
      title: 'Member count',
      dataIndex: 'memberCount',
      key: 'memberCount',
      sorter: true,
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      render: (val) => moment(val).format('DD/MM/YYYY'),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (val, record) => (
        <>
          <EditOutlined
            onClick={() => {
              selectCamp(record);
              handleModalVisible(true);
            }}
          />
          <Popconfirm
            title={`Are you sure to delete ${record.title}?`}
            onConfirm={() => deleteCamp(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <StandardTable
        headerTitle="Camp"
        rowKey="id"
        renderToolbar={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              handleModalVisible(true);
            }}
          >
            <PlusOutlined /> Add
          </Button>,
        ]}
        dataSource={data}
        columns={columns}
        allowSelectRow
        pagination={pagination}
        onChange={onChange}
      />
      <CampModal
        visible={modalVisible}
        data={selectedCamp}
        onCancel={handleCloseModal}
        headerTitle="Camp"
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default CampTable;
