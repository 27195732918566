import React, { useState, useEffect } from 'react';
import { Layout, Card, Input } from 'antd';
import { gql, useQuery } from '@apollo/client';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';
import CampTable from './components/CampTable';
import { ALL_CAMPS } from './schema';

const { Search } = Input;

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    switch (filter) {
      case 'title':
      case 'description':
        newFilter.push({ field: filter, operator: OPERATOR.contains, value: filters[filter] });
        break;
      case 'status':
      case 'type':
        newFilter.push({ field: filter, operator: OPERATOR.in, value: filters[filter] });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const Camp = ({ location }) => {
  const [sortState, updateSortState] = useState([]);
  const { data, error, loading, refetch } = useQuery(ALL_CAMPS, {
    variables: {
      page: 0,
      pageSize: 30,
      sort: sortState,
    },
  });
  const filter = useFilter(['type', 'status', 'page', 'pageSize', 'title']);
  const { pageSize = 30, status, type, page = 1, title } = filter.querySearch;

  useEffect(() => {
    if (filter.querySearch) {
      refetch({
        page: Number(page - 1),
        pageSize: Number(pageSize),
        filter: generateFilter(filter.querySearch),
        sort: sortState,
      });
    }
  }, [pageSize, page, status, type, title, data]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      updateSortState([{ field: sorter?.field, value: 'asc' }]);
    }
    if (sorter?.order === 'descend') {
      updateSortState([{ field: sorter?.field, value: 'desc' }]);
    }
    const { current, pageSize: currentPageSize } = pagination;
    filter.onSearch({ page: current, pageSize: currentPageSize, ...filters });
  };

  const onEnterSearch = (event) => {
    filter.onSearch({ title: event.target.value, page: 1 });
  };
  const onSearch = (value) => {
    filter.onSearch({ title: value, page: 1 });
  };

  const fetchCamp = () => {
    refetch({
      page: Number(page - 1),
      pageSize: Number(pageSize),
      filter: generateFilter(filter.querySearch),
    });
  };

  return (
    <Layout.Content>
      <Card>
        <Search placeholder="Search Camp" style={{ width: 400 }} onPressEnter={onEnterSearch} onSearch={onSearch} />
        <CampTable
          data={data?.adminGetCampList?.results}
          pagination={{
            reset: true,
            defaultPageSize: 30,
            showSizeChanger: true,
            total: data?.adminGetCampList?.total || 0,
            pageSizeOptions: ['5', '10', '20', '30'],
          }}
          onChange={handleTableChange}
          fetchCamp={fetchCamp}
        />
      </Card>
    </Layout.Content>
  );
};

export default Camp;
