import { CSVLink } from 'react-csv';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, message, Modal, Popconfirm, Tag } from 'antd';
import { memo, useRef, useState } from 'react';
import StandardTable from 'components/StandardTable';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { OPERATOR, PAYMENT_TICKET_STATUS, ROOM_STATUS } from 'utils/constant';
import { RollbackOutlined } from '@ant-design/icons';
import numeral from 'numeral';
import { GET_LIST_ROOM_TICKET, GET_ROOM_TICKET, UPDATE_TICKET } from './schema';
import ConfirmCancelallModal from './ConfirmCancelallModal';

const DEFAULT_PAGE_SIZE = 30;

const DATE_FORMAT = 'DD/MM/YYYY, HH:MM';

const headersCsv = [
  { label: 'Username', key: 'user.username' },
  { label: 'Email', key: 'user.email' },
  { label: 'First name', key: 'user.firstName' },
  { label: 'Last name', key: 'user.lastName' },
  { label: 'Date of payment', key: 'created' },
  { label: 'Room title', key: 'roomTitle' },
  { label: 'Creator', key: 'creator.username' },
  { label: 'Price', key: 'price' },
  { label: 'Status', key: 'status' },
];

const arrayMapperToCsv = ({ data, roomTitle, creator, price }) => {
  return data?.map((item) => ({
    ...item,
    roomTitle,
    creator,
    price,
  }));
};

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    switch (filter) {
      case 'status':
        if (filters[filter]) {
          newFilter.push({ field: filter, operator: OPERATOR.in, value: filters[filter] });
        }
        break;
      default:
        break;
    }
  });
  return newFilter;
};

const RoomDetailModal = ({ visible, onCancel, roomData }) => {
  const {
    id: roomId,
    title,
    totalTicketSold,
    totalTicket,
    price,
    start,
    created,
    creator,
    status,
    orderId,
    paymentMethod,
  } = roomData;
  const [variables, setVariables] = useState({
    roomId,
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const { data, loading, refetch } = useQuery(GET_ROOM_TICKET, {
    variables,
  });

  const [updateTicket, { loading: updating }] = useMutation(UPDATE_TICKET, {
    refetchQueries: [GET_LIST_ROOM_TICKET, GET_ROOM_TICKET],
  });

  const listTicket = data?.adminGetRoomTicket?.results || null;

  const csvRef = useRef();

  const [getAllTicket, { data: dataDowload, loading: downloading }] = useLazyQuery(GET_ROOM_TICKET, {
    fetchPolicy: 'network-only',
    onCompleted: () => csvRef.current.link.click(),
  });

  const handleRefund = (ticketId) =>
    updateTicket({
      variables: {
        adminUpdateRoomTicketId: ticketId,
        input: {
          status: PAYMENT_TICKET_STATUS.CANCELLED,
        },
      },
      onCompleted: () => message.success('Refund success'),
      onError: () => message.error('Refund fail'),
    });

  const handleChangeTable = (pagination, filters) => {
    const newVariables = { page: pagination.current - 1, filter: [] };
    const newFilter = generateFilter(filters);
    if (newFilter && newFilter.length) newVariables.filter = newFilter;
    setVariables((pre) => ({ ...pre, ...newVariables }));
  };

  const columns = [
    {
      title: 'No',
      render: (text, record, index) =>
        variables.page === 0 ? index + 1 : variables.page * variables.pageSize + (index + 1),
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: '120px',
      render: (val) => <Link to={`user/info/${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Full name',
      dataIndex: 'user',
      width: '30%',
      render: (val) => [val?.firstName, val?.lastName].join(' '),
    },
    {
      title: 'Order id',
      dataIndex: 'orderId',
      render: (val) => val || '----',
    },
    {
      title: 'Payment method',
      dataIndex: 'paymentMethod',
    },
    {
      title: 'Order at',
      dataIndex: 'created',
      render: (value) => {
        return moment(value).format(DATE_FORMAT);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: '150px',
      filters: Object.keys(PAYMENT_TICKET_STATUS).map((key) => ({
        text: PAYMENT_TICKET_STATUS[key],
        value: PAYMENT_TICKET_STATUS[key],
      })),
      render: (value) => {
        const colorTag = {
          [PAYMENT_TICKET_STATUS.PAID]: 'green',
          [PAYMENT_TICKET_STATUS.UNPAID]: 'gold',
          [PAYMENT_TICKET_STATUS.CANCELLED]: 'red',
        }[value];
        return (
          <Tag style={{ textTransform: 'capitalize' }} color={colorTag}>
            {value}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      width: '80px',
      render: (val) => {
        const disabled =
          updating || [PAYMENT_TICKET_STATUS.UNPAID, PAYMENT_TICKET_STATUS.CANCELLED].includes(val?.status);
        return (
          <Popconfirm
            title="Refund this ticket?"
            okText="Refund"
            disabled={disabled}
            onConfirm={() => handleRefund(val.id)}
          >
            <Button
              loading={updating}
              disabled={disabled}
              type="text"
              danger
              icon={<RollbackOutlined style={{ fontSize: 20 }} />}
            />
          </Popconfirm>
        );
      },
    },
  ];

  const handleDowload = () => {
    getAllTicket({
      variables: {
        ...variables,
        pageSize: 100000000,
        page: 0,
      },
    });
  };

  const detailSchema = [
    { label: 'Room id', value: roomId },
    { label: 'Status', value: status },
    { label: 'Sold', value: `${totalTicketSold || 0} / ${totalTicket}` },
    { label: 'Price', value: numeral(Math.abs(price / 100.0)).format('$0,0.00') },
    { label: 'Revenue', value: numeral(Math.abs(totalTicketSold * (price / 100.0))).format('$0,0.00') },
    { label: 'Time start', value: moment(start).format(DATE_FORMAT) },
    { label: 'Time create', value: moment(created).format(DATE_FORMAT) },
    { label: 'Creator', value: creator?.username },
    { label: 'Order id', value: orderId || '---' },
    { label: 'Payment method', value: paymentMethod || '---' },
  ];

  return (
    <Modal
      bodyStyle={{ minHeight: 350 }}
      title={`#${roomId} - ${title}`}
      visible={visible}
      destroyOnClose
      centered
      loading={loading}
      maskStyle={{ background: 'rgba(37, 39, 51, 0.2)' }}
      width="80%"
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={onCancel}
    >
      <div className="grid grid-cols-2">
        {detailSchema.map((row) => (
          <div key={row.label}>
            <b>{row.label}: </b>
            {row.value}
          </div>
        ))}
      </div>
      <div className="max-h-[60vh] overflow-auto">
        <StandardTable
          dataSource={listTicket}
          renderToolbar={() => (
            <div className="flex gap-2 ">
              <Button
                disabled={!data?.adminGetRoomTicket?.total || downloading}
                loading={downloading}
                onClick={handleDowload}
                size="small"
                className="mt-1"
              >
                Export CSV
              </Button>
              {status !== ROOM_STATUS.COMPLETED && (
                <ConfirmCancelallModal roomId={roomId}>
                  {(open) => (
                    <Button onClick={open} type="primary" danger size="small" className="mt-1">
                      Cancel event
                    </Button>
                  )}
                </ConfirmCancelallModal>
              )}
            </div>
          )}
          loading={loading}
          columns={columns}
          rowKey="id"
          scroll={{ x: 375 }}
          pagination={{
            showSizeChanger: false,
            total: data?.adminGetRoomTicket?.total || 0,
            pageSize: DEFAULT_PAGE_SIZE,
          }}
          onChange={handleChangeTable}
        />
        <CSVLink
          ref={csvRef}
          headers={headersCsv}
          data={arrayMapperToCsv({
            data: dataDowload?.adminGetRoomTicket?.results || [],
            roomTitle: title,
            price,
            creator,
          })}
          filename="List_User_Ticket_Show.csv"
        />
      </div>
    </Modal>
  );
};

export default memo(RoomDetailModal);
