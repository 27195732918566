import { useEffect, useMemo } from 'react';
import { stringifyUrl, parse, exclude } from 'query-string';
import { pick, omit, pickBy } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

const useFilter = (params, wait = 300, options = { leading: true, maxWait: 1000 }) => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => {
    return parse(location.search);
  }, [location?.search]);

  const querySearch = pick(query, params);

  const handleReset = (param) => {
    history.replace({
      pathname: location.pathname,
      search: exclude(location.search, [param]),
    });
  };

  const handleSearch = (values) => {
    const omitValues = pickBy(values, (v) => !v);
    const omitKeys = Object.keys(omitValues);
    const _query = stringifyUrl({
      url: location.pathname,
      query: {
        ...omit(query, omitKeys),
        ...omit(values, omitKeys),
      },
    });
    history.replace(_query);
  };

  const handleChangeDebounce = useDebouncedCallback(
    (values) => {
      handleSearch(values);
    },
    wait,
    options
  );

  useEffect(
    () => () => {
      handleChangeDebounce.flush();
    },
    [handleChangeDebounce]
  );

  return {
    querySearch,
    onSearch: handleChangeDebounce,
    reset: handleReset,
  };
};

export default useFilter;
