import React, { useMemo, useState } from 'react';
import { Select, Spin } from 'antd';
import { useQuery } from '@apollo/client';
import { OPERATOR } from 'utils/constant';
import { ADMIN_GET_AUTHOR } from 'containers/Playlist/schema';
import { useDebouncedCallback } from 'use-debounce';

const { Option } = Select;

const AuthorSelect = ({
  handleChange,
  renderUserList,
  title,
  disabled,
  isInvalid,
  description,
  required,
  value,
  defaultValue,
  ...selectProps
}) => {
  const [searchText, setSearchText] = useState(defaultValue);
  const _filter = useMemo(() => {
    const newFilter = [];
    if (searchText) newFilter.push({ field: 'name', operator: OPERATOR.contains, value: searchText });
    if (searchText && !Number.isNaN(+searchText)) {
      newFilter.push({ field: 'id', operator: OPERATOR.eq, value: Number(searchText) });
    }
    return newFilter;
  }, [searchText]);

  const { data: dataAuthor, loading } = useQuery(ADMIN_GET_AUTHOR, {
    variables: {
      page: 0,
      pageSize: 20,
      filter: [{ operator: OPERATOR.or, value: _filter }],
    },
  });

  const result = dataAuthor?.getListAuthor?.results || [];

  const renderUser = () => {
    if (typeof renderUserList === 'function') {
      return renderUserList();
    }
    if (!loading && result) {
      return result?.map((element) => (
        <Option key={`option__${element.name}__${element.id}`} value={element.id}>
          <div className="flex items-center gap-2">
            {element.avatar && (
              <img src={element.avatar} className="rounded-sm" height={20} width={20} alt={element.username} />
            )}
            {element.name}
          </div>
        </Option>
      ));
    }
    return null;
  };

  const handleSearch = useDebouncedCallback(
    (val) => {
      setSearchText(val);
    },
    250,
    { leading: false, trailing: true }
  );

  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">
        {title || 'Author'} {required && <span className="text-red-400">*</span>}
      </span>
      <Select
        onChange={handleChange}
        showSearch
        value={value}
        loading={loading}
        style={{ width: '100%', border: isInvalid ? '2px solid red' : '' }}
        filterOption={false}
        placeholder="Please select author"
        onSearch={handleSearch}
        notFoundContent={loading ? <Spin size="small" /> : null}
        disabled={disabled}
        defaultValue={defaultValue}
        {...selectProps}
      >
        {renderUser()}
      </Select>
      {description && <p className="text-xs text-gray-400 mt-1">{description}</p>}
    </div>
  );
};

export default AuthorSelect;
