import React from 'react';
import { Layout, Card, Input, Button, message, Select } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';
import ReportTable from 'components/ReportTable';

const { Search } = Input;

const selectOptions = [
  { label: 'All', value: '' },
  { label: 'User', value: 'user' },
  { label: 'Wave', value: 'wave' },
  { label: 'Room', value: 'room' },
  { label: 'Review', value: 'review' },
];

const ALL_REPORT = gql`
  query getReports($filter: JSON, $pageSize: Int, $page: Int, $sort: JSON) {
    getReports(filter: $filter, pageSize: $pageSize, page: $page, sort: $sort) {
      total
      results {
        id
        roomId
        userId
        waveId
        reviewId
        reason
        category
        created
        creator {
          id
          username
        }
        user {
          id
          username
        }
        room {
          id
          title
        }
        wave {
          id
          description
        }
        review {
          id
          playlistId
          content
        }
      }
    }
  }
`;

const ADMIN_DELETE_REPORT = gql`
  mutation adminDeleteReport($id: Int!) {
    deleteReport(id: $id) {
      id
    }
  }
`;

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    const value = filters?.[filter];
    switch (filter) {
      case 'search':
        newFilter.push({
          operator: OPERATOR.or,
          value: [
            { field: 'reason', operator: OPERATOR.contains, value },
            ...(!Number.isNaN(+value) ? [{ field: 'id', operator: OPERATOR.eq, value: Number(value) }] : []),
            ...(!Number.isNaN(+value) ? [{ field: 'userId', operator: OPERATOR.eq, value: Number(value) }] : []),
            ...(!Number.isNaN(+value) ? [{ field: 'roomId', operator: OPERATOR.eq, value: Number(value) }] : []),
            ...(!Number.isNaN(+value) ? [{ field: 'waveId', operator: OPERATOR.eq, value: Number(value) }] : []),
            ...(!Number.isNaN(+value) ? [{ field: 'reviewId', operator: OPERATOR.eq, value: Number(value) }] : []),
          ],
        });
        break;
      case 'type':
        if (value === 'user') newFilter.push({ field: 'userId', operator: OPERATOR.null });
        if (value === 'wave') newFilter.push({ field: 'waveId', operator: OPERATOR.null });
        if (value === 'room') newFilter.push({ field: 'roomId', operator: OPERATOR.null });
        if (value === 'review') newFilter.push({ field: 'reviewId', operator: OPERATOR.null });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const Report = () => {
  const filter = useFilter(['page', 'pageSize', 'userId', 'creatorId', 'roomId', 'type', 'search', 'sort']);
  const { pageSize = 30, page = 1, sort = 'desc' } = filter.querySearch;
  const { data, loading } = useQuery(ALL_REPORT, {
    variables: {
      page: Number(page - 1),
      pageSize: Number(pageSize),
      sort: [{ field: 'created', value: sort }],
      filter: generateFilter(filter.querySearch),
    },
  });
  const [adminDeleteReport] = useMutation(ADMIN_DELETE_REPORT, {
    onCompleted: () => {
      message.success('Xóa thành công');
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: [ALL_REPORT, 'getReports'],
  });

  const handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize: currentPageSize } = pagination;
    if (sorter?.field === 'created') {
      filters.sort = sorter.order === 'ascend' ? 'asc' : 'desc';
    }
    filter.onSearch({ page: current, pageSize: currentPageSize, ...filters });
  };

  const handleDeleteReport = (id) => {
    adminDeleteReport({ variables: { id } });
  };

  const onEnterSearch = (event) => {
    filter.onSearch({ search: event.target.value, page: 1 });
  };
  const onSearch = (event) => {
    filter.onSearch({ search: event, page: 1 });
  };

  return (
    <Layout.Content>
      <Card>
        <div className="space-x-1">
          <Select
            className="w-28"
            options={selectOptions}
            value={filter.querySearch?.type || selectOptions[0].value}
            onChange={(val) => filter.onSearch({ type: val, page: 1 })}
          />
          <Search
            defaultValue={filter.querySearch?.search}
            placeholder="ID, reason, user id, wave id, room id, review id"
            style={{ width: 400 }}
            onPressEnter={onEnterSearch}
            onSearch={onSearch}
          />
          <Button>Total: {data?.getReports?.total}</Button>
        </div>

        <ReportTable
          loading={loading}
          data={data?.getReports?.results}
          pagination={{
            reset: true,
            defaultPageSize: 30,
            showSizeChanger: true,
            total: data?.getReports?.total || 0,
            pageSizeOptions: ['5', '10', '20', '30'],
          }}
          onChange={handleTableChange}
          onDelete={handleDeleteReport}
        />
      </Card>
    </Layout.Content>
  );
};

export default Report;
