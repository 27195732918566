import React from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import moment from 'moment';

const dateTimeFormat = 'DD/MM/YYYY HH:mm';
const dateFormat = 'DD/MM/YYYY';
const DateInput = ({ field, form, title = '', required, ...rest }) => {
  const { setFieldValue } = form;
  const { name, value } = field;
  const { showTime } = rest || {};

  const format = showTime ? dateTimeFormat : dateFormat;

  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">
        {title} {required && <span className="text-red-400">*</span>}
      </span>
      <DatePicker
        {...field}
        {...rest}
        format={format}
        value={value ? moment(value, moment.HTML5_FMT.DATETIME_LOCAL) : null}
        size="medium"
        showToday={false}
        picker="date"
        onChange={(date) => {
          setFieldValue(name, date);
        }}
      />
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

DateInput.prototype = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default DateInput;
