import { gql } from '@apollo/client';

export const ALL_CAMPS = gql`
  query adminGetCampList($filter: JSON, $sort: JSON, $pageSize: Int, $page: Int) {
    adminGetCampList(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      total
      results {
        id
        title
        description
        avatar
        creator {
          id
          username
        }
        status
        type
        memberCount
        created
        topic {
          id
          title
        }
      }
    }
  }
`;

export const DELETE_CAMP = gql`
  mutation adminDeleteCamp($id: Int!) {
    adminDeleteCamp(id: $id) {
      id
    }
  }
`;

export const ADMIN_CREATE_CAMP = gql`
  mutation adminCreateCamp($input: AdminCampInput!) {
    adminCreateCamp(input: $input) {
      id
    }
  }
`;

export const ADMIN_UPDATE_CAMP = gql`
  mutation adminUpdateCamp($id: Int!, $input: AdminCampInput!) {
    adminUpdateCamp(id: $id, input: $input) {
      id
      title
      description
      avatar
      status
      type
      memberCount
      topic {
        id
        title
      }
    }
  }
`;
