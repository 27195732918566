export const RECOIL_KEY = {
  AUTH: 'auth',
  SETTING: 'setting',
  CUSTOMER: 'customer',
};

export const OPERATOR = {
  or: 'or',
  in: 'in',
  nin: 'nin',
  eq: 'eq',
  ne: 'ne',
  lt: 'lt',
  lte: 'lte',
  gt: 'gt',
  gte: 'gte',
  contains: 'contains',
  ncontains: 'ncontains',
  containss: 'containss',
  like: 'like',
  ncontainss: 'ncontainss',
  null: 'null',
  fts: 'fts',
};

export const POINT_HISTORY_TYPE = {
  recharge: 'Recharge',
  refunds: 'Refunds',
  gift_sent: 'Gift Sent',
  awards: 'Awards',
  other: 'Other',
};

export const EARNING_HISTORY_TYPE = {
  gift_received: 'Gift Received',
  withdraw: 'Withdraw',
  room_ticket: 'Room Ticket',
  refund_ticket: 'Refund ticket',
};

export const PAYMENT_REQUEST_STATUS = {
  pending: 'Pending',
  completed: 'Completed',
  rejected: 'Rejected',
};

export const USER_STATUS = {
  active: 'Active',
  deleted: 'Deleted',
  banned: 'Banned',
};

export const CAMP_STATUS = {
  active: 'Active',
  pending: 'Pending',
  inactive: 'Inactive',
  deleted: 'Deleted',
};

export const CAMP_TYPE = {
  public: 'Public',
  private: 'Private',
};

export const ERROR = {
  ADMIN: {
    USERNAME_OR_EMAIL_EXISTED: 'USERNAME_OR_EMAIL_EXISTED',
  },
};
export const PERMISSION_LIST = {
  MANAGE_ADMIN: 'manage-admin',
  MANAGE_CAMP: 'manage-camp',
  MANAGE_USER: 'manage-user',
  MANAGE_ROOM: 'manage-room',
  MANAGE_NOTIFICATION: 'manage-notification',
  MANAGE_PAYMENT: 'manage-payment',
  MANAGE_SETTING: 'manage-setting',
  MANAGE_REPORT: 'manage-report',
  MANAGE_GIFT: 'manage-gift',
  MANAGE_GAME: 'manage-game',
  MANAGE_GAME_QUESTION: 'manage-game-question',
  MANAGE_PLAYLIST: 'manage-playlist',
  MANAGE_COMMENT: 'manage-comment',
  MANAGE_WAVE: 'manage-wave',
  MANAGE_COUPON: 'manage-coupon',
  MANAGE_DECORATION: 'manage-decoration',
};

export const PAYMENT_TICKET_STATUS = {
  UNPAID: 'unpaid',
  PAID: 'paid',
  CANCELLED: 'cancelled',
};

export const PAYMENT_METHODS = {
  CASH: 'cash',
  VNPAY: 'vnpay',
  MOMO: 'momo',
  PAYPAL: 'paypal',
  STRIPE: 'stripe',
};

export const ROOM_STATUS = {
  OPEN: 'open',
  LIVE: 'live',
  COMPLETED: 'completed',
};

export const EARNING_HISTORY_STATUS = {
  PENDING: 'pending',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const PLAYLIST_TYPE = {
  USER: 'user',
  TOPIC: 'channel',
  AUDIOBOOK: 'audiobook',
};

export const PLAYLIST_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  DELETED: 'deleted',
};

export const PLAYLIST_INPUT_TYPE = {
  ROOM: 'room',
  EPISODE: 'episode',
};

export const ROOM_TYPE = {
  PUBLIC: 'public',
  SOCIAL: 'social',
  PRIVATE: 'private',
  CAMP: 'camp',
  TICKET: 'ticket',
};

export const MAX_NUMERIC_VALUE = 2147483647;

export const PRICE_TICKET = [
  {
    label: 'Event free',
    value: 0,
  },
  {
    label: '$0.5',
    value: 50,
  },
  {
    label: '$1',
    value: 100,
  },
  {
    label: '$2',
    value: 200,
  },
  {
    label: '$3',
    value: 300,
  },
  {
    label: '$4',
    value: 400,
  },
  {
    label: '$5',
    value: 500,
  },
  {
    label: '$6',
    value: 600,
  },
  {
    label: '$8',
    value: 800,
  },
  {
    label: '$10',
    value: 1000,
  },
  {
    label: '$15',
    value: 1500,
  },
  {
    label: '$20',
    value: 2000,
  },
  {
    label: '$25',
    value: 2500,
  },
  {
    label: '$30',
    value: 3000,
  },
  {
    label: '$50',
    value: 5000,
  },
  {
    label: '$100',
    value: 10000,
  },
];

export const UPLOAD_PATH = `https://internal1.getonmic.com/upload-recording`;
export const BOOK_COVER_RATIO = 1410 / 2250;
export const THUMBNAIL_SHARING_RATIO = 1200 / 630;

export const SERIES_PRODUCT_ID_OPTIONS = [
  { label: 'Free', value: '' },
  { label: 'Book 19k', value: 'com.onmic.bookintro' },
  { label: 'Book 59k', value: 'com.onmic.book59' },
  { label: 'Book 79k', value: 'com.onmic.book79' },
  { label: 'Book 99k', value: 'com.onmic.book99' },
  { label: 'Book 129k', value: 'com.onmic.book129' },
  { label: 'Book 199k', value: 'com.onmic.book199' },
  { label: 'Book 249k', value: 'com.onmic.book249' },
  { label: 'Book 299k', value: 'com.onmic.book299' },
  { label: 'Book 399k', value: 'com.onmic.book399' },
  { label: 'Book 599k', value: 'com.onmic.book599' },
  { label: 'Book 699k', value: 'com.onmic.book699' },
];

export const BUY_INTRO = 'https://onmic-1.s3.ap-southeast-1.amazonaws.com/ticket_show.m4a';

export const COUPON_STATUS = {
  active: 'active',
  inactive: 'inactive',
};

export const COUPON_TYPE = {
  all: 'all',
  playlist: 'playlist',
  ticket: 'ticket',
  package: 'package',
};

export const DECORATION_TYPE = {
  subscription: 'subscription',
};

export const PURCHASE_TYPE = {
  free: 'free',
  // full: 'full',
  subscription: 'subscription',
  coin: 'coin',
};

export const PREMIUM_TYPE = {
  FREE: 'free',
  VIP: 'vip',
};
