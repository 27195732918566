import { gql } from '@apollo/client';

export const FRAGEMENT_REVIEW = gql`
  fragment ReviewInfo on Review {
    id
    content
    rating
    helpfulCount
    created
    creator {
      id
      firstName
      lastName
      username
      avatar
    }
  }
`;

export const UPDATE_PLAYLIST = gql`
  mutation AdminUpdatePlaylist($adminUpdatePlaylistId: Int!, $input: PlaylistInput!) {
    adminUpdatePlaylist(id: $adminUpdatePlaylistId, input: $input) {
      id
      title
      status
    }
  }
`;

export const CREATE_PLAYLIST_ITEM = gql`
  mutation AdminCreatePlaylistItem(
    $playlistItemInput: AdminCreatePlaylistItemInput!
    $roomInput: AdminCreateRoomInput
    $episodeInput: AdminCreateEpisodeInput
  ) {
    adminCreatePlaylistItem(playlistItemInput: $playlistItemInput, roomInput: $roomInput, episodeInput: $episodeInput) {
      id
      playlistId
    }
  }
`;

export const GET_PLAYLIST_ITEMS = gql`
  query adminGetPlaylistItems($filter: JSON, $page: Int, $pageSize: Int, $sort: JSON) {
    adminGetPlaylistItems(filter: $filter, page: $page, pageSize: $pageSize, sort: $sort) {
      results {
        id
        roomId
        playlistId
        type
        image
        created
        order
        episode {
          id
          title
          description
          duration
          isFree
          pubDate
          audioUrl
        }
        room {
          title
          id
          description
          start
          end
          type
          recordingUrl
          creator {
            id
            username
          }
          speakers {
            id
            userId
            role
            user {
              id
              username
            }
          }
        }
      }
      total
      currentPage
      pageSize
    }
  }
`;

export const DELETE_PLAYLIST_ITEM = gql`
  mutation AdminDeletePlaylistItem($adminDeletePlaylistItemId: Int!, $isDeleteRoom: Boolean) {
    adminDeletePlaylistItem(id: $adminDeletePlaylistItemId, isDeleteRoom: $isDeleteRoom) {
      id
    }
  }
`;

export const ADMIN_GET_REVIEWS = gql`
  query adminGetReviews($filter: JSON, $sort: JSON, $pageSize: Int, $page: Int) {
    adminGetReviews(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      currentPage
      pageSize
      total
      results {
        ...ReviewInfo
      }
    }
  }
  ${FRAGEMENT_REVIEW}
`;

export const ADMIN_DELETE_REVIEWS = gql`
  mutation AdminDeleteReview($adminDeleteReviewId: Int!) {
    adminDeleteReview(id: $adminDeleteReviewId) {
      id
    }
  }
`;

export const ADMIN_EDIT_EPISODE = gql`
  mutation AdminEditEpisode($adminEditEpisodeId: Int!, $input: EpisodeInput!) {
    adminEditEpisode(id: $adminEditEpisodeId, input: $input) {
      id
      isFree
      title
      audioUrl
    }
  }
`;
