import React, { useCallback, useMemo } from 'react';
import { Button, Tag, message, Input, Checkbox } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { omitBy, isUndefined, isNull } from 'lodash';
import moment from 'moment';
import { userSchema } from 'common/validationSchema';
import { TextFieldInput, InputFormik, SelectInput, DateInput } from 'components/Input';
import { FastField, Form, Formik } from 'formik';
import CheckBoxInput from 'components/Input/CheckBoxInput';
import { PREMIUM_TYPE } from 'utils/constant';

const UPD_USER = gql`
  mutation adminUpdateUser($id: Int!, $input: AdminUpdateUserInput!) {
    adminUpdateUser(id: $id, input: $input) {
      username
      id
      email
      firstName
      lastName
      phone
      gender
      birthday
      website
      verifiedAccount
      verifiedCreator
      status
      note
    }
  }
`;

const GENDER = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Non-binary', value: 'non_binary' },
  { label: 'Others', value: 'others' },
  { label: 'Prefer not to say', value: 'prefer_not_to_say' },
];

const disabledDate = (current) => {
  return current && current > moment();
};

const EditUser = ({ user }) => {
  const [updateUser, { loading: mutationLoading }] = useMutation(UPD_USER, {
    onCompleted() {
      message.success('User updated!');
    },
    onError() {
      message.error('User updated fail!');
    },
  });

  const onSubmit = (values, { setSubmitting }) => {
    const inputForm = {
      username: values.username,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      website: values.website,
      gender: values.gender,
      birthday: values.birthday,
      note: values.note,
      verifiedAccount: values.verifiedAccount,
      verifiedCreator: values.verifiedCreator,
    };
    if (inputForm.birthday != null) {
      const day = moment(values.birthday).format(moment.HTML5_FMT.DATE);
      inputForm.birthday = day;
    }
    updateUser({
      variables: {
        id: user.id,
        input: omitBy(inputForm, (v) => isUndefined(v) || isNull(v)),
      },
    });
    setSubmitting(false);
  };

  const initialValues = {
    username: user?.username || '',
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    website: user?.website || '',
    gender: user?.gender || '',
    birthday: user?.birthday,
    note: user?.note || '',
    verifiedAccount: user?.verifiedAccount || false,
    verifiedCreator: user?.verifiedCreator || false,
  };

  const EmailTitle = () => (
    <>
      <span className="mr-2">Email</span>
      {user?.emailVerified ? <Tag color="success">Verified</Tag> : <Tag color="warning">Unverified</Tag>}
    </>
  );

  const renderUserStatus = useCallback(() => {
    return user?.status?.charAt(0).toUpperCase() + user?.status?.slice(1);
  }, [user?.status]);

  return (
    <Formik initialValues={initialValues} validationSchema={userSchema} validateOnBlur onSubmit={onSubmit}>
      {({ values, isValid, isSubmitting, isDirty, resetForm, submitForm }) => {
        return (
          <Form>
            {user?.premiumType === PREMIUM_TYPE.VIP && (
              <div>
                <Tag color="gold">Premium</Tag> User subscribed to premium, which expires on the{' '}
                {moment(user?.premiumExp).format('HH:mm, DD/MM/YYYY')}
              </div>
            )}
            <div className="grid grid-cols-2 gap-y-2 gap-x-8 mt-4">
              <FastField name="username" component={InputFormik} title="Username" type="text" />
              <FastField name="email" component={InputFormik} title={<EmailTitle />} disabled />
              <FastField name="firstName" component={InputFormik} title="Firstname" type="text" />
              <FastField name="lastName" component={InputFormik} title="Lastname" type="text" />
              <FastField name="website" component={InputFormik} title="URL" />
              <FastField name="birthday" component={DateInput} title="Birthday" disabledDate={disabledDate} />
              <FastField name="gender" component={SelectInput} options={GENDER} title="Gender" />
              <FastField name="note" component={TextFieldInput} title="Note" />
            </div>

            <div className="mb-4">
              <span className="text-gray font-bold text-18 mr-4">Status</span>
              <Tag color={{ active: 'success', default: 'warning' }[user?.status]} className="h-6">
                {renderUserStatus()}
              </Tag>
            </div>
            <div className="flex flex-wrap flex-row items-center gap-x-5">
              <FastField
                name="verifiedAccount"
                checked={values.verifiedAccount}
                title="Verified Account"
                component={CheckBoxInput}
                className="flex flex-row gap-x-2 items-center"
              />
              <FastField
                name="verifiedCreator"
                checked={values.verifiedCreator}
                title="Premium creator"
                className="flex flex-row gap-x-2 items-center"
                component={CheckBoxInput}
              />
            </div>

            <div className="col-span-2 flex justify-end gap-2">
              <Button htmlType="button" onClick={resetForm} style={{ marginRight: 10 }}>
                Reset
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isValid || isSubmitting || isDirty || mutationLoading}
                loading={isSubmitting || mutationLoading}
                style={{ marginRight: 10 }}
              >
                Update
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditUser;
