import { ApolloLink, HttpLink, from, split, ApolloClient, InMemoryCache } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';

import { RECOIL_KEY } from 'utils/constant';

import { logoutUnset } from 'utils/request';

const { REACT_APP_GRAPHQL_URI, REACT_APP_WS_URI } = process.env;

export const getToken = () => {
  try {
    const auth = window.localStorage.getItem(RECOIL_KEY.AUTH);
    return auth ? JSON.parse(auth)?.token : '';
  } catch (error) {
    return '';
  }
};

const retryLink = new RetryLink({
  attempts: {
    max: 2,
    retryIf: (error, _operation) => !!error && _operation !== 'mutation',
  },
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true,
  },
});

/* Apollo setup */
const httpLink = new HttpLink({ uri: REACT_APP_GRAPHQL_URI });

const wsLink = new GraphQLWsLink(
  createClient({
    url: REACT_APP_WS_URI,
    lazy: true,
    shouldRetry: (errOrCloseEvent) => !!errOrCloseEvent,
    lazyCloseTimeout: 2000,
    connectionParams: () => {
      const token = getToken();
      return { authorization: token ? `Bearer ${token}` : null };
    },
  })
);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const mainLink = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);

    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (process.env.NODE_ENV !== 'production') {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, path, extensions = {} }) =>
        console.log(
          `%c [GraphQL error]: Message: ${message}, Code: ${extensions.code}, Path: ${path && path[0]}`,
          'color: red'
        )
      );
    }
  }
  if (graphQLErrors) {
    // graphQLErrors.map(({ extensions = {} }) => extensions.code === 'UNAUTHENTICATED' && logoutUnset());
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    if (networkError.result && networkError.result.code === 401) {
      logoutUnset();
      // eslint-disable-next-line
      networkError.result.errors = null;
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken();

  operation.setContext({
    headers: { authorization: token ? `Bearer ${token}` : null },
  });

  return forward(operation);
});

// const cleanTypenameLink = new ApolloLink((operation, forward) => {
//   if (operation.variables) {
//     // eslint-disable-next-line
//     operation.variables = omitDeep(operation.variables, '__typename')
//   }

//   return forward(operation);
// });

// logger
const loggerLink = new ApolloLink((operation, forward) =>
  forward(operation).map((result) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`%c [GraphQL Logger] received result from ${operation.operationName}`, 'color: gray');
      console.log(result?.data || result);
    }

    return result;
  })
);

function pageSizePagination(keyArgs = false) {
  return {
    keyArgs,
    merge(existing, incoming, { args, readField }) {
      if (!args) return existing;
      if (existing) {
        const existingIdSet = new Set(existing.results?.map((item) => readField('id', item)) || []);
        // Remove incoming item already present in the existing data.
        const mergeResult = incoming?.results?.filter((item) => !existingIdSet.has(readField('id', item)));
        return {
          ...incoming,
          results: [...existing.results, ...mergeResult],
        };
      }
      return incoming;
    },
  };
}

function pageSizeIterator(keyArgs = false) {
  return {
    keyArgs,
    merge(existing, incoming, { args, readField }) {
      if (!args) return existing;
      if (existing) {
        const existingIdSet = new Set(existing?.map((item) => readField('id', item)) || []);
        // Remove incoming item already present in the existing data.
        const mergeResult = incoming?.filter((item) => !existingIdSet.has(readField('id', item)));
        return [...existing, ...mergeResult];
      }
      return incoming;
    },
  };
}

function pageSizeCursor() {
  return {
    keyArgs: false,
    merge(existing, incoming, { args, readField }) {
      if (existing) {
        if (args?.cursor) {
          const existingIdSet = new Set(existing?.map((item) => readField('id', item)) || []);
          // Remove incoming item already present in the existing data.
          const mergeResult = incoming?.filter((item) => !existingIdSet.has(readField('id', item)));
          return [...existing, ...mergeResult];
        }
        if (existing?.length < incoming?.length) {
          return incoming;
        }
        return existing;
      }
      return incoming;
    },
  };
}

const client = new ApolloClient({
  link: from([authLink, loggerLink, errorLink, retryLink, mainLink]),
  cache: new InMemoryCache({}),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
