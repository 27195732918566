import React, { useState } from 'react';
import { Input, Image, Spin } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import UploadImage from 'components/UploadImage';
import classNames from 'classnames';

const UploadImageInput = ({
  form,
  preview = false,
  field,
  className = '',
  value,
  onChange,
  height = 150,
  width = 250,
  title,
  useCrop,
  aspect,
  required,
}) => {
  const [stateUrl, setStateUrl] = useState(value || field?.value);
  const { name } = field;
  const { errors } = form || {};

  const [uploading, setUploading] = useState(false);

  return (
    <div className={classNames('relative', className)}>
      {title && (
        <p className="text-gray mb-2 font-bold h-5 text-18">
          {title} {required && <span className="text-red-400">*</span>}
        </p>
      )}
      <UploadImage
        useCrop={useCrop}
        aspect={aspect}
        disabled={uploading}
        onBeforeUpload={() => setUploading(true)}
        onFinish={(url) => {
          if (form) {
            form?.setFieldValue(name, url);
          }
          setStateUrl(url);
          setUploading(false);
          if (typeof onChange === 'function') onChange(url);
        }}
      >
        <div
          className="flex items-center justify-center border border-dashed hover:border-blue-400 p-2"
          style={{ width, height, aspectRatio: `${aspect}` }}
        >
          <Image
            width="100%"
            height="100%"
            style={{ objectFit: 'contain', objectPosition: 'center' }}
            src={stateUrl}
            placeholder={
              <PictureOutlined
                className="w-full m-auto h-full !flex justify-center items-center"
                style={{ fontSize: '6em' }}
              />
            }
            preview={preview}
          />
        </div>
      </UploadImage>
      <Input className="absolute" name={name} hidden value={stateUrl} />
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">{errors[name]}</div>
    </div>
  );
};

export default UploadImageInput;
