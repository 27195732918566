import { useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import { InputFormik, TextFieldInput } from 'components/Input';
import UploadImageInput from 'components/UploadImageInput';
import { FastField, Formik } from 'formik';
import { ADMIN_CREATE_AUTHOR, ADMIN_GET_AUTHOR, ADMIN_UPDATE_AUTHOR } from '../schema';

const ModalAuthor = ({ visible, onDismiss, defaultValue = {} }) => {
  const [createAuthor, { loading: creating }] = useMutation(ADMIN_CREATE_AUTHOR, {
    refetchQueries: [ADMIN_GET_AUTHOR],
    onCompleted: () => {
      message.success('Created!');
      onDismiss();
    },
    onError: () => message.error('Error!'),
  });
  const [editAuthor, { loading: editing }] = useMutation(ADMIN_UPDATE_AUTHOR, {
    refetchQueries: [ADMIN_GET_AUTHOR],
    onCompleted: () => {
      message.success('Edit completed');
      onDismiss();
    },
    onError: () => message.error('Error!'),
  });

  const isEdit = !!defaultValue?.id;

  const onSubmit = (values) => {
    const input = {
      name: values.name,
      description: values.description,
      avatar: values.avatar || '',
    };
    if (isEdit) {
      editAuthor({ variables: { adminUpdateAuthorId: defaultValue.id, input } });
    } else {
      createAuthor({ variables: { input } });
    }
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={defaultValue}>
      {({ submitForm }) => {
        return (
          <Modal
            title={defaultValue?.name || 'Create author'}
            visible={visible}
            destroyOnClose
            onCancel={onDismiss}
            closable={!creating || !editing}
            centered
            onOk={submitForm}
            okButtonProps={{ loading: creating || editing, disabled: creating || editing }}
            okText={isEdit ? 'Save' : 'Create'}
          >
            <FastField
              className="flex flex-col"
              width={150}
              title="Avatar"
              name="avatar"
              useCrop
              required
              component={UploadImageInput}
            />
            <FastField title="Name" placeholder="Author's name" required name="name" component={InputFormik} />
            <FastField
              title="Description"
              placeholder="Something about author"
              name="description"
              component={TextFieldInput}
            />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ModalAuthor;
