/* eslint-disable react/prop-types */
import React from 'react';
import { Result } from 'antd';
import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';

const Authorized = ({
  children,
  authority,
  noMatch = <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />,
}) => {
  const auth = useRecoilValue(authState);
  const childrenRender = typeof children === 'undefined' ? null : children;
  const { permissions = [] } = auth;

  if (authority) {
    const found = permissions.find((item) => item.code === authority);

    if (found && found.allow) {
      return childrenRender;
    }

    return <>{noMatch}</>;
  }

  return <>{childrenRender}</>;
};

export default Authorized;
