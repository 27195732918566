import React, { useState, useCallback, useRef } from 'react';
import { Result, message, Table, Button, Image, Popconfirm } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useMutation } from '@apollo/client';
import { PictureOutlined, PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import TableHeader from 'components/StandardTable/TableHeader';
import styles from './styles.module.css';
import { UPDATE_CORE_SETTING } from '../schema';
import BannerModal from './BannerModal';

const type = 'DraggableBodyRow';

const parseData = (data) => {
  try {
    const banners = JSON.parse(data?.value);
    return banners;
  } catch {
    return [];
  }
};

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: 'outline outline-1 outline-dashed outline-offset-[-3px] outline-blue-400',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className} ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

function Banner({ data, loading, error }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedBanner, selectBanner] = useState(null);

  const dataSource = parseData(data);

  const [updateSetting] = useMutation(UPDATE_CORE_SETTING, {
    onCompleted: () => {
      message.success('Upload completed!');
    },
    onError: (err) => {
      message.error(err?.message);
    },
  });

  const handleEditBanner = (record, index) => {
    selectBanner({ ...record, index });
    setModalVisible(true);
  };

  const handleDeleteBanner = async (image) => {
    const newData = dataSource.filter((d) => d?.image !== image);
    await updateSetting({ variables: { id: data.id, value: JSON.stringify(newData) } });
  };

  const onCancel = () => {
    selectBanner(null);
    setModalVisible(false);
  };

  async function handleSubmit(formValues, index) {
    try {
      const newData = Object.assign(dataSource, {});
      if (index) {
        newData[index] = formValues;
      } else {
        newData.push(formValues);
      }
      await updateSetting({ variables: { id: data.id, value: JSON.stringify(newData) } });
      onCancel();
    } catch (e) {
      console.log(e);
      message.error(e?.message);
    }
  }

  if (error) {
    return <Result status="error" title="Fail to fetch data" />;
  }

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const newData = [...dataSource];
      const record = newData[dragIndex];
      if (dragIndex !== hoverIndex) {
        newData.splice(dragIndex, 1);
        newData.splice(hoverIndex, 0, record);
        const params = { id: data.id, value: JSON.stringify(newData) };
        updateSetting({
          variables: params,
          optimisticResponse: {
            updateMasterSetting: {
              ...data,
              ...params,
            },
          },
        });
      }
    },
    [dataSource]
  );

  const columns = [
    {
      title: 'Banner',
      dataIndex: 'image',
      render: (value) => (
        <Image
          src={value}
          className={styles.imageWrapper}
          placeholder={<PictureOutlined style={{ fontSize: 50 }} />}
          height={80}
        />
      ),
    },
    {
      title: 'Link',
      dataIndex: 'url',
    },
    {
      title: 'Action',
      dataIndex: 'username',
      key: 'action',
      width: 100,
      render: (val, record, index) => (
        <>
          <EditOutlined style={{ fontSize: 20 }} onClick={() => handleEditBanner(record, index)} />
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => {
              handleDeleteBanner(record?.image);
            }}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <>
      <TableHeader
        renderToolbar={() => (
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              setModalVisible(true);
            }}
          >
            <PlusOutlined /> Add
          </Button>
        )}
      />
      <DndProvider backend={HTML5Backend}>
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="image"
          components={components}
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>

      {modalVisible && (
        <BannerModal data={selectedBanner} onSubmit={handleSubmit} onCancel={onCancel} visible={modalVisible} />
      )}
    </>
  );
}

export default Banner;
