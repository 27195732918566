import { Button, Popconfirm } from 'antd';
import React, { useState } from 'react';
import StandardTable from 'components/StandardTable';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { DeleteOutlined } from '@ant-design/icons';

import { formatPrice } from 'utils/helpers';
import { BOOK_COVER_RATIO } from 'utils/constant';
import ModalCreatePlaylist from './CreatePlaylist';
import ImportRss from './ImportRss';
import { ADMIN_DELETE_PLAYLIST } from '../schema';

const PlaylistTable = ({ data, pagination, onChange, hideRss, defaultCreateValue, isSeries }) => {
  const [isCreating, setIsCreating] = useState(false);
  const [deletePlaylist] = useMutation(ADMIN_DELETE_PLAYLIST);

  const handleDeletePlaylist = (playlistId) => {
    deletePlaylist({
      variables: {
        adminDeletePlaylistId: playlistId,
      },
      update(cache) {
        const normalizedId = cache.identify({ id: playlistId, __typename: 'Playlist' });
        if (normalizedId) {
          cache.evict({ id: normalizedId });
          cache.gc();
        }
      },
    });
  };

  const columns = [
    {
      title: '#',
      width: 80,
      render: (id, record, index) => (pagination?.current - 1) * 20 + index + 1,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
    },
    ...(isSeries
      ? [
          {
            title: 'Book cover',
            width: 120,
            render: (val) => (
              <img src={val?.image} style={{ aspectRatio: BOOK_COVER_RATIO }} height="auto" width={80} alt="" />
            ),
          },
        ]
      : []),
    {
      title: 'Name',
      render: (val) => <Link to={`/playlist/detail/${val.id}`}>{val.title}</Link>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: 150,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: 250,
      render: (val) => (
        <ul>
          {[...(val || [])].map((i) => (
            <li key={i?.id}>{i?.title}</li>
          ))}
        </ul>
      ),
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      width: 250,
    },
    {
      title: 'Price (VND)',
      width: 250,
      render: (record) => formatPrice(record?.price),
    },
    {
      title: 'Created at',
      dataIndex: 'created',
      width: 180,
      sorter: true,
      render: (record) => moment(record).format('HH:mm, DD/MM/YYYY'),
    },
    {
      title: '',
      width: 100,
      render: (record) => {
        return (
          <div>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDeletePlaylist(record?.id)}
              okText="Delete"
              cancelText="No"
            >
              <Button type="link" danger icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <StandardTable
        rowKey="id"
        renderToolbar={() => [
          ...(!hideRss ? [<ImportRss key="rss" />] : []),
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              setIsCreating(true);
            }}
          >
            Create
          </Button>,
        ]}
        dataSource={data}
        columns={columns}
        pagination={pagination}
        onChange={onChange}
      />

      <ModalCreatePlaylist
        defaultValue={defaultCreateValue}
        visible={isCreating}
        onDismiss={() => setIsCreating(false)}
      />
    </div>
  );
};

export default PlaylistTable;
