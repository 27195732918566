import React from 'react';
import AuthorizedTabs from 'components/Authorized/AuthorizedTabs';
import { Layout } from 'antd';
import { PERMISSION_LIST } from 'utils/constant';
import TabRoomInfo from './components/TabRoomInfo';
import TabRoomComment from './components/TabRoomComment';

const tabs = [
  { key: 'info', tab: 'Info', component: <TabRoomInfo /> },
  { key: 'comment', tab: 'Comment', component: <TabRoomComment />, authority: PERMISSION_LIST.MANAGE_COMMENT },
];
const EditRoom = () => {
  return (
    <Layout.Content>
      <AuthorizedTabs tabs={tabs} />
    </Layout.Content>
  );
};

export default EditRoom;
