import React from 'react';
import { Space } from 'antd';

import ColumnSetting from './ColumnSetting';
import styles from './styles.module.css';

const TableHeader = ({ headerTitle, renderToolbar, columns, columnsMap, setColumnsMap }) => {
  return (
    <div className={styles.toolbar}>
      <Space className={styles.toolbarLeft}>
        <div className={styles.toolbarTitle}>{headerTitle || ''}</div>
      </Space>
      <Space className={styles.toolbarRight}>
        {renderToolbar ? renderToolbar() : null}
        {Array.isArray(columns) && (
          <ColumnSetting columns={columns} columnsMap={columnsMap} setColumnsMap={setColumnsMap} />
        )}
      </Space>
    </div>
  );
};

export default TableHeader;
