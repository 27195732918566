import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'antd';
import { Formik, Form, FastField } from 'formik';
import * as Yup from 'yup';
import { NumberInput, InputFormik, TextFieldInput, SelectInput } from 'components/Input';
import { POINT_HISTORY_TYPE } from 'utils/constant';

const GeneralSchema = Yup.object().shape({
  account: Yup.string(),
  description: Yup.string().required(),
  amount: Yup.number().required(),
  type: Yup.string().required(),
});

const AddPointModal = ({ visible, headerTitle, user, isLoading, onCancel = () => {}, onSubmit = () => {} }) => {
  return (
    <Modal
      title={headerTitle}
      visible={visible}
      maskStyle={{ background: 'rgba(37, 39, 51, 0.2)' }}
      footer={null}
      closable={false}
      width="388px"
      onCancel={onCancel}
    >
      <Formik
        initialValues={{
          user: user?.username,
          description: '',
          amount: '',
          type: '',
        }}
        validationSchema={GeneralSchema}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        {({ values }) => (
          <Form>
            <FastField disabled type="text" title="Target" name="user" component={InputFormik} />
            <FastField
              max={10000}
              type="text"
              name="amount"
              title="Point"
              value={values?.amount}
              placeholder="Input Point"
              component={NumberInput}
            />
            <FastField
              name="type"
              component={SelectInput}
              options={Object.keys(POINT_HISTORY_TYPE).map((t) => ({ label: POINT_HISTORY_TYPE[t], value: t }))}
              title="Type"
            />
            <FastField
              name="description"
              component={TextFieldInput}
              title="Descripiton"
              type="text"
              placeholder="Input description"
            />
            <div className="flex items-end justify-end gap-6">
              <Button onClick={onCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

AddPointModal.propTypes = {
  visible: PropTypes.bool,
  headerTitle: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
};

export default AddPointModal;
