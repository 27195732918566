/* eslint-disable no-case-declarations */
import React, { useEffect, useMemo } from 'react';
import { Layout, Card, Input, Button, message, Checkbox } from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';
import CommentTable from './CommentTable';

const { Search } = Input;

export const ALL_COMMENT = gql`
  query AdminGetComments($page: Int, $pageSize: Int, $filter: JSON, $sort: JSON) {
    adminGetComments(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      currentPage
      pageSize
      results {
        id
        roomId
        waveId
        content
        created
        creator {
          id
          username
        }
      }
      total
    }
  }
`;

export const DELETE_RECORDING_COMMENT = gql`
  mutation AdminDeleteRecordingComment($commentId: Int!) {
    adminDeleteRecordingComment(commentId: $commentId) {
      id
    }
  }
`;

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    const value = filters?.[filter];
    if (!value) return;
    switch (filter) {
      case 'search':
        newFilter.push({
          operator: OPERATOR.or,
          value: [
            { field: 'content', operator: OPERATOR.contains, value },
            ...(!Number.isNaN(+value) ? [{ field: 'id', operator: OPERATOR.eq, value: Number(value) }] : []),
            ...(!Number.isNaN(+value) ? [{ field: 'roomId', operator: OPERATOR.eq, value: Number(value) }] : []),
            ...(!Number.isNaN(+value) ? [{ field: 'waveId', operator: OPERATOR.eq, value: Number(value) }] : []),
          ],
        });
        break;
      case 'type':
        if (value === 'wave') newFilter.push({ field: 'waveId', operator: OPERATOR.null });
        else newFilter.push({ field: 'roomId', operator: OPERATOR.null });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const Comments = ({ location }) => {
  const filter = useFilter(['page', 'search', 'type']);
  const { pageSize = 20, page = 0, search, type: typeComment } = useMemo(() => filter.querySearch || {}, [
    filter.querySearch,
  ]);
  const { data, error, loading, refetch } = useQuery(ALL_COMMENT, {
    variables: {
      page: Number(page),
      pageSize: Number(pageSize),
      sort: [{ field: 'created', value: 'desc' }],
      filter: generateFilter(filter.querySearch),
    },
  });
  const [adminDeleteRecordingComment] = useMutation(DELETE_RECORDING_COMMENT, {
    onCompleted: () => message.success('Delete success!'),
    onError: (err) => message.error(err.message),
    refetchQueries: [ALL_COMMENT],
  });

  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    filter.onSearch({ page: current, ...filters });
  };

  const handleDeleteReport = (id) => {
    adminDeleteRecordingComment({ variables: { commentId: id } });
  };

  const onEnterSearch = (event) => {
    filter.onSearch({ search: event.target.value, page: 0 });
  };
  const onSearch = (event) => {
    filter.onSearch({ search: event, page: 0 });
  };

  const onChangeTypeComment = (values) => {
    filter.onSearch({ type: values, page: 0 });
  };

  return (
    <Layout.Content>
      <Card>
        <Search
          placeholder="User ID"
          defaultValue={search}
          style={{ width: 400 }}
          onPressEnter={onEnterSearch}
          onSearch={onSearch}
        />
        <Button>Total: {data?.adminGetComments?.total}</Button>
        <div className="p-1 border rounded-sm w-fit inline-block ml-0.5 px-2 pl-3">
          <Checkbox.Group
            options={['recording', 'wave']}
            className="capitalize"
            value={typeComment}
            onChange={onChangeTypeComment}
          />
        </div>
        <CommentTable
          data={data?.adminGetComments?.results}
          pagination={{
            reset: true,
            defaultPageSize: pageSize,
            total: data?.adminGetComments?.total || 0,
          }}
          onChange={handleTableChange}
          onDelete={handleDeleteReport}
        />
      </Card>
    </Layout.Content>
  );
};

export default Comments;
