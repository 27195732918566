import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Form, Spin, Row, Col, Input, Select, Space, Button } from 'antd';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';

const FORM_FIELD = {
  EMAIL: 'email',
  PASSWORD: 'password',
  USER_NAME: 'username',
  ROLE: 'roleId',
};

const GET_ROLE_SELECT = gql`
  query AdminGetRoles($filter: JSON) {
    adminGetRoles(filter: $filter) {
      id
      code
      name
    }
  }
`;

const AdminAddEditForm = ({ doAddEdit, adminData, type, loading = false, close }) => {
  const { data } = useQuery(GET_ROLE_SELECT);

  const handleSubmit = (values) => {
    doAddEdit(pickBy(values, identity));
  };

  return (
    <Spin spinning={loading}>
      <Form
        layout="vertical"
        initialValues={isEmpty(adminData) ? {} : adminData}
        autoComplete="false"
        onFinish={handleSubmit}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item rules={[{ required: true }]} name={FORM_FIELD.USER_NAME} label="Username">
              <Input type="text" />
            </Form.Item>
            <Form.Item rules={[{ type: 'email', required: true }]} name={FORM_FIELD.EMAIL} label="Email">
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item rules={[{ required: true }]} name={FORM_FIELD.ROLE} label="Role">
              <Select placeholder="Select role">
                {data?.adminGetRoles?.map((opt) => (
                  <Select.Option value={opt.id} key={opt.id}>
                    {opt.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item rules={[{ required: type === 'add' }]} name={FORM_FIELD.PASSWORD} label="Password">
              <Input type="password" />
            </Form.Item>
          </Col>
        </Row>
        <Space size="middle" className="mb-4 flex justify-end">
          <Button ghost type="primary" htmlType="button" onClick={close}>
            Cancel
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form>
    </Spin>
  );
};

AdminAddEditForm.propTypes = {
  adminData: PropTypes.objectOf(PropTypes.any),
  doAddEdit: PropTypes.func,
  loading: PropTypes.bool,
  close: PropTypes.func,
  type: PropTypes.string,
};

export default AdminAddEditForm;
