import { useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import { DateInput, InputFormik, NumberInput, SelectInput, TextFieldInput } from 'components/Input';
import PlaylistCategorySelect from 'components/PlaylistCategorySelect';
import TopicSelect from 'components/TopicSelect';
import UploadImageInput from 'components/UploadImageInput';
import UserSelect from 'components/UserSelect';
import { FastField, Field, Formik } from 'formik';
import {
  BOOK_COVER_RATIO,
  PLAYLIST_STATUS,
  PLAYLIST_TYPE,
  PURCHASE_TYPE,
  SERIES_PRODUCT_ID_OPTIONS,
  THUMBNAIL_SHARING_RATIO,
} from 'utils/constant';
import AuthorSelect from 'components/AuthorSelect';
import { playlistValidationSchema } from 'common/validationSchema';
import { capitalize } from 'lodash';
import { telegramApi } from 'utils/helpers/telegram';
import { ADMIN_GET_PLAYLIST, CREATE_PLAYLIST } from '../schema';

const FORM = {
  title: 'title',
  slug: 'slug',
  image: 'image',
  status: 'status',
  description: 'description',
  type: 'type',
  category: 'categoryId',
  userId: 'userId',
  topicId: 'topicId',
  authorId: 'authorId',
  thumbnail: 'thumbnail',
  purchasedType: 'purchasedType',
};

const ModalCreatePlaylist = ({ visible, onDismiss, defaultValue }) => {
  const [createPlaylist, { loading: creating }] = useMutation(CREATE_PLAYLIST, {
    refetchQueries: [ADMIN_GET_PLAYLIST],
    onCompleted: (response) => {
      message.success('Created!');
      onDismiss();
      const { id, title, status } = response?.adminCreatePlaylist || {};
      if (status === PLAYLIST_STATUS.PUBLISHED) {
        telegramApi.sendMessage(
          // eslint-disable-next-line max-len
          `📗|<b><i>${title}</i></b>| has just been published\nPreview: https://app.getonmic.com/playlist/${id}`,
          'html'
        );
      }
    },
    onError: (error) => {
      message.error(error?.message);
    },
  });

  const onSubmit = (values) => {
    const { price, iosProductId, purchasedType, androidProductId, authorId, ...rest } = values;
    const input = rest;
    if (!values?.[FORM.userId]) delete input[FORM.userId];
    if (values.type === PLAYLIST_TYPE.AUDIOBOOK) {
      input.price = price;
      input.iosProductId = iosProductId;
      input.androidProductId = androidProductId;
      input.authorId = authorId;
      input.purchasedType = purchasedType;
    }
    createPlaylist({ variables: { input } });
  };

  const initialValues = {
    [FORM.title]: '',
    [FORM.slug]: '',
    [FORM.image]: null,
    [FORM.description]: '',
    [FORM.type]: PLAYLIST_TYPE.TOPIC,
    [FORM.category]: [],
    [FORM.userId]: null,
    [FORM.status]: PLAYLIST_STATUS.DRAFT,
    price: 0,
    iosProductId: '',
    androidProductId: '',
    thumbnail: null,
    [FORM.purchasedType]: PURCHASE_TYPE.free,
    ...defaultValue,
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={playlistValidationSchema}>
      {({ values, setFieldValue, submitForm, errors }) => {
        return (
          <Modal
            title="Create Playlist"
            visible={visible}
            destroyOnClose
            width={850}
            onCancel={onDismiss}
            centered
            onOk={submitForm}
            okButtonProps={{ loading: creating, disabled: creating }}
            okText="Create"
          >
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Field
                  className="flex flex-col"
                  title="Image"
                  height={200}
                  width={200}
                  name={FORM.image}
                  aspect={values?.type === PLAYLIST_TYPE.AUDIOBOOK ? BOOK_COVER_RATIO : 1}
                  useCrop
                  required
                  component={UploadImageInput}
                />

                <FastField
                  useCrop
                  title="Thumbnail sharing (w:1200 x h:630)"
                  aspect={THUMBNAIL_SHARING_RATIO}
                  name={FORM.thumbnail}
                  component={UploadImageInput}
                  width={270}
                  height="auto"
                />
              </div>
              <div>
                <FastField title="Title" required name={FORM.title} component={InputFormik} />
                <FastField title="Slug" required name={FORM.slug} component={InputFormik} />
                <FastField title="Description" rows={3} name={FORM.description} component={TextFieldInput} required />
              </div>

              <TopicSelect
                value={values?.[FORM.topicId]}
                handleChange={(value) => setFieldValue(FORM.topicId, value)}
              />

              <FastField
                title="Type"
                defaultValue={PLAYLIST_TYPE.TOPIC}
                name={FORM.type}
                options={Object.values(PLAYLIST_TYPE).map((v) => ({ label: v, value: v }))}
                component={SelectInput}
              />

              <FastField
                title="Status"
                name={FORM.status}
                component={SelectInput}
                options={Object.values(PLAYLIST_STATUS).map((i) => ({ label: capitalize(i), value: i }))}
              />

              <PlaylistCategorySelect
                error={errors[FORM.category]}
                value={values?.[FORM.category]}
                handleChange={(v) => setFieldValue(FORM.category, v)}
                paramsSuggest={{ title: values?.title, description: values?.description }}
                onSuggest={(categories) => {
                  const arrayId = categories.map((i) => i.id);
                  if (arrayId.length) setFieldValue(FORM.category, arrayId);
                }}
              />

              <UserSelect
                isInvalid={errors[FORM.userId]}
                handleChange={(value) => setFieldValue(FORM.userId, value)}
                title="Creator"
              />

              {values?.type === PLAYLIST_TYPE.AUDIOBOOK && (
                <div>
                  <div className="grid grid-cols-3 gap-2">
                    <FastField
                      title="IOS (coin)"
                      name="iosProductId"
                      component={SelectInput}
                      options={SERIES_PRODUCT_ID_OPTIONS}
                    />
                    <FastField
                      title="Android (coin)"
                      name="androidProductId"
                      component={SelectInput}
                      options={SERIES_PRODUCT_ID_OPTIONS}
                    />
                    <FastField title="Price (VND)" className="!w-full" name="price" component={NumberInput} />
                  </div>
                  <AuthorSelect
                    isInvalid={errors[FORM.authorId]}
                    required
                    value={values[FORM.authorId]}
                    handleChange={(value) => setFieldValue(FORM.authorId, value)}
                    title="Author"
                    description="Author of audiobook"
                  />

                  <FastField
                    title="Purchased type"
                    name={FORM.purchasedType}
                    options={Object.values(PURCHASE_TYPE).map((v) => ({ label: v, value: v }))}
                    component={SelectInput}
                  />
                </div>
              )}

              <FastField title="Publish Date" name="pubDate" showTime component={DateInput} allowEmpty />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ModalCreatePlaylist;
