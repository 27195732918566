import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Col, Form, Image, message, Modal, Row, Tag } from 'antd';
import { memo, useRef, useState } from 'react';
import StandardTable from 'components/StandardTable';
import { FastField, Formik } from 'formik';
import PictureOutlined from '@ant-design/icons/lib/icons/PictureOutlined';
import { InputFormik, TextFieldInput } from 'components/Input';
import UploadImage from 'components/UploadImage';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';
import { CSVLink } from 'react-csv';
import { getOperationName } from '@apollo/client/utilities';
import { OPERATOR, PERMISSION_LIST } from 'utils/constant';
import { gameValidationSchema } from 'common/validationSchema';
import { importQuestion } from 'utils/request';
import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';
import { GET_LIST_GAME_ROOM, GET_LIST_QUESTION, UPDATE_GAME } from './schema';

const DEFAULT_PAGE_SIZE = 20;

const headers = [{ label: 'content', key: 'content' }];

const dataMapperCsv = ({ gameId, data }) => data.map((item) => ({ gameId, content: item?.content }));

const GameDetailModal = ({ visible, onCancel, gameData }) => {
  const { id, description, image, name, backgroundImage } = gameData || {};
  const auth = useRecoilValue(authState);
  const [page, setPage] = useState(1);
  const csvRef = useRef();
  const canUpload = auth?.permissions?.find?.((i) => i.code === PERMISSION_LIST.MANAGE_GAME_QUESTION)?.allow;

  const { data, loading, refetch } = useQuery(GET_LIST_QUESTION, {
    variables: {
      page: page - 1,
      pageSize: DEFAULT_PAGE_SIZE,
      filter: [{ field: 'gameId', operator: OPERATOR.eq, value: id }],
    },
  });

  const [getAllQuestion, { data: dataDowload, loading: downloading }] = useLazyQuery(GET_LIST_QUESTION, {
    variables: {
      pageSize: 100000000,
      filter: [{ field: 'gameId', operator: OPERATOR.eq, value: id }],
    },
    fetchPolicy: 'network-only',
    onCompleted: () => csvRef.current.link.click(),
  });

  const oprationData = data?.[getOperationName(GET_LIST_QUESTION)];

  const [update] = useMutation(UPDATE_GAME);

  const columns = [
    {
      title: 'No',
      sorter: true,
      render: (value, item, index) => (page - 1) * DEFAULT_PAGE_SIZE + index + 1,
    },
    {
      title: 'Game type',
      dataIndex: 'gameType',
      sorter: true,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
    },
    {
      title: 'Content',
      key: 'content',
      dataIndex: 'content',
      sorter: true,
    },
  ];

  const initialValues = {
    image,
    name,
    description,
    backgroundImage,
  };

  const handleUploadCSV = async (event) => {
    const csvInput = event.target.files?.[0];
    const frmData = new FormData();
    frmData.append('upload', csvInput);
    frmData.append('delimiter', ',');
    message.loading({ content: 'Uploading', key: 'upload' });
    try {
      await importQuestion(frmData, id);
      message.success({ content: 'Upload completed!', key: 'upload' });
      refetch();
      setPage(1);
    } catch (error) {
      message.error({ content: error, key: 'upload' });
    }
  };

  const onSubmit = async (values) => {
    await update({
      variables: {
        gameId: id,
        input: values,
      },
      refetchQueries: [GET_LIST_GAME_ROOM],
      onError: () => message.error('Update error!'),
      onCompleted: () => {},
    });
    onCancel();
    message.success('Update success!');
  };

  const onDowloadCsv = async () => getAllQuestion();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={gameValidationSchema}>
      {({ values, setFieldValue, submitForm, isSubmitting }) => {
        const handleAdd = async (keyName, url) => {
          if (url) {
            setFieldValue(keyName, url);
          }
        };

        return (
          <Modal
            bodyStyle={{ minHeight: 350 }}
            title={
              <div>
                <Tag color="blue">Game id: {id}</Tag> {name}
              </div>
            }
            visible={visible}
            destroyOnClose
            centered
            loading={loading}
            maskStyle={{ background: 'rgba(37, 39, 51, 0.2)' }}
            okText="Update"
            onOk={submitForm}
            okButtonProps={{ loading: isSubmitting, disabled: isSubmitting }}
            width="80%"
            onCancel={onCancel}
          >
            <Form>
              <Row gutter={20}>
                <Col span={12}>
                  <FastField name="name" component={InputFormik} title="Game name" type="text" />
                  <FastField name="description" component={TextFieldInput} title="Description" type="text" />
                </Col>
                <Col span={12} className="text-center my-auto">
                  <div className="mb-2">
                    <p className="font-bold">Thumbnail</p>
                    <UploadImage onFinish={(url) => handleAdd('image', url)}>
                      <Image
                        src={values?.image || null}
                        placeholder={<PictureOutlined style={{ fontSize: '120px' }} />}
                        preview={false}
                        height={150}
                      />
                    </UploadImage>
                  </div>
                  <div className="border-t pt-2">
                    <p className="font-bold">Background</p>
                    <UploadImage onFinish={(url) => handleAdd('backgroundImage', url)}>
                      <Image
                        src={values?.backgroundImage || null}
                        placeholder={<PictureOutlined style={{ fontSize: '120px' }} />}
                        preview={false}
                        height={150}
                      />
                    </UploadImage>
                  </div>
                </Col>
              </Row>
              <div className="max-h-[60vh] overflow-auto">
                <StandardTable
                  renderToolbar={() => (
                    <Button
                      disabled={!canUpload}
                      title={
                        !canUpload ? `No permission ${PERMISSION_LIST.MANAGE_GAME_QUESTION}` : 'Upload game question'
                      }
                      className="overflow-hidden relative"
                      style={{ borderRadius: 6 }}
                      type="primary"
                    >
                      Upload question
                      <input
                        type="file"
                        id="csvFileInput"
                        accept=".csv"
                        className="absolute top-0 left-0 w-full h-full opacity-0"
                        onChange={handleUploadCSV}
                      />
                    </Button>
                  )}
                  pagination={{
                    pageSize: DEFAULT_PAGE_SIZE,
                    total: oprationData?.total || 0,
                    showSizeChanger: false,
                    current: page,
                    onChange(current) {
                      setPage(current);
                    },
                  }}
                  rowKey="id"
                  dataSource={oprationData?.results || []}
                  columns={columns}
                  loading={loading}
                />
              </div>
              {oprationData?.results?.length > 0 && (
                <>
                  <CSVLink
                    ref={csvRef}
                    headers={headers}
                    data={dataMapperCsv({ data: dataDowload?.adminGetGameQuestionList?.results || [], gameId: id })}
                    filename="List_Game_Question.csv"
                  />

                  <Button
                    loading={downloading}
                    disabled={downloading}
                    onClick={onDowloadCsv}
                    className="ml-2 float-right mt-[35px]"
                    icon={<DownloadOutlined />}
                  >
                    Dowload
                  </Button>
                </>
              )}
            </Form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default memo(GameDetailModal);
