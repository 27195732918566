import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Spin, Form, Input, Space, Button, Switch } from 'antd';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import noop from 'lodash/noop';
import snakeCase from 'lodash/snakeCase';

import { ALL_PERMISSIONS } from 'containers/AdminManagement/schema';

const FORM_FIELD = {
  NAME: 'name',
  PERMISSONS: 'permissions',
};

const RoleAddEditForm = ({ doAddEdit, loading = false, roleData = null, close = noop }) => {
  const [form] = Form.useForm();
  const { data: permissionData, loading: permissionLoading } = useQuery(ALL_PERMISSIONS, {
    onCompleted: () => form.resetFields(),
  });

  console.log('permissions :', permissionData);

  const permissionList = permissionData && permissionData?.adminGetPermissions;

  const handleSubmit = (values) => {
    doAddEdit({
      name: values.name,
      code: snakeCase(values.name),
      permissions: values.permissions,
    });
  };

  return (
    <Spin spinning={permissionLoading || loading}>
      <Form
        initialValues={
          isEmpty(roleData)
            ? {
                [FORM_FIELD.NAME]: '',
                [FORM_FIELD.PERMISSONS]:
                  map(permissionList, (p) => ({
                    permissionId: p.id,
                    allow: true,
                  })) || [],
              }
            : {
                [FORM_FIELD.NAME]: roleData.name,
                [FORM_FIELD.PERMISSONS]:
                  map(permissionList, (p) => ({
                    permissionId: p.id,
                    allow: roleData?.permissions?.find((item) => item.id === p.id)?.allow || false,
                  })) || [],
              }
        }
        form={form}
        onFinish={handleSubmit}
        autoComplete="off"
        layout="horizontal"
      >
        <Form.Item rules={[{ required: true }]} name={FORM_FIELD.NAME} labelCol={{ span: 24 }} label="Name">
          <Input placeholder="Enter role name" type="text" />
        </Form.Item>
        <Form.List name={FORM_FIELD.PERMISSONS}>
          {(fields) =>
            fields.map(({ key, name, ...restField }) => (
              <Form.Item
                valuePropName="checked"
                {...restField}
                key={key}
                name={[name, 'allow']}
                labelAlign="left"
                labelCol={{ span: 20 }}
                label={permissionData?.adminGetPermissions?.[name]?.name}
              >
                <Switch />
              </Form.Item>
            ))
          }
        </Form.List>

        <Space size="middle" className="mb-4 flex justify-end">
          <Button ghost type="primary" htmlType="button" onClick={close}>
            Cancel
          </Button>
          <Button loading={loading} type="primary" htmlType="submit">
            Submit
          </Button>
        </Space>
      </Form>
    </Spin>
  );
};

RoleAddEditForm.propTypes = {
  doAddEdit: PropTypes.func,
  loading: PropTypes.bool,
  roleData: PropTypes.objectOf(PropTypes.any),
  close: PropTypes.func,
};
export default RoleAddEditForm;
