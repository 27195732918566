import React, { useState } from 'react';
import { Select, Spin } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { OPERATOR } from 'utils/constant';
import { useDebounce } from 'use-debounce';
import isNaN from 'lodash/isNaN';

const ALL_ROOMS = gql`
  query adminGetRoomlist($filter: JSON, $sort: JSON, $pageSize: Int, $page: Int) {
    adminGetRoomList(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      total
      results {
        id
        title
        description
        start
        end
        created
        status
        type
        image
        totalAudience
        allowRecording
        recordingUrl
        creator {
          id
          username
        }
        speakers {
          id
          userId
          role
          user {
            id
            username
          }
        }
      }
    }
  }
`;

const { Option } = Select;

const RecordingSelect = ({ handleChange, renderList, title, disabled }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue] = useDebounce(searchTerm, 500, { leading: false, trailing: true });

  const { data, loading } = useQuery(ALL_ROOMS, {
    variables: {
      page: 0,
      pageSize: 20,
      filter: [
        ...(searchValue
          ? [
              {
                operator: 'or',
                value: [
                  { field: 'title', operator: OPERATOR.contains, value: searchValue },
                  ...(!isNaN(+searchValue) ? [{ field: 'id', operator: OPERATOR.eq, value: searchValue }] : []),
                ],
              },
            ]
          : []),
        { field: 'allowRecording', operator: OPERATOR.eq, value: true },
      ],
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const result = data?.adminGetRoomList?.results;

  const renderUser = () => {
    if (typeof renderList === 'function') {
      return renderList();
    }
    if (!loading && result) {
      return result?.map((element) => (
        <Option key={element.id} value={element.id}>
          <b>{element.title}</b>
        </Option>
      ));
    }
    return null;
  };

  return (
    <div className="flex flex-col">
      {!!title && <span className="text-gray mb-2 font-bold h-5 text-18">{title}</span>}
      <Select
        onChange={(v, o) => handleChange(v, o, result)}
        showSearch
        onClick={(e) => e.preventDefault()}
        style={{ width: '100%' }}
        filterOption={false}
        placeholder="Select recording"
        onSearch={setSearchTerm}
        notFoundContent={loading ? <Spin size="small" /> : null}
        disabled={disabled}
      >
        {renderUser()}
      </Select>
    </div>
  );
};

export default RecordingSelect;
