import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { useFlag } from 'utils/hooks';
import RoleFormWrapper from './RoleFormWrapper';

const RoleModal = ({ refetch, roleData, children, type = 'add' }) => {
  const [openModal, doOpenModal, doCloseModal] = useFlag(false);

  return (
    <>
      {children(doOpenModal)}
      {openModal && (
        <Modal
          footer={null}
          title={type === 'add' ? 'Add Role' : 'Edit Role'}
          width={464}
          visible={openModal}
          onCancel={doCloseModal}
        >
          <RoleFormWrapper refetch={refetch} roleData={roleData} type={type} close={doCloseModal} />
        </Modal>
      )}
    </>
  );
};

RoleModal.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  roleData: PropTypes.objectOf(PropTypes.any),
};
export default RoleModal;
