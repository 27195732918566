import { FRAGMENT_COUPON } from './fragment';

const { gql } = require('@apollo/client');

export const GET_LIST_COUPON = gql`
  query AdminGetListCoupon($pageSize: Int, $page: Int, $sort: JSON, $filter: JSON) {
    adminGetListCoupon(pageSize: $pageSize, page: $page, sort: $sort, filter: $filter) {
      total
      currentPage
      pageSize
      results {
        ...Coupon
      }
    }
  }
  ${FRAGMENT_COUPON}
`;

export const CREATE_COUPON = gql`
  mutation AdminCreateCoupon($input: CouponInput) {
    adminCreateCoupon(input: $input) {
      ...Coupon
    }
  }
  ${FRAGMENT_COUPON}
`;

export const UPDATE_COUPON = gql`
  mutation AdminUpdateCoupon($adminUpdateCouponId: Int!, $input: CouponUpdateInput!) {
    adminUpdateCoupon(id: $adminUpdateCouponId, input: $input) {
      ...Coupon
    }
  }
  ${FRAGMENT_COUPON}
`;
