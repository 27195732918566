import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Tag, Popconfirm } from 'antd';
import React from 'react';
import StandardTable from 'components/StandardTable';
import { Link } from 'react-router-dom';

const DetailReport = ({ data }) => {
  if (data?.userId)
    return (
      <div>
        <Tag color="blue">User</Tag> <Link to={`/user/info/${data?.user?.id}`}>{data?.user?.username}</Link>
      </div>
    );
  if (data?.waveId)
    return (
      <div className="min-w-0 break-words line-clamp-1">
        <Tag color="magenta">Wave</Tag> <Link to={`/wave?description=${data?.waveId}`}>{data?.wave?.description}</Link>
      </div>
    );
  if (data?.roomId)
    return (
      <div className="min-w-0 break-words line-clamp-1">
        <Tag color="volcano">Room</Tag> <Link to={`/room/edit/${data?.roomId}`}>{data?.room?.title}</Link>
      </div>
    );
  if (data?.reviewId)
    return (
      <div className="min-w-0 break-words line-clamp-1">
        <Tag color="lime">Review</Tag>{' '}
        <Link to={`/playlist/detail/${data?.review?.playlistId}?page=1&search=${data?.reviewId}&tab=review`}>
          {data?.review?.content}
        </Link>
      </div>
    );
  return null;
};

const ReportTable = ({ data, pagination, onChange, onDelete, loading }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator',
      render: (val, record) => <Link to={`/user/info/${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Detail',
      key: 'reason',
      width: 450,
      render: (val, record) => {
        return <DetailReport data={val} />;
      },
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      sorter: true,
      render: (val) => <span>{moment(val).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'room',
      key: 'action',
      render: (val, record) => (
        <>
          <Popconfirm
            title="Are you sure to delete this report?"
            onConfirm={() => onDelete(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <StandardTable
        loading={loading}
        rowKey="id"
        dataSource={data}
        columns={columns}
        allowSelectRow
        pagination={pagination}
        onChange={onChange}
      />
    </div>
  );
};

export default ReportTable;
