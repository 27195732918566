import React, { useCallback, useMemo, useState } from 'react';
import { Button, Checkbox, Image, Layout, message, Popconfirm, Select, Spin, Table } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { LexoRank } from 'lexorank';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';
import { CREATE_GIFT, DELETE_GIFT, GET_LIST_GIFT, UNDELETE_GIFT, UPDATE_GIFT } from '../../schema';
import styles from '../styles.module.css';
import GiftModal from './GiftModal';
import DraggableBodyRow from './DraggableBodyRow';

const statusValue = {
  active: false,
  deleted: true,
};

const Gift = () => {
  const { querySearch, onSearch } = useFilter(['status']);
  const _filter = useMemo(() => {
    const filter = [];
    if ('status' in querySearch) {
      filter.push({ field: 'isDeleted', operator: OPERATOR.eq, value: statusValue[querySearch?.status] });
    }
    return filter;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySearch?.status]);

  const { data, loading } = useQuery(GET_LIST_GIFT, {
    variables: {
      sort: [{ field: 'order', value: 'asc' }],
      pageSize: 100,
      filter: _filter,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const [selectedGift, setSelectedGift] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [createGift] = useMutation(CREATE_GIFT, { refetchQueries: [GET_LIST_GIFT] });
  const [deleteGift] = useMutation(DELETE_GIFT, { refetchQueries: [GET_LIST_GIFT] });
  const [undeleteGift] = useMutation(UNDELETE_GIFT, { refetchQueries: [GET_LIST_GIFT] });
  const [updateGift] = useMutation(UPDATE_GIFT, { refetchQueries: [GET_LIST_GIFT] });

  const giftList = data?.adminGetListGift?.results || [];
  const handleDeleteGift = (id, isDeleted) => {
    if (isDeleted) undeleteGift({ variables: { undeleteGiftId: id } });
    else deleteGift({ variables: { deleteGiftId: id } });
  };

  const handleCreateGift = async (input) => {
    const lastItem = giftList[giftList.length - 1];
    const lastOrder = (lastItem.order ? LexoRank.parse(lastItem.order) : LexoRank.max()).genNext().toString();
    await createGift({
      variables: { input: { ...input, order: lastOrder } },
      onCompleted: () => message.success('Created gift!'),
      onError: () => message.error('Created fail!'),
    });
    return setIsVisible(false);
  };

  const handleUpdateGift = async (input) => {
    const { id, point, image, animationUrl, order } = input || {};
    await updateGift({
      variables: { updateGiftId: id, input: { point, image, animationUrl, order } },
      onError: () => message.error('Update fail!'),
      onCompleted: () => message.success('Update success!'),
    });
    setSelectedGift(null);
    return setIsVisible(false);
  };

  const openModalUpdateGift = (record) => {
    setSelectedGift(record);
    setIsVisible(true);
  };

  const handleCloseModal = () => {
    if (selectedGift) setSelectedGift(null);
    setIsVisible(false);
  };

  const columns = [
    {
      title: 'Index',
      key: 'index',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Order',
      dataIndex: 'order',
    },
    {
      title: 'Image',
      dataIndex: 'image',
      render: (value) => <Image src={value} className={styles.imageWrapper} height={80} width={80} />,
    },
    {
      title: 'Point',
      dataIndex: 'point',
    },
    {
      title: 'Status',
      dataIndex: 'isDeleted',
      render: (val) => (
        <div className={`font-semibold ${val ? 'text-red-400' : 'text-green-400'}`}>{val ? 'Deleted' : 'Active'}</div>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'username',
      key: 'action',
      render: (val, record, index) => (
        <div className="flex items-center gap-3">
          <EditOutlined title="Edit" style={{ fontSize: 20 }} onClick={() => openModalUpdateGift(record)} />

          <Popconfirm
            title={record?.isDeleted ? 'Are you sure to active this gift?' : 'Are you sure to delete this gift?'}
            onConfirm={() => handleDeleteGift(record?.id, record.isDeleted)}
            okText="Yes"
            cancelText="No"
          >
            {record?.isDeleted ? (
              <EyeOutlined title="Active" className="text-lg" />
            ) : (
              <DeleteOutlined title="Delete" className="text-lg" style={{ color: 'red' }} />
            )}
          </Popconfirm>
        </div>
      ),
    },
  ];

  const genNewRank = (oldIndex, newIndex) => {
    if (newIndex === 0) {
      const firstOrder = giftList[0].order || LexoRank.min();
      return LexoRank.parse(firstOrder).genPrev();
    }
    if (newIndex === giftList.length - 1) {
      const lastOrder = giftList[giftList.length - 1].order || LexoRank.max();
      return LexoRank.parse(lastOrder).genNext();
    }
    const hoverRank = giftList[newIndex].order ? LexoRank.parse(giftList[newIndex].order) : LexoRank.middle();
    const isMoveNext = newIndex > oldIndex;
    let secondRank = null;
    const secondOrder = giftList[newIndex + (!isMoveNext ? -1 : 1)].order;
    if (secondOrder) secondRank = LexoRank.parse(secondOrder);
    else {
      secondRank = isMoveNext ? hoverRank.genNext() : hoverRank.genPrev();
    }
    return hoverRank.between(secondRank);
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex !== hoverIndex) {
        const newRank = genNewRank(dragIndex, hoverIndex).toString();
        handleUpdateGift({ id: giftList[dragIndex].id, order: newRank });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [giftList]
  );

  if (loading && !data) return <Spin />;

  return (
    <Layout.Content className="flex flex-col">
      <div className="text-right mb-4 flex justify-end gap-4 items-center">
        <Select
          onChange={(val) => onSearch({ status: val })}
          className="w-28 text-left"
          options={[
            { label: 'All', value: '' },
            { label: 'Active', value: 'active' },
            { label: 'Deleted', value: 'deleted' },
          ]}
          defaultValue={querySearch?.status || ''}
        />
        <Button type="primary" onClick={() => setIsVisible(true)}>
          + Create
        </Button>
      </div>
      <DndProvider backend={HTML5Backend}>
        <Table
          pagination={false}
          dataSource={giftList}
          columns={columns}
          components={{ body: { row: DraggableBodyRow } }}
          rowKey="image"
          onRow={(record, index) => ({
            index,
            moveRow,
          })}
        />
      </DndProvider>
      {isVisible && (
        <GiftModal
          visible={isVisible}
          onCancel={handleCloseModal}
          onUpdate={handleUpdateGift}
          onCreate={handleCreateGift}
          data={selectedGift}
        />
      )}
    </Layout.Content>
  );
};

export default Gift;
