import { Input, message, Popconfirm } from 'antd';
import StandardTable from 'components/StandardTable';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { ALL_COMMENT, DELETE_RECORDING_COMMENT } from 'containers/Comments';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    const value = filters?.[filter];
    if (!value) return;
    switch (filter) {
      case 'search':
        newFilter.push({
          operator: OPERATOR.or,
          value: [
            { field: 'content', operator: OPERATOR.contains, value },
            ...(!Number.isNaN(+value) ? [{ field: 'id', operator: OPERATOR.eq, value: Number(value) }] : []),
          ],
        });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const TabRoomComment = () => {
  const filter = useFilter(['page', 'search', 'tab']);
  const { id: roomId } = useParams() || {};
  const { page = 0, search } = filter.querySearch || {};
  const { data, error, loading, refetch } = useQuery(ALL_COMMENT, {
    skip: !roomId || Number.isNaN(+roomId),
    variables: {
      page: Number(page),
      sort: [{ field: 'created', value: 'desc' }],
      filter: [
        { field: 'roomId', operator: OPERATOR.eq, value: Number(roomId) },
        ...generateFilter(filter.querySearch),
      ],
    },
  });
  const [adminDeleteRecordingComment] = useMutation(DELETE_RECORDING_COMMENT, {
    onCompleted: () => message.success('Delete success!'),
    onError: (err) => message.error(err.message),
    refetchQueries: [ALL_COMMENT],
  });

  const handleDeleteComment = (id) => {
    adminDeleteRecordingComment({ variables: { commentId: id } });
  };
  const handleTableChange = (pagination, filters, sorter) => {
    const { current } = pagination;
    filter.onSearch({ page: current, ...filters });
  };
  const onEnterSearch = (event) => {
    filter.onSearch({ search: event.target.value, page: 0 });
  };
  const onSearch = (event) => {
    filter.onSearch({ search: event, page: 0 });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Time',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (val) => <span>{moment(val).format('DD/MM/YYYY, HH:mm')}</span>,
    },
    {
      title: 'Username',
      dataIndex: 'creator',
      key: 'creator',
      render: (val, record) => <Link to={`/report?page=1&creatorId=${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Action',
      dataIndex: 'room',
      key: 'action',
      render: (val, record) => (
        <Popconfirm
          title="Are you sure to delete this report?"
          onConfirm={() => handleDeleteComment(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <div className="flex">
        <Input.Search
          placeholder="User ID"
          defaultValue={search}
          style={{ width: 400 }}
          onPressEnter={onEnterSearch}
          onSearch={onSearch}
        />
        <div className="p-2 py-1 rounded-sm border border-gray-200 text-gray-500">
          Total: {data?.adminGetComments?.total}
        </div>
      </div>
      <StandardTable
        rowKey="id"
        dataSource={data?.adminGetComments?.results || []}
        columns={columns}
        pagination={{
          reset: true,
          defaultPageSize: 20,
          total: data?.adminGetComments?.total || 0,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default TabRoomComment;
