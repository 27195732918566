import { useMutation } from '@apollo/client';
import { Button, Form, Image, Modal } from 'antd';
import { memo } from 'react';
import { FastField, Formik } from 'formik';
import { InputFormik, TextFieldInput } from 'components/Input';
import { gameValidationSchema } from 'common/validationSchema';
import UploadImage from 'components/UploadImage';
import PictureOutlined from '@ant-design/icons/lib/icons/PictureOutlined';
import { CREATE_GAME, GET_LIST_GAME_ROOM } from './schema';

const CreateGameModal = ({ visible, onCancel }) => {
  const [createGame] = useMutation(CREATE_GAME);

  const onSubmit = async (values) => {
    await createGame({
      variables: { input: values },
      refetchQueries: [GET_LIST_GAME_ROOM],
    });
    return onCancel();
  };

  const initialValues = {
    name: '',
    description: '',
    image: '',
    backgroundImage: '',
  };

  return (
    <Modal
      bodyStyle={{ minHeight: 350 }}
      title="Create new game"
      visible={visible}
      onCancel={onCancel}
      destroyOnClose
      centered
      maskStyle={{ background: 'rgba(37, 39, 51, 0.2)' }}
      width="60%"
      footer={null}
    >
      <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur validationSchema={gameValidationSchema}>
        {({ values, isValid, isSubmitting, isDirty, resetForm, setFieldValue, submitForm }) => {
          const handleAdd = async (name, url) => {
            if (url) {
              setFieldValue(name, url);
            }
          };
          return (
            <Form onSubmit={submitForm}>
              <FastField name="name" component={InputFormik} title="Game name" type="text" />
              <FastField name="description" component={TextFieldInput} title="Description" type="text" />
              <div className="grid grid-cols-2">
                <div className="border-r-2 pr-4">
                  <p className="text-gray mb-2 font-bold h-5 text-18">Thumbnail</p>
                  <UploadImage onFinish={(url) => handleAdd('image', url)}>
                    <Image
                      src={values?.image || null}
                      placeholder={<PictureOutlined style={{ fontSize: '120px' }} />}
                      preview={false}
                      height="120px"
                    />
                  </UploadImage>
                </div>
                <div className="pl-4">
                  <p className="text-gray mb-2 font-bold h-5 text-18">Background image</p>
                  <UploadImage onFinish={(url) => handleAdd('backgroundImage', url)}>
                    <Image
                      src={values?.backgroundImage || null}
                      placeholder={<PictureOutlined style={{ fontSize: '120px' }} />}
                      preview={false}
                      height="120px"
                    />
                  </UploadImage>
                </div>
              </div>
              <div className="col-span-3 flex justify-end gap-2">
                <div>
                  <Button htmlType="button" onClick={resetForm} style={{ marginRight: 10 }}>
                    Reset
                  </Button>
                  <Button
                    type="primary"
                    onClick={submitForm}
                    disabled={!isValid || isDirty}
                    loading={isSubmitting}
                    style={{ marginRight: 10 }}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default memo(CreateGameModal);
