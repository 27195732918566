// Imports
import ky from 'ky';
import { getToken } from 'store/apolloClient';
import { RECOIL_KEY, UPLOAD_PATH } from 'utils/constant';

export const { REACT_APP_API_URI, REACT_APP_WEP_APP_API } = process.env;
let api = ky.create({
  prefixUrl: REACT_APP_API_URI,
  hooks: {
    beforeRequest: [
      (request) => {
        const token = getToken();
        if (!token) return;
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
  },
});

// login
export const login = (username, password) => {
  return api
    .post('auth/admin', {
      headers: { 'content-type': 'application/json' },
      json: { username, password },
    })
    .json();
};

// forgot password
export const forgot = (username) => {
  return api
    .post('auth/admin_forget_password', {
      headers: { 'content-type': 'application/json' },
      json: { username },
    })
    .json();
};

// recover password
export const recover = (token, password) => {
  return api
    .post('auth/admin_reset_password', {
      headers: { 'content-type': 'application/json' },
      json: { token, password },
    })
    .json();
};

// login set localStorage
export const loginSet = (auth) => {
  // HTTP header
  api = api.extend({
    headers: {
      Authorization: `Bearer ${auth.token}`,
    },
  });

  // localStorage values
  window.localStorage.setItem(RECOIL_KEY.AUTH, JSON.stringify(auth));
};

// logout remove localStorage
export const logoutUnset = () => {
  // HTTP header
  api = api.extend({
    headers: {
      Authorization: undefined,
    },
  });

  // localStorage values
  window.localStorage.removeItem(RECOIL_KEY.AUTH);
  window.location.href = '/';
};

export const importQuestion = async (formData, gameId) => {
  const token = getToken();
  api = await api.post(`import/${gameId}/questions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  return api;
};

export const getDuration = (file) => {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e) => {
      audio.src = e.target.result;
      audio.addEventListener('loadedmetadata', () => {
        resolve(audio.duration);
      });
    };
    reader.onerror = reject;
  });
};

export const getRssParser = (url) => {
  return ky
    .post(`${REACT_APP_WEP_APP_API}/rss`, {
      headers: { 'content-type': 'application/json' },
      method: 'POST',
      json: { url },
    })
    .json();
};

export const uploadRecording = (file, options) => {
  const reader = new FileReader();
  const frmData = new FormData();
  frmData.append('recording', file, file?.name);

  return new Promise((resolve, reject) => {
    ky.post(UPLOAD_PATH, { timeout: 120000, body: frmData, ...options })
      .json()
      .then((response) => {
        const audio = new Audio();
        reader.readAsDataURL(file);

        reader.onload = (e) => {
          audio.src = e.target.result;
          audio.addEventListener('loadedmetadata', () => {
            resolve({
              ...response,
              recordingUrl: response?.Key && `https://${response?.Bucket}/${response?.Key}`,
              duration: audio.duration,
            });
          });
        };
      })
      .catch((error) => reject(error));
  });
};

export const tts = async (value) => {
  const results = await api
    .post('text-to-audio', {
      json: value,
      timeout: 30000, //30s
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .blob();
  return results;
};
