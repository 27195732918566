import React from 'react';
import { Modal } from 'antd';
import { FastField, Formik } from 'formik';
import UploadImageInput from 'components/UploadImageInput';
import { InputFormik } from 'components/Input';
import { addGiftSchema } from 'common/validationSchema';
import UploadAnimationFormik from 'components/Input/UploadAnimation';
import { LinkOutlined } from '@ant-design/icons';

const GiftModal = ({ visible, onCancel, onUpdate, onCreate, data }) => {
  const isEdit = !!data;

  const handleSubmit = (values) => {
    if (isEdit) return onUpdate(values);
    return onCreate(values);
  };

  return (
    <Formik validationSchema={addGiftSchema} initialValues={isEdit ? { ...data } : {}} onSubmit={handleSubmit}>
      {({ values, submitForm, isSubmitting, isValid }) => {
        return (
          <Modal
            visible={visible}
            title={isEdit ? 'Edit gift' : 'Update gift'}
            onCancel={onCancel}
            onOk={submitForm}
            okButtonProps={{ loading: isSubmitting, disabled: !isValid || isSubmitting }}
            okText={isEdit ? 'Save' : 'Create'}
          >
            <form>
              <div className="h-32 mb-2">
                <FastField
                  name="image"
                  size="8rem"
                  component={UploadImageInput}
                  className="h-full block"
                  value={values?.image}
                />
              </div>
              <div className="mt-10">
                <FastField name="point" type="number" title="Point" component={InputFormik} />
              </div>
              <div>
                <FastField title="Animation url" name="animationUrl" component={UploadAnimationFormik} />
                {values?.animationUrl && (
                  <a href={values?.animationUrl} className="text-sm text-blue-500">
                    <LinkOutlined className="text-xs" /> {values?.animationUrl}
                  </a>
                )}
              </div>
            </form>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default GiftModal;
