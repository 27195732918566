import { Table, Alert, Space } from 'antd';
import React, { useState } from 'react';
import { genColumnKey } from 'utils/helpers/table';

import styles from './styles.module.css';
import TableHeader from './TableHeader';

const StandardTable = ({
  headerTitle,
  loading,
  columns,
  rowKey,
  size,
  bordered,
  scroll,
  renderToolbar,
  allowSelectRow,
  dataSource,
  pagination,
  onChange,
  rowClassName,
}) => {
  const [selectedRowKeys, selectRows] = useState([]);
  const [columnsMap, setColumnsMap] = useState({});

  const onSelectChange = (rows) => {
    selectRows(rows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <div className={styles.standardTable}>
      <TableHeader
        headerTitle={headerTitle}
        columns={columns}
        columnsMap={columnsMap}
        setColumnsMap={setColumnsMap}
        renderToolbar={renderToolbar}
      />
      {allowSelectRow && selectedRowKeys.length ? (
        <div className={styles.tableAlert}>
          <Alert
            message={
              <>
                Selected <a style={{ fontWeight: 600 }}>{selectedRowKeys.length}</a> Items&nbsp;&nbsp;
                <div style={{ display: 'inline-block', float: 'right' }}>
                  <a onClick={() => onSelectChange([])} role="presentation" style={{ marginLeft: 24 }}>
                    Clear
                  </a>
                </div>
              </>
            }
            type="info"
            showIcon
          />
        </div>
      ) : null}

      <Table
        loading={loading}
        rowKey={rowKey || 'id'}
        rowSelection={allowSelectRow ? rowSelection : undefined}
        columns={columns.filter((item) => {
          const columnKey = genColumnKey(item.key, item.dataIndex);
          if (!columnKey) {
            return true;
          }
          const config = columnsMap[columnKey];
          if (config && config.show === false) {
            return false;
          }
          return true;
        })}
        dataSource={dataSource}
        pagination={pagination}
        onChange={onChange}
        rowClassName={rowClassName}
      />
    </div>
  );
};

export default StandardTable;
