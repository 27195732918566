import React, { Fragment, useMemo, useState } from 'react';
import { Upload, message, Spin, Modal } from 'antd';
import cn from 'classnames';
import { getToken } from 'store/apolloClient';
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import styles from './style.module.css';

const { confirm } = Modal;

const { REACT_APP_API_URI } = process.env;

const UPLOAD_PATH = `${REACT_APP_API_URI}/upload`;

const CROP_INITIAL = {
  showGrid: true,
  modalProps: {
    transitionName: 'none',
    maskTransitionName: 'none',
  },
};

export const UploadWrapper = React.memo(({ className = '', children, loading, loadingComponent }) => {
  const _loaderOverlay = loadingComponent || (
    <div className="absolute bg-gray-200 bg-opacity-50 h-full w-full z-10 flex items-center justify-center">
      <Spin />
    </div>
  );
  return (
    <div className={cn('relative w-fit h-fit', className)}>
      {loading && _loaderOverlay} {children}
    </div>
  );
});

UploadWrapper.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};

const UploadImage = (props) => {
  const { onFinish = () => {}, loadingComponent, className, children, useCrop, aspect = 1 } = props;
  const [uploading, setUploading] = useState(false);

  const beforeUpload = async (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt300KB = file.size / 1024 <= 300;
    if (!isLt300KB) {
      return new Promise((resolve, reject) => {
        confirm({
          centered: true,
          title: 'Nhớ up hình dưới 300kb, không là anh Lâm chửi -.-',
          icon: <ExclamationCircleOutlined />,
          onOk: () => resolve(true),
          onCancel: () => reject(),
        });
      });
    }
    return isJpgOrPng;
  };

  const handleAdd = async (info) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    }
    if (info?.file?.status === 'done' && info?.file?.response?.uploaded) {
      setUploading(false);
      onFinish(info?.file?.response?.url);
    }
  };

  const authorization = getToken() ? `Bearer ${getToken()}` : null;

  const Parent = useMemo(() => (useCrop ? ImgCrop : Fragment), [useCrop]);

  return (
    <Parent {...(useCrop ? { aspect, ...CROP_INITIAL } : {})}>
      <Upload
        disabled={uploading}
        name="upload"
        className={styles.upload}
        multiple={false}
        showUploadList={false}
        beforeUpload={beforeUpload}
        action={UPLOAD_PATH}
        headers={{ authorization }}
        onChange={handleAdd}
      >
        <UploadWrapper className={className} loading={uploading} loadingComponent={loadingComponent}>
          {children}
        </UploadWrapper>
      </Upload>
    </Parent>
  );
};

UploadImage.propTypes = {
  onFinish: PropTypes.func,
  loadingComponent: PropTypes.element,
  children: PropTypes.element,
};

export default UploadImage;
