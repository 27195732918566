import React from 'react';
import { Image, Select, Spin } from 'antd';
import { gql, useLazyQuery } from '@apollo/client';
import { OPERATOR } from 'utils/constant';

const ALL_USERS = gql`
  query adminGetUserList($filter: JSON, $pageSize: Int, $page: Int) {
    adminGetUserList(filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        username
        earning
        point
        email
        avatar
        status
      }
    }
  }
`;

const { Option } = Select;

const UserSelect = ({
  handleChange,
  renderUserList,
  title,
  disabled,
  isInvalid,
  description,
  required,
  ...selectProps
}) => {
  const [getUser, { data: dataUser, loading }] = useLazyQuery(ALL_USERS);
  const result = dataUser?.adminGetUserList?.results;
  const renderUser = () => {
    if (typeof renderUserList === 'function') {
      return renderUserList();
    }
    if (!loading && result) {
      return result?.map((element) => (
        <Option key={element.id} value={element.id}>
          <div className="flex items-center gap-2">
            {element.avatar && (
              <Image className="rounded-full" src={element.avatar} height={20} width={20} alt={element.username} />
            )}
            {element.username}
          </div>
        </Option>
      ));
    }
    return null;
  };
  const handleSearchUser = (value) => {
    if (value?.trim()) {
      getUser({
        variables: {
          page: 0,
          pageSize: 40,
          filter: [
            {
              operator: OPERATOR.or,
              value: [
                { field: 'username', operator: OPERATOR.contains, value },
                { field: 'email', operator: OPERATOR.contains, value },
              ],
            },
          ],
        },
      });
    }
  };
  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">
        {title || 'User'} {required && <span className="text-red-400">*</span>}
      </span>
      <Select
        onChange={handleChange}
        showSearch
        style={{ width: '100%', border: isInvalid ? '2px solid red' : '' }}
        filterOption={false}
        placeholder="Please select user"
        onSearch={handleSearchUser}
        notFoundContent={loading ? <Spin size="small" /> : null}
        disabled={disabled}
        {...selectProps}
      >
        {renderUser()}
      </Select>
      {description && <p className="text-xs text-gray-400 mt-1">{description}</p>}
    </div>
  );
};

export default UserSelect;
