import React, { useMemo, useCallback, useRef } from 'react';
import { Card, Layout } from 'antd';
import useFilter from 'utils/hooks/useFilter';
import { pick } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_LIST_COUPON } from 'common/coupon.gql';
import { COUPON_STATUS, OPERATOR } from 'utils/constant';
import TableCoupon from 'components/Coupon/TableCoupon';
import moment from 'moment';

const TAB_LIST = [
  {
    key: 'active',
    tab: 'Active',
  },
  {
    key: 'inactive',
    tab: 'Inactive',
  },
];

const SORT_KEYS = ['maximum', 'expiredAt', 'created'];
const generateSort = (query) => {
  return Object.keys(query).map((k) => ({ field: k, value: query[k] === 'descend' ? 'desc' : 'asc' }));
};
const generateFilterSearch = (value) => {
  if (!value) return [];
  return [
    {
      operator: OPERATOR.or,
      value: [
        { field: 'code', operator: OPERATOR.contains, value },
        { field: 'description', operator: OPERATOR.contains, value },
      ],
    },
  ];
};

const Coupon = () => {
  const timeRef = useRef(moment());
  const { querySearch, onSearch } = useFilter(['search', 'page', 'type', 'tab', 'expired', ...SORT_KEYS]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const _sort = useMemo(() => generateSort(pick(querySearch, SORT_KEYS)), [
    querySearch?.maximum,
    querySearch?.expiredAt,
    querySearch?.created,
  ]);

  const { data, loading } = useQuery(GET_LIST_COUPON, {
    variables: {
      page: Number(querySearch?.page),
      pageSize: 20,
      sort: _sort,
      filter: [
        {
          field: 'status',
          operator: OPERATOR.eq,
          value: querySearch?.tab === COUPON_STATUS.inactive ? COUPON_STATUS.inactive : COUPON_STATUS.active,
        },
        ...(!querySearch?.expired
          ? [{ field: 'expiredAt', operator: OPERATOR.gte, value: timeRef.current.toISOString() }]
          : []),
        ...(querySearch?.type ? [{ field: 'type', operator: OPERATOR.eq, value: querySearch?.type }] : []),
        ...generateFilterSearch(querySearch?.search),
      ],
    },
  });

  const listData = data?.adminGetListCoupon?.results || [];

  const handleTableChange = useCallback((page, filter, sort) => {
    const newSort = Object.fromEntries(SORT_KEYS.map((k) => [k, sort.field === k ? sort.order : null]));
    onSearch({ page: page.current - 1, ...filter, ...newSort });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.Content>
      <Card
        bordered
        activeTabKey={querySearch?.tab}
        onTabChange={(key) => onSearch({ tab: key, page: 0 })}
        tabList={TAB_LIST}
      >
        <TableCoupon
          data={listData}
          loading={loading}
          total={data?.adminGetListCoupon?.total}
          onTableChange={handleTableChange}
          onSearch={onSearch}
        />
      </Card>
    </Layout.Content>
  );
};

export default Coupon;
