import React, { useState, useEffect } from 'react';
import { Layout, Card, Input } from 'antd';
import { gql, useQuery } from '@apollo/client';
import useFilter from 'utils/hooks/useFilter';

import { MAX_NUMERIC_VALUE, OPERATOR } from 'utils/constant';
import isNaN from 'lodash/isNaN';
import UserTable from './components/UserTable';

const { Search } = Input;

const ALL_USERS = gql`
  query adminGetUserList($filter: JSON, $sort: JSON, $pageSize: Int, $page: Int) {
    adminGetUserList(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      results {
        id
        username
        email
        firstName
        lastName
        status
        avatar
        created
        premiumType
        premiumExp
      }
      total
      currentPage
      pageSize
    }
  }
`;

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    const value = filters[filter]?.trim?.();

    switch (filter) {
      case 'username':
        newFilter.push({
          operator: OPERATOR.or,
          value: [
            ...(!isNaN(+value) && Number(value) <= MAX_NUMERIC_VALUE
              ? [{ field: 'id', operator: OPERATOR.eq, value }]
              : []),
            { field: 'username', operator: OPERATOR.contains, value },
            { field: 'email', operator: OPERATOR.contains, value },
          ],
        });
        break;
      case 'status':
        if (value) newFilter.push({ field: filter, operator: OPERATOR.in, value });
        break;
      case 'premiumType':
        if (filters[filter].length) newFilter.push({ field: filter, operator: OPERATOR.in, value: filters[filter] });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const User = ({ location }) => {
  const [sortState, updateSortState] = useState([]);
  const { data, error, loading, refetch } = useQuery(ALL_USERS, {
    variables: {
      page: 0,
      pageSize: 30,
      sort: sortState,
    },
  });
  const filter = useFilter(['status', 'page', 'pageSize', 'username', 'premiumType', 'premiumExp']);
  const { pageSize = 30, status, page = 1, username, premiumType, premiumExp } = filter.querySearch;

  useEffect(() => {
    if (filter.querySearch) {
      refetch({
        page: Number(page - 1),
        pageSize: Number(pageSize),
        filter: generateFilter(filter.querySearch),
        sort: sortState,
      });
    }
  }, [pageSize, page, status, username, premiumType, premiumExp]);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order === 'ascend') {
      updateSortState([{ field: sorter?.field, value: 'asc' }]);
    }
    if (sorter?.order === 'descend') {
      updateSortState([{ field: sorter?.field, value: 'desc' }]);
    }
    const { current, pageSize: currentPageSize } = pagination;
    filter.onSearch({ page: current, pageSize: currentPageSize, ...filters });
  };

  const onEnterSearch = ({ target } = {}) => {
    filter.onSearch({ username: target.value, page: 1 });
  };
  const onSearch = (event) => {
    filter.onSearch({ username: event, page: 1 });
  };

  return (
    <Layout.Content>
      <Card>
        <Search placeholder="Search User" style={{ width: 400 }} onPressEnter={onEnterSearch} onSearch={onSearch} />
        <UserTable
          loading={loading}
          data={data?.adminGetUserList?.results}
          pagination={{
            reset: true,
            defaultPageSize: 30,
            showSizeChanger: true,
            total: data?.adminGetUserList?.total || 0,
            pageSizeOptions: ['5', '10', '20', '30'],
          }}
          onChange={handleTableChange}
        />
      </Card>
    </Layout.Content>
  );
};

export default User;
