import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Card, Layout, message, Popconfirm, Tag, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import StandardTable from 'components/StandardTable';
import { BarChartOutlined, RetweetOutlined } from '@ant-design/icons';
import { OPERATOR, ROOM_STATUS } from 'utils/constant';
import moment from 'moment';

import useFilter from 'utils/hooks/useFilter';
import { GET_LIST_ROOM_TICKET, REOPEN_ROOM } from './schema';
import RoomDetailModal from './RoomDetailModal';

const DEFAULT_PAGE_SIZE = 30;

const DATE_FORMAT = 'DD/MM/YYYY, HH:MM';

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    switch (filter) {
      case 'status':
        newFilter.push({ field: filter, operator: OPERATOR.in, value: filters[filter] });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const TicketShow = () => {
  const [roomSelected, setRoomSelected] = useState(null);

  const [getRoomTicket, { data, loading }] = useLazyQuery(GET_LIST_ROOM_TICKET);

  const [reopenRoom, { loading: reopening }] = useMutation(REOPEN_ROOM);

  const filter = useFilter(['page', 'status']);

  const { page = 1, status } = filter.querySearch;

  useEffect(() => {
    if (filter.querySearch) {
      getRoomTicket({
        variables: {
          page: Number(page - 1),
          pageSize: DEFAULT_PAGE_SIZE,
          sort: [{ field: 'start', value: 'desc' }],
          filter: [...generateFilter(filter.querySearch), { field: 'type', operator: OPERATOR.eq, value: 'ticket' }],
        },
      });
    }
  }, [page, status]);

  const handleChangeTable = (pagination, filters) => {
    const { current, pageSize: pS } = pagination;
    filter.onSearch({ page: current, pageSize: pS, ...filters });
  };

  const handleReopenRoom = (roomId) => {
    reopenRoom({
      variables: { adminReopenTicketRoomId: roomId },
      onCompleted: () => message.success('Reopen room completed'),
      refetchQueries: [GET_LIST_ROOM_TICKET],
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Room',
      key: 'room',
      dataIndex: 'title',
      width: '25%',
    },
    {
      title: 'Creator',
      key: 'creator',
      width: '10%',
      dataIndex: 'creator',
      render: (val) => <Link to={`user/info/${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Start',
      key: 'start',
      dataIndex: 'start',
      width: '20%',
      render: (value) => {
        return moment(value).format(DATE_FORMAT);
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: 100,
      dataIndex: 'status',
      filters: Object.keys(ROOM_STATUS).map((key) => ({
        text: ROOM_STATUS[key],
        value: ROOM_STATUS[key],
      })),
      render: (value) => {
        const colorTag = {
          [ROOM_STATUS.OPEN]: 'green',
          [ROOM_STATUS.LIVE]: 'purple',
          [ROOM_STATUS.COMPLETED]: 'volcano',
        }[value];

        return (
          <Tag color={colorTag} style={{ textTransform: 'capitalize' }}>
            {value}
          </Tag>
        );
      },
    },
    {
      title: 'Sold',
      key: 'ticket',
      render: (val) => `${val?.totalTicketSold || 0} / ${val?.totalTicket || 0}`,
    },
    {
      title: 'Action',
      key: 'action',
      render: (val) => (
        <>
          <Tooltip placement="topRight" title="View detail">
            <Button
              onClick={() => setRoomSelected(val)}
              type="text"
              icon={<BarChartOutlined style={{ fontSize: 20 }} />}
            />
          </Tooltip>
          {val?.status === ROOM_STATUS.COMPLETED && (
            <Popconfirm title="Reopen this room?" onConfirm={() => handleReopenRoom(val.id)}>
              <Button
                loading={reopening}
                disabled={reopening}
                type="text"
                icon={<RetweetOutlined style={{ fontSize: 20 }} />}
              />
            </Popconfirm>
          )}
        </>
      ),
    },
  ];

  return (
    <Layout.Content className="flex flex-col md:p-4 p-2 space-y-4">
      <Card className="overflow-auto">
        <StandardTable
          dataSource={data?.adminGetRoomList?.results || []}
          loading={loading}
          columns={columns}
          rowKey="id"
          pagination={{
            showSizeChanger: false,
            total: data?.adminGetRoomList?.total || 0,
            pageSize: DEFAULT_PAGE_SIZE,
          }}
          onChange={handleChangeTable}
        />
        {roomSelected && (
          <RoomDetailModal roomData={roomSelected} visible={!!roomSelected} onCancel={() => setRoomSelected(null)} />
        )}
      </Card>
    </Layout.Content>
  );
};

export default TicketShow;
