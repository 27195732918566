import { Button, Input, Popconfirm, message } from 'antd';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import StandardTable from 'components/StandardTable';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_DECORATION, GET_LIST_DECORATION } from 'common/decoration.gql';
import useFilter from 'utils/hooks/useFilter';
import { OPERATOR } from 'utils/constant';
import ModalBorderDecor from 'components/Decoration/ModalBorderDecor';
import { generateSort } from 'utils/helpers/table';
import { pick } from 'lodash';

const SORT_KEYS = ['created'];
const PAGE_SIZE = 20;

const BorderDecoration = () => {
  const [editItem, setEditItem] = useState(null);
  const { querySearch, onSearch } = useFilter(['title', 'page', 'tab'].concat(SORT_KEYS));
  const [deleteDecoration] = useMutation(DELETE_DECORATION);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const querySort = useMemo(() => generateSort(pick(querySearch, SORT_KEYS)), [querySearch?.created]);
  const queryFilter = useMemo(() => {
    const gen = [{ field: 'title', operator: OPERATOR.contains, value: querySearch?.title }].filter((i) => !!i.value);
    return gen.length ? gen : undefined;
  }, [querySearch?.title]);

  const { data, loading, refetch } = useQuery(GET_LIST_DECORATION, {
    variables: {
      currentPage: Number(querySearch?.page),
      pageSize: PAGE_SIZE,
      sort: querySort,
      filter: queryFilter,
    },
  });

  const { results, total } = data?.getListDecoration || {};

  const onTableChange = (page, filter, sort) => {
    const newSort = Object.fromEntries(SORT_KEYS.map((k) => [k, sort.field === k ? sort.order : null]));
    onSearch({ page: page.current - 1, ...filter, ...newSort });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onDeleteItem = (record) => {
    deleteDecoration({
      variables: { adminDeleteDecorationId: record.id },
      onCompleted: () => message.success('Deleted!'),
      update: (cache) => {
        cache.evict(cache.identify(record));
      },
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 80,
      fixed: 'left',
    },
    {
      title: 'Border',
      dataIndex: 'border',
      width: 150,
      render: (record) => (
        <div className="flex justify-center items-center gap-4">
          <img src={record} height={100} width={100} alt="" />
        </div>
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      minWidth: 150,
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      width: 200,
      sorter: true,
      render: (value) => moment(value).format('HH:mm, DD/MM/YYYY'),
    },
    {
      title: '',
      width: 100,
      render: (record) => (
        <div className="flex justify-center items-center">
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onDeleteItem(record)}
            okText="Delete"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} danger type="text" />
          </Popconfirm>
          <Button onClick={() => setEditItem(record)} icon={<EditOutlined />} type="text" />
        </div>
      ),
    },
  ];

  return (
    <div>
      <StandardTable
        renderToolbar={() => [
          <strong key="total">Total: {total}</strong>,
          <Input
            defaultValue={querySearch?.title}
            key="search"
            placeholder="Search"
            onChange={({ target }) => onSearch({ title: target.value })}
          />,
          <Button onClick={() => setEditItem({})} type="primary" key="primary">
            Add
          </Button>,
        ]}
        pagination={{
          showSizeChanger: false,
          total: total || 0,
          pageSize: PAGE_SIZE,
        }}
        onChange={onTableChange}
        loading={loading}
        dataSource={results}
        columns={columns}
      />

      {!!editItem && (
        <ModalBorderDecor
          opened
          onDismiss={() => setEditItem(false)}
          values={editItem}
          onSuccess={() => {
            setEditItem(false);
            refetch();
          }}
        />
      )}
    </div>
  );
};

export default BorderDecoration;
