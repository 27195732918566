/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import ProLayout, { PageContainer } from '@ant-design/pro-layout';
import { Link } from 'react-router-dom';
import {
  SmileOutlined,
  HeartOutlined,
  CrownOutlined,
  BarChartOutlined,
  MessageOutlined,
  UserOutlined,
  CalendarOutlined,
  SettingOutlined,
  NotificationOutlined,
  AimOutlined,
  PayCircleOutlined,
  DatabaseOutlined,
  WarningOutlined,
  CreditCardOutlined,
  HomeOutlined,
  TagOutlined,
  AliwangwangOutlined,
  UnorderedListOutlined,
  CommentOutlined,
  RocketOutlined,
  PercentageOutlined,
  StarOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { Result, Button } from 'antd';

import Authorized from 'components/Authorized';
import GlobalHeader from 'components/GlobalHeader';
import GlobalFooter from 'components/GlobalFooter';

import { getAuthorityFromRouter } from 'utils/authority';
import { useScrollToTop, useSetPermission as usePermissions } from 'utils/hooks';
import { PERMISSION_LIST } from 'utils/constant';
import './basicLayout.css';
import logo from '../logo.png';

const IconMap = {
  smile: <SmileOutlined />,
  heart: <HeartOutlined />,
  crown: <CrownOutlined />,
  'bar-chart': <BarChartOutlined />,
  message: <MessageOutlined />,
  user: <UserOutlined />,
  calendar: <CalendarOutlined />,
  setting: <SettingOutlined />,
  notification: <NotificationOutlined />,
  aim: <AimOutlined />,
  pay: <PayCircleOutlined />,
  wave: <RocketOutlined />,
  database: <DatabaseOutlined />,
  comment: <CommentOutlined />,
  warning: <WarningOutlined />,
  money: <CreditCardOutlined />,
  home: <HomeOutlined />,
  ticket: <TagOutlined />,
  'game-room': <AliwangwangOutlined />,
  playlist: <UnorderedListOutlined />,
  coupon: <PercentageOutlined />,
  decoration: <StarOutlined />,
  tool: <ToolOutlined />,
};

const noMatch = (
  <Result
    status={403}
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
    extra={() => (
      <Button type="primary">
        <Link to="/user/login">Go Login</Link>
      </Button>
    )}
  />
);

const defaultFooterDom = <GlobalFooter copyright={`${new Date().getFullYear()} Onmic`} />;

const BasicLayout = (props) => {
  const {
    dispatch,
    children,
    settings,
    location = {
      pathname: '/',
    },
    route,
    history,
  } = props;
  const permissionList = usePermissions(PERMISSION_LIST);

  const ref = useRef();
  useScrollToTop({ ref });

  const handleMenuCollapse = (payload) => {
    if (dispatch) {
      dispatch({
        type: 'global/changeLayoutCollapsed',
        payload,
      });
    }
  }; // get children authority

  const authorized = getAuthorityFromRouter(route.routes, location.pathname || '/') || {
    authority: undefined,
  };

  const menuDataRender = (menuList) =>
    menuList.map(({ icon, children: _children, ...item }) => {
      const localItem = {
        ...item,
        icon: icon && IconMap[icon],
        children: _children ? menuDataRender(_children) : undefined,
      };
      return Authorized.check(item.authority, permissionList, localItem, null);
    });

  return (
    <ProLayout
      title="OnMic"
      logo={<img alt="logo" style={{ margin: 8, width: '25px', height: '100%' }} src={logo} />}
      navTheme="light"
      onCollapse={handleMenuCollapse}
      onMenuHeaderClick={() => history.push('/')}
      menuItemRender={(menuItemProps, defaultDom) => {
        if (menuItemProps.isUrl || !menuItemProps.path) {
          return defaultDom;
        }
        return <Link to={menuItemProps.path + (menuItemProps.query || '')}>{defaultDom}</Link>;
      }}
      breadcrumbRender={(routers = []) => [
        {
          path: '/',
          breadcrumbName: 'home',
        },
        ...routers,
      ]}
      itemRender={(r, params, routes, paths) => {
        const first = routes.indexOf(route) === 0;
        return first ? <Link to={paths.join('/')}>{r.breadcrumbName}</Link> : <span>{r.breadcrumbName}</span>;
      }}
      footerRender={() => defaultFooterDom}
      menuDataRender={menuDataRender}
      rightContentRender={() => <GlobalHeader />}
      {...props}
      {...settings}
    >
      <Authorized {...(authorized || {})} authority={authorized?.authority} noMatch={noMatch}>
        <PageContainer>{children}</PageContainer>
      </Authorized>
    </ProLayout>
  );
};

export default BasicLayout;
