import { useMutation } from '@apollo/client';
import { Button, Card, Input, Layout, message, Modal, Tag } from 'antd';
import { InputFormik } from 'components/Input';
import { Formik, Form, FastField } from 'formik';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { authState } from 'store/atom';
import { useState } from 'react';
import { changPswSchema, adminSchema } from 'common/validationSchema';
import { CHANGE_PASSWORD_ADMIN } from './schema';

const Profile = () => {
  const { admin } = useRecoilValue(authState);
  const [isChangePsw, setIsChangePsw] = useState(false);

  const [updatePsw, { loading: mutationLoading, success: multationSuccess, error: mutationError }] = useMutation(
    CHANGE_PASSWORD_ADMIN,
    {
      onError: () => message.error('Password incorrect!'),
      onCompleted: () => {
        message.success('Change password successfully!');
        setIsChangePsw(false);
      },
    }
  );
  const onChangePsw = async (values, { setSubmitting }) => {
    await updatePsw({
      variables: {
        oldPassword: values.oldPsw,
        newPassword: values?.newPsw,
      },
    });
    setSubmitting(false);
  };
  const onSubmit = (values, { setSubmitting }) => {
    message.success('Update success');
    setSubmitting(false);
  };

  const initialValues = {
    username: admin?.username || '',
    email: admin?.email || '',
  };

  return (
    <Layout.Content>
      <Card>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur validationSchema={adminSchema}>
          {({ isValid, isSubmitting, isDirty, resetForm }) => {
            return (
              <Form className="grid grid-cols-3 gap-y-2 gap-x-8">
                <FastField name="username" component={InputFormik} title="Username" type="text" readOnly />
                <div className="relative">
                  <FastField
                    name="email"
                    component={InputFormik}
                    title={
                      <p>
                        Email{' '}
                        <span style={{ color: admin?.emailVefified ? '#2ecc71' : '#e74c3c' }}>
                          {admin?.emailVefified ? '- verified' : '- unverified'}
                        </span>{' '}
                      </p>
                    }
                    disabled
                  />
                </div>
                <div className="flex flex-row items-center">
                  <span className="text-gray font-bold h-5 text-18 mr-4">Status</span>
                  <Tag color={{ active: 'success', default: 'warning' }[admin?.status]}>{admin?.status}</Tag>
                </div>
                <div className="col-span-3 flex justify-end gap-2">
                  <div>
                    <Button className="mr-3" htmlType="button" onClick={() => setIsChangePsw((pre) => !pre)}>
                      Change password
                    </Button>
                    <Button htmlType="button" onClick={resetForm} style={{ marginRight: 10 }}>
                      Reset
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!isValid || isDirty}
                      loading={isSubmitting}
                      style={{ marginRight: 10 }}
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
        {/* changePsw */}
        <Formik initialValues={{ oldPsw: '' }} onSubmit={onChangePsw} validateOnBlur validationSchema={changPswSchema}>
          {({ values, isSubmitting, isValid, handleSubmit, resetForm }) => {
            return (
              <Form onSubmit={handleSubmit} className='className="grid grid-cols-3 gap-y-2 gap-x-8"'>
                <Modal
                  okButtonProps={{
                    onClick: handleSubmit,
                    loading: isSubmitting || mutationLoading,
                    disabled: !isValid || !values?.oldPsw,
                  }}
                  destroyOnClose
                  visible={isChangePsw}
                  onCancel={() => {
                    resetForm();
                    setIsChangePsw(false);
                  }}
                >
                  <FastField name="oldPsw" component={InputFormik} title="Old password" type="password" />
                  <FastField name="newPsw" component={InputFormik} title="New password" type="text" />
                  <FastField name="confirmPsw" component={InputFormik} title="Confirm password" type="text" />
                </Modal>
              </Form>
            );
          }}
        </Formik>
      </Card>
    </Layout.Content>
  );
};

export default Profile;
