/* eslint-disable react/jsx-key */
/* eslint-disable no-shadow */

import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Popconfirm, message } from 'antd';
import { DELETE_ADMIN, ALL_ADMIN } from 'containers/AdminManagement/schema';

import StandardTable from 'components/StandardTable';
import AdminModal from './AdminModal';

const AdminContainer = () => {
  const { data, loading } = useQuery(ALL_ADMIN);

  const [deleteAdmin] = useMutation(DELETE_ADMIN, {
    onCompleted: () => message.success('Admin has been deleted successfully.'),
  });

  const handleDeleteAdmin = (adminId) => {
    deleteAdmin({
      variables: { id: adminId },
      refetchQueries: [{ query: ALL_ADMIN }],
    });
  };

  const columns = [
    {
      title: 'Username',
      key: 'username',
      dataIndex: 'username',
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      // eslint-disable-next-line react/destructuring-assignment
      render: (item) => <div>{item?.name}</div>,
    },
    {
      title: 'Last Active At',
      key: 'lastActivedAt',
      dataIndex: 'lastActivedAt',
    },

    {
      title: '',
      render: (item) => (
        <Space>
          <Popconfirm
            // eslint-disable-next-line react/destructuring-assignment
            title={`Are you sure to delete ${item.username}?`}
            onConfirm={() => handleDeleteAdmin(item.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button className="flex justify-center items-center" ghost danger icon={<DeleteOutlined />} />
          </Popconfirm>

          <AdminModal adminData={item} type="edit">
            {(doOpenModal) => (
              <Button
                onClick={doOpenModal}
                className="flex justify-center items-center"
                ghost
                type="primary"
                icon={<EditOutlined />}
              />
            )}
          </AdminModal>
        </Space>
      ),
    },
  ];
  return (
    <StandardTable
      renderToolbar={() => [
        <AdminModal type="add">
          {(doOpenModal) => (
            <Button type="primary" key="primary" onClick={doOpenModal}>
              <PlusOutlined /> Add
            </Button>
          )}
        </AdminModal>,
      ]}
      headerTitle="Admin Management"
      rowKey="id"
      dataSource={data?.adminGetListAdmin}
      columns={columns}
      loading={loading}
      pagination={{
        hideOnSinglePage: true,
      }}
    />
  );
};

export default AdminContainer;
