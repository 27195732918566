import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Popconfirm } from 'antd';
import React from 'react';
import StandardTable from 'components/StandardTable';
import { Link } from 'react-router-dom';

const CommentTable = ({ data, pagination, onChange, onDelete }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Time',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (val) => <span>{moment(val).format('DD/MM/YYYY, HH:mm')}</span>,
    },
    {
      title: 'Username',
      dataIndex: 'creator',
      key: 'creator',
      render: (val, record) => <Link to={`/report?page=1&creatorId=${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Wave id',
      key: 'waveId',
      dataIndex: 'waveId',
      render: (val) => val, //TODO: Page manage wave
    },
    {
      title: 'Room id',
      key: 'room',
      dataIndex: 'roomId',
      render: (val) => <Link to={`/room/edit/${val}?tab=comment`}>{val}</Link>,
    },

    {
      title: 'Action',
      dataIndex: 'room',
      key: 'action',
      render: (val, record) => (
        <Popconfirm
          title="Are you sure to delete this comment?"
          onConfirm={() => onDelete(record.id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <StandardTable rowKey="id" dataSource={data} columns={columns} pagination={pagination} onChange={onChange} />
    </div>
  );
};

export default CommentTable;
