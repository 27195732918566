import { gql, useQuery } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { Table, Tag } from 'antd';
import { TYPE_COLOR } from 'containers/Room/components/RoomTable';
import moment from 'moment';
import { useMemo } from 'react';
import { OPERATOR } from 'utils/constant';

const DATE_FORMAT = 'HH:mm, DD/MM/YYYY';

const ROOM_LIST = gql`
  query adminGetRoomList($filter: JSON, $sort: JSON, $pageSize: Int, $page: Int) {
    adminGetRoomList(filter: $filter, sort: $sort, pageSize: $pageSize, page: $page) {
      currentPage
      total
      results {
        id
        title
        description
        start
        end
        creatorId
        type
        speakers {
          id
          role
          user {
            id
            firstName
            lastName
            username
            avatar
          }
        }
      }
    }
  }
`;

const HistoryTable = ({ user }) => {
  const now = useMemo(() => moment().toISOString(), []);

  const { loading, data, refetch } = useQuery(ROOM_LIST, {
    variables: {
      filter: [
        { field: 'creatorId', operator: OPERATOR.eq, value: user?.id },
        { field: 'start', operator: OPERATOR.lt, value: now },
      ],
      sort: [{ field: 'start', value: 'desc' }],
    },
    skip: !user?.id,
  });

  const { results, ...paginationData } = data?.[getOperationName(ROOM_LIST)] || {};
  const { total = 30, currentPage = 0, pageSize = 30 } = paginationData;

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 250,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (value) => <Tag color={TYPE_COLOR[value]}>{value}</Tag>,
    },
    {
      title: 'Start',
      dataIndex: 'start',
      render: (value) => moment(value).format(DATE_FORMAT),
    },
    {
      title: 'End',
      dataIndex: 'end',
      render: (value) => moment(value).format(DATE_FORMAT),
    },
    {
      title: 'Duration',
      render: (value) => {
        const start = moment(value?.start);
        const end = value?.end ? moment(value.end) : start;
        const diff = end.diff(start);
        const duration = moment.utc(diff).format('HH:mm:ss');
        return duration;
      },
    },
  ];

  const handleChangePage = (pagination, filter) => {
    const { current, pageSize: pS } = pagination;
    refetch({ page: current - 1, pageSize: pS });
  };

  return (
    <Table
      rowKey={(record) => `${record.created}${record.type}`}
      columns={columns}
      loading={loading}
      dataSource={results}
      pagination={{
        total,
        showSizeChanger: false,
        defaultPageSize: pageSize,
        current: currentPage + 1,
      }}
      onChange={handleChangePage}
    />
  );
};

export default HistoryTable;
