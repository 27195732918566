import numeral from 'numeral';

export const genColumnKey = (key, dataIndex, index) => {
  if (key) {
    return key;
  }
  if (!key && dataIndex) {
    if (Array.isArray(dataIndex)) {
      return dataIndex.join('-');
    }
    return dataIndex;
  }
  return `${index}`;
};

export const removeObjectNull = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key]) {
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const reduceWidth = (width) => {
  if (width === undefined) {
    return width;
  }
  if (typeof width === 'string') {
    if (!width.includes('calc')) {
      return `calc(100% - ${width})`;
    }
    return width;
  }
  if (typeof width === 'number') {
    return width - 32;
  }
  return width;
};

export const formatPriceVnd = (price) => numeral(price).format('0,0');

export const generateSort = (query) => {
  return Object.keys(query).map((k) => ({ field: k, value: query[k] === 'descend' ? 'desc' : 'asc' }));
};
