import React from 'react';
import { Switch, Spin, Result } from 'antd';
import { useMutation } from '@apollo/client';
import { UPDATE_CORE_SETTING } from '../schema';

const IntimateStranger = ({ intimate = [], loading, error }) => {
  const [updateSetting] = useMutation(UPDATE_CORE_SETTING);
  const handleChangeIS = (checked) => {
    updateSetting({ variables: { id: intimate.id, value: String(checked) } });
  };

  if (loading) {
    return <Spin />;
  }

  if (error) {
    return <Result status="error" title="Fail to fetch data" />;
  }

  return (
    <div>
      <h3>Enable Intimate Stranger</h3>
      <Switch checked={intimate?.value === 'true'} onChange={handleChangeIS} />
    </div>
  );
};

export default IntimateStranger;
