import React from 'react';
import PropTypes from 'prop-types';
import AvatarDropdown from './AvatarDropdown';
import styles from './styles.module.css';

const GlobalHeaderRight = (props) => {
  const { theme = 'light', layout } = props;
  let className = styles.right;

  if (theme === 'dark' && layout === 'top') {
    className = `${styles.right}  ${styles.dark}`;
  }

  return (
    <div className={className}>
      <AvatarDropdown />
    </div>
  );
};

GlobalHeaderRight.propTypes = {
  theme: PropTypes.string,
  layout: PropTypes.string,
};
export default GlobalHeaderRight;
