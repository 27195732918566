import { useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import { InputFormik, NumberInput } from 'components/Input';
import { FastField, Formik } from 'formik';
import * as Yup from 'yup';
import { ADMIN_GET_ALL_CATEGORY, CREATE_CATEGORY, UPDATE_CATEGORY } from '../schema';

const FORM = {
  title: 'title',
  slug: 'slug',
  priority: 'priority',
};

const validationSchema = Yup.object().shape({
  [FORM.title]: Yup.string().required('Field is required!'),
  [FORM.slug]: Yup.string().required('Field is required!'),
  [FORM.priority]: Yup.number().required('Field is required!'),
});

const ModalCreateCategory = ({ visible, onDismiss, data }) => {
  const isEdit = !!Object.values(data).length;
  const [createCategory, { loading: creating }] = useMutation(CREATE_CATEGORY, {
    refetchQueries: [ADMIN_GET_ALL_CATEGORY],
    onCompleted: () => {
      message.success('Created!');
      onDismiss();
    },
    onError: () => message.error('Error!'),
  });
  const [updateCategory, { loading: updating }] = useMutation(UPDATE_CATEGORY, {
    refetchQueries: [ADMIN_GET_ALL_CATEGORY],
    onCompleted: () => {
      message.success('Created!');
      onDismiss();
    },
    onError: () => message.error('Error!'),
  });

  const onSubmit = (values) => {
    if (!isEdit) {
      createCategory({ variables: { input: values } });
    } else {
      updateCategory({
        variables: {
          adminUpdatePlaylistCategoryId: data?.id,
          input: { ...values },
        },
      });
    }
  };

  const initialValues = {
    [FORM.title]: data?.title || '',
    [FORM.priority]: data?.priority || 1,
    [FORM.slug]: data?.slug || '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {({ submitForm }) => {
        return (
          <Modal
            title={isEdit ? 'Edit category' : 'Create new category'}
            visible={visible}
            destroyOnClose
            onCancel={onDismiss}
            centered
            onOk={submitForm}
            okText={isEdit ? 'Edit' : 'Create'}
            okButtonProps={{ loading: creating || updating, disabled: creating || updating }}
          >
            <FastField title="Title" name={FORM.title} component={InputFormik} />
            <FastField title="Slug" name={FORM.slug} component={InputFormik} />
            <FastField title="Priority" name={FORM.priority} component={NumberInput} min={1} max={10} />
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ModalCreateCategory;
