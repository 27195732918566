import { Alert } from 'antd';
import React from 'react';
import { useRecover } from 'utils/hooks/useLogin';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import qs from 'qs';
import LoginForm from './components/LoginForm';

import styles from './styles.module.css';

const { Password, Submit } = LoginForm;

const LoginMessage = ({ content, type = 'error' }) => (
  <Alert style={{ marginBottom: 24 }} message={content} type={type} showIcon />
);
LoginMessage.propTypes = {
  content: PropTypes.string,
  type: PropTypes.string,
};

const Forgot = (props) => {
  const { token } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

  const { recoverPassword, submitting, message, success } = useRecover();
  const handleSubmit = (values) => {
    recoverPassword({ ...values, token });
  };

  return (
    <div className={styles.main}>
      <LoginForm onSubmit={handleSubmit}>
        {message && !submitting && <LoginMessage content={message} />}
        {success && !submitting && (
          <LoginMessage content="Password has been changed! Please login with new password." type="success" />
        )}
        <Password
          name="password"
          placeholder="Password"
          rules={[
            {
              required: true,
              message: 'Please enter password',
            },
          ]}
        />
        <Password
          name="password_confirm"
          placeholder="Confirm Password"
          rules={[
            {
              required: true,
              message: 'Please enter password for confirmation',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The values do not match'));
              },
            }),
          ]}
        />
        <div className={styles.pullRight}>
          <Link to="/login">Already have account</Link>
        </div>
        <Submit loading={submitting}>Submit</Submit>
      </LoginForm>
    </div>
  );
};

export default Forgot;
