import { PLAYLIST_TYPE } from 'utils/constant';
import * as Yup from 'yup';

export const userSchema = Yup.object().shape({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  username: Yup.string().required(),
  email: Yup.string(),
  verifiedAccount: Yup.boolean().default(false),
  verifiedCreator: Yup.boolean().default(false),
});

export const adminSchema = Yup.object().shape({
  email: Yup.string().email(),
  username: Yup.string(),
});

export const addBannerSchema = Yup.object().shape({
  image: Yup.string().required(),
  url: Yup.string().required(),
});

export const addGiftSchema = Yup.object().shape({
  image: Yup.string().required(),
  point: Yup.number('Input must a number').required(),
});

export const changPswSchema = Yup.object().shape({
  oldPsw: Yup.string().min(6).required('Please enter your old password!'),
  newPsw: Yup.string()
    .min(6)
    .when('oldPsw', (oldPsw, field) => (oldPsw ? field.required('Please enter your new password!') : field)),
  confirmPsw: Yup.string().when('newPsw', (newPsw, field) =>
    newPsw
      ? field.required('Please cofirm your new password!').oneOf([Yup.ref('newPsw')], 'Password must be the same!')
      : field
  ),
});

export const gameValidationSchema = Yup.object().shape({
  name: Yup.string().required('Game name is required'),
  description: Yup.string().required('Description is required'),
  image: Yup.string().required('Thumbnail is required'),
});

export const playlistValidationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  image: Yup.string().required('Cover art is required').nullable(),
  slug: Yup.string().required('Slug is required'),
  description: Yup.string(),
  type: Yup.string().required('Field is required!'),
  category: Yup.array(),
  userId: Yup.number().when('type', {
    is: (v) => v === PLAYLIST_TYPE.USER,
    then: Yup.number().positive('Price must be greater than 0'),
    otherwise: Yup.number().nullable(),
  }),
  price: Yup.number(),
  iosProductId: Yup.string().when('price', {
    is: (v) => v > 0,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
  androidProductId: Yup.string().when('price', {
    is: (v) => v > 0,
    then: Yup.string().required(),
    otherwise: Yup.string().nullable(),
  }),
  authorId: Yup.number().when('type', {
    is: (v) => v === PLAYLIST_TYPE.AUDIOBOOK,
    then: Yup.number().required(),
    otherwise: Yup.number().nullable(),
  }),
});
