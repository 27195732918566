import React from 'react';
import { Layout } from 'antd';
import AuthorizedTabs from 'components/Authorized/AuthorizedTabs';
import AdminContainer from './components/Admin';
import RoleContainer from './components/Role';

const TAB_LIST = [
  {
    key: 'admin',
    tab: 'Admin',
    component: <AdminContainer />,
  },
  {
    key: 'role',
    tab: 'Role',
    component: <RoleContainer />,
  },
];
const AdminManagement = () => {
  return (
    <Layout.Content>
      <AuthorizedTabs tabs={TAB_LIST} />
    </Layout.Content>
  );
};

export default AdminManagement;
