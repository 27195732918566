import { useMutation } from '@apollo/client';
import { Input, message, Modal } from 'antd';
import { useState } from 'react';
import { CLOSE_ROOM_TICKET, GET_LIST_ROOM_TICKET, GET_ROOM_TICKET } from './schema';

const CONFIRM_PASSWORD = 'permanently delete';

export default function ConfirmCancelallModal({ children, roomId }) {
  const [visible, setVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [closeRoom, { loading: closing }] = useMutation(CLOSE_ROOM_TICKET);

  const handleCancelAll = async () => {
    await closeRoom({
      variables: { adminCloseRoomId: roomId },
      onCompleted: () => message.success(`Room #${roomId} is closed`),
      onError: () => message.error(`Close room #${roomId} be failed`),
      refetchQueries: [GET_ROOM_TICKET, GET_LIST_ROOM_TICKET],
    });
    setVisible(false);
  };

  return (
    <>
      {children(() => setVisible(true))}
      <Modal
        centered
        okText="Confirm"
        okButtonProps={{ disabled: confirmPassword !== CONFIRM_PASSWORD, loading: closing }}
        title="Cancel all"
        visible={visible}
        destroyOnClose
        onOk={handleCancelAll}
        onCancel={() => setVisible(false)}
      >
        <Input.Password value={confirmPassword} onChange={({ target: { value } }) => setConfirmPassword(value)} />
        <p className="text-gray-500 select-none mt-2">
          Type <b>{`"${CONFIRM_PASSWORD}"`}</b> to cancel all
        </p>
      </Modal>
    </>
  );
}
