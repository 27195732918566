import UserLayout from 'layouts/UserLayout';
import BasicLayout from 'layouts/BasicLayout';
import SecurityLayout from 'layouts/SecurityLayout';
import NotFound from 'components/NotFound';
import Auth from 'containers/Auth';
import Forgot from 'containers/Auth/Forgot';
import Recover from 'containers/Auth/Recover';
import User from 'containers/User';
import UserInfo from 'containers/UserInfo';
import CreateUser from 'containers/User/create';
import Room from 'containers/Room';
import Dashboard from 'containers/Dashboard';
import PaymentRequest from 'containers/PaymentRequest';
import MasterSetting from 'containers/MasterSetting';
import EditRoom from 'containers/Room/edit';
import Report from 'containers/Report';
import Camp from 'containers/Camp';
import Profile from 'containers/Profile';
import GameRoom from 'containers/GameRoom';
import AdminManagement from 'containers/AdminManagement';
import { PERMISSION_LIST } from 'utils/constant';
import TicketShow from 'containers/TicketShow';
import Playlist from 'containers/Playlist';
import PlaylistDetail from 'containers/PlaylistDetail';
import Comments from 'containers/Comments';
import Wave from 'containers/Wave';
import Coupon from 'containers/Coupon';
import Decoration from 'containers/Decoration';
import Tools from 'containers/Tools';

const routes = [
  {
    path: '/login',
    component: UserLayout,
    routes: [
      {
        name: 'Login',
        path: '/login',
        component: Auth,
      },
    ],
  },
  {
    path: '/password',
    component: UserLayout,
    routes: [
      {
        name: 'Forgot',
        path: '/password/forgot',
        component: Forgot,
      },
      {
        name: 'Recover',
        path: '/password/recover',
        component: Recover,
      },
    ],
  },
  {
    path: '/',
    component: SecurityLayout,
    routes: [
      {
        path: '/',
        component: BasicLayout,
        routes: [
          {
            path: '/',
            exact: true,
            redirect: '/dashboard',
          },
          {
            path: '/dashboard',
            name: 'Dashboard',
            icon: 'bar-chart',
            component: Dashboard,
          },
          {
            path: '/room',
            query: '?status=open&status=live',
            name: 'Room',
            icon: 'database',
            exact: true,
            component: Room,
            authority: PERMISSION_LIST.MANAGE_ROOM,
          },
          {
            path: '/room/edit/:id',
            name: 'Edit Room',
            hideInMenu: true,
            component: EditRoom,
            authority: PERMISSION_LIST.MANAGE_ROOM,
          },
          {
            path: '/user',
            query: '?status=active',
            name: 'User',
            icon: 'user',
            exact: true,
            component: User,
            authority: PERMISSION_LIST.MANAGE_USER,
          },
          {
            path: '/user/info/:id',
            name: 'User Info',
            hideInMenu: true,
            component: UserInfo,
            authority: PERMISSION_LIST.MANAGE_USER,
          },
          {
            path: '/user/create',
            name: 'Create User',
            hideInMenu: true,
            component: CreateUser,
            authority: PERMISSION_LIST.MANAGE_USER,
          },
          {
            path: '/wave',
            name: 'Wave',
            icon: 'wave',
            component: Wave,
            authority: PERMISSION_LIST.MANAGE_WAVE,
          },
          {
            path: '/report',
            name: 'Report',
            icon: 'warning',
            component: Report,
            authority: PERMISSION_LIST.MANAGE_REPORT,
          },
          {
            path: '/payment',
            name: 'Payment Request',
            icon: 'money',
            component: PaymentRequest,
            authority: PERMISSION_LIST.MANAGE_PAYMENT,
          },
          {
            path: '/camp',
            name: 'Camp',
            icon: 'home',
            component: Camp,
            authority: PERMISSION_LIST.MANAGE_CAMP,
          },
          {
            path: '/comment',
            name: 'Comment',
            icon: 'comment',
            exact: true,
            component: Comments,
            authority: PERMISSION_LIST.MANAGE_COMMENT,
          },
          {
            path: '/admin',
            name: 'Admin',
            icon: 'user',
            exact: true,
            component: AdminManagement,
            authority: PERMISSION_LIST.MANAGE_ADMIN,
          },
          // {
          //   path: '/notification',
          //   //Notification management
          //   name: 'Notification',
          //   icon: 'notification',
          //   component: Notification,
          //   authority: 'manage-notification',
          // },
          {
            path: '/ticket-show',
            name: 'Ticket show',
            icon: 'ticket',
            component: TicketShow,
            authority: PERMISSION_LIST.MANAGE_ROOM,
          },
          {
            path: '/account/profile',
            name: 'Profile',
            hideInMenu: true,
            icon: 'user',
            component: Profile,
          },
          {
            path: '/game-room',
            name: 'Game room',
            icon: 'game-room',
            exact: true,
            component: GameRoom,
            authority: PERMISSION_LIST.MANAGE_GAME,
          },
          {
            path: '/playlist',
            name: 'Playlist',
            icon: 'playlist',
            exact: true,
            component: Playlist,
            authority: PERMISSION_LIST.MANAGE_PLAYLIST,
          },
          {
            path: '/playlist/detail/:id',
            name: 'Detail',
            hideInMenu: true,
            component: PlaylistDetail,
            authority: PERMISSION_LIST.MANAGE_PLAYLIST,
          },
          {
            path: '/setting',
            name: 'Master Setting',
            icon: 'setting',
            component: MasterSetting,
            authority: PERMISSION_LIST.MANAGE_SETTING,
          },
          {
            path: '/coupon',
            name: 'Coupon',
            icon: 'coupon',
            component: Coupon,
            authority: PERMISSION_LIST.MANAGE_COUPON,
          },
          {
            path: '/decoration',
            name: 'Decoration',
            icon: 'decoration',
            component: Decoration,
            authority: PERMISSION_LIST.MANAGE_USER,
          },
          {
            path: '/tools',
            name: 'Tools',
            icon: 'tool',
            component: Tools,
          },
          {
            component: NotFound,
          },
        ],
      },
      {
        component: NotFound,
      },
    ],
  },
  {
    component: NotFound,
  },
];

export default routes;
