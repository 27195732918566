const checkPermissions = (permission, permissionList, localItem, target) => {
  if (permission === undefined) {
    return localItem;
  }
  if (permissionList[permission]) return localItem;
  return target;
};

export { checkPermissions };

function check(permission, permissionList, localItem = 'NULL', target) {
  return checkPermissions(permission, permissionList, localItem, target);
}
export default check;
