import { Divider, Spin } from 'antd';
import React from 'react';
import { useQuery } from '@apollo/client';
import { ADMIN_GET_PLAYLIST } from 'containers/Playlist/schema';
import { OPERATOR } from 'utils/constant';
import RecordTable from './components/RecordTable';
import PlaylistEdit from './components/PlaylistEdit';

const TabEdit = ({ playlistId }) => {
  const { data, loading } = useQuery(ADMIN_GET_PLAYLIST, {
    skip: !playlistId,
    fetchPolicy: 'cache-and-network',
    variables: { filter: [{ field: 'id', operator: OPERATOR.eq, value: playlistId }] },
  });

  const playlistData = data?.adminGetPlaylist?.results?.[0];
  if (loading && !data) return <Spin />;

  return (
    <div>
      <p className="uppercase text-2xl font-bold">{playlistData?.type}</p>
      <PlaylistEdit data={playlistData} />
      <Divider />
      <RecordTable data={playlistData} />
    </div>
  );
};

export default TabEdit;
