import { PlusOutlined, EditOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Button, message, Avatar, Tag, Popconfirm } from 'antd';
import React from 'react';
import { gql, useMutation } from '@apollo/client';
import StandardTable from 'components/StandardTable';
import { Link } from 'react-router-dom';
import { PREMIUM_TYPE } from 'utils/constant';

const DEL_USERS = gql`
  mutation deleteUser($id: Int!) {
    adminDeleteUser(id: $id) {
      id
      username
      status
    }
  }
`;

const STATUS_COLOR = {
  active: 'green',
  deleted: '',
  banned: 'red',
};

const UserTable = ({ data, pagination, onChange }) => {
  const [adminDeleteUser] = useMutation(DEL_USERS);

  const deleteUser = (id) => {
    adminDeleteUser({ variables: { id } });
    message.success('User updated!');
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
    },
    {
      title: 'Avatar',
      dataIndex: 'avatar',
      key: 'avatar',
      width: 80,
      render: (val) => <Avatar icon={<UserOutlined />} src={val} />,
    },
    {
      title: 'Username',
      key: 'username',
      render: (value) => (
        <Link key="edit" to={`/user/info/${value?.id}`}>
          {value?.username}
        </Link>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'First name',
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      key: 'lastName',
      sorter: true,
    },
    {
      title: 'Premium Type',
      dataIndex: 'premiumType',
      key: 'premiumType',
      width: 150,
      filters: [
        { text: 'Free', value: 'free' },
        { text: 'Premium', value: 'vip' },
      ],
      render: (val) => (val === PREMIUM_TYPE.VIP ? <Tag color="gold">Premium</Tag> : val),
    },
    {
      title: 'Premium Exp',
      dataIndex: 'premiumExp',
      key: 'premiumExp',
      width: 150,
      render: (val) => (val ? <span>{moment(val).format('DD/MM/YYYY')}</span> : ''),
    },
    {
      title: 'Date Joined',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (val) => <span>{moment(val).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 80,
      filters: [
        { text: 'Active', value: 'active' },
        { text: 'Deleted', value: 'deleted' },
        { text: 'Banned', value: 'banned' },
      ],
      defaultFilteredValue: ['active'],
      render: (val) => <Tag color={STATUS_COLOR[val]}>{val}</Tag>,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (val, record) => (
        <>
          <Link key="edit" to={`/user/info/${val}`}>
            <EditOutlined style={{ fontSize: 20 }} />
          </Link>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => deleteUser(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  return (
    <div>
      <StandardTable
        headerTitle="Users"
        rowKey="id"
        renderToolbar={() => [
          <Button type="primary" key="primary">
            <Link key="create" to="/user/create">
              <PlusOutlined /> Add
            </Link>
          </Button>,
        ]}
        dataSource={data}
        columns={columns}
        pagination={pagination}
        onChange={onChange}
      />
    </div>
  );
};

export default UserTable;
