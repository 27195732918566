import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Table, Typography } from 'antd';
import { gql, useQuery } from '@apollo/client';
import moment from 'moment';

import { OPERATOR, EARNING_HISTORY_TYPE, EARNING_HISTORY_STATUS } from 'utils/constant';
import numeral from 'numeral';

const DATE_FORMAT = 'DD/MM/YYYY';

const ADMIN_GET_EARNING = gql`
  query adminGetEarningHistory($filter: JSON!, $page: Int, $pageSize: Int) {
    adminGetEarningHistory(filter: $filter, page: $page, pageSize: $pageSize) {
      results {
        type
        total
        created
      }
      total
      currentPage
      pageSize
    }
  }
`;

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters)
    .filter((v) => filters[v])
    .forEach((filter) => {
      const value = filters[filter];
      switch (filter) {
        case 'type':
          newFilter.push({ field: filter, operator: OPERATOR.in, value });
          break;
        case 'status':
        case 'userId':
          newFilter.push({ field: filter, operator: OPERATOR.eq, value });
          break;
        default:
          break;
      }
    });

  return newFilter;
};

const EarningHistory = ({ user }) => {
  const [statusMoney, setStatusMoney] = useState(Object.values(EARNING_HISTORY_STATUS)[0]);

  const { data, refetch, loading } = useQuery(ADMIN_GET_EARNING, {
    variables: {
      filter: generateFilter({ userId: user.id, status: statusMoney }),
    },
    skip: !user?.id,
  });

  const { results, ...paginationData } = data?.adminGetEarningHistory || {};
  const { total = 30, currentPage = 0, pageSize = 30 } = paginationData;

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      filters: Object.keys(EARNING_HISTORY_TYPE).map((key) => ({
        text: EARNING_HISTORY_TYPE[key],
        value: key,
      })),
      render: (type) => EARNING_HISTORY_TYPE[type],
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      render: (value) => (
        <div style={{ color: `${value > 0 ? '#27AE60' : '#EB5757'}` }}>{`${value > 0 ? '+' : ''}${value / 100}$`}</div>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'created',
      render: (value) => moment(value).format(DATE_FORMAT),
    },
  ];

  const handleChangePage = (pagination, filter) => {
    const { current, pageSize: pS } = pagination;
    const currentFilter = generateFilter({ ...filter, userId: user.id });
    refetch({ page: current - 1, pageSize: pS, filter: currentFilter });
  };

  const totalMoney = {
    [EARNING_HISTORY_STATUS.COMPLETED]: user?.earning,
    [EARNING_HISTORY_STATUS.PENDING]: user?.pendingEarning,
  }[[statusMoney]];

  return (
    <div>
      <Radio.Group value={statusMoney} onChange={({ target }) => setStatusMoney(target.value)} buttonStyle="solid">
        {Object.values(EARNING_HISTORY_STATUS).map((status) => (
          <Radio.Button className="capitalize" key={status} value={status}>
            {status}
          </Radio.Button>
        ))}
      </Radio.Group>
      <div>
        <Typography.Title level={5} className="mt-2">
          {typeof totalMoney !== 'undefined' &&
            `Total ${statusMoney}: ${numeral(Math.abs(totalMoney / 100.0)).format('$0,0.00')}`}
        </Typography.Title>
      </div>
      <Table
        rowKey={(record) => `${record.created}${record.type}`}
        columns={columns}
        loading={loading}
        dataSource={results}
        pagination={{
          total,
          showSizeChanger: false,
          defaultPageSize: pageSize,
          current: currentPage + 1,
        }}
        onChange={handleChangePage}
      />
    </div>
  );
};

EarningHistory.propTypes = {
  user: PropTypes.object,
};

export default EarningHistory;
