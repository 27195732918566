import { useMutation, useQuery } from '@apollo/client';
import { Input, Popconfirm, Rate, message } from 'antd';
import StandardTable from 'components/StandardTable';
import moment from 'moment';
import React, { useMemo } from 'react';
import useFilter from 'utils/hooks/useFilter';
import { Link } from 'react-router-dom';
import { MAX_NUMERIC_VALUE, OPERATOR } from 'utils/constant';
import { getOperationName } from '@apollo/client/utilities';
import { DeleteOutlined } from '@ant-design/icons';
import isNaN from 'lodash/isNaN';
import { ADMIN_DELETE_REVIEWS, ADMIN_GET_REVIEWS } from './schema';

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters).forEach((filter) => {
    const value = filters[filter]?.trim?.();
    if (!value) return;
    switch (filter) {
      case 'search':
        newFilter.push({
          operator: OPERATOR.or,
          value: [
            ...(!isNaN(+value) && Number(value) <= MAX_NUMERIC_VALUE
              ? [{ field: 'id', operator: OPERATOR.eq, value }]
              : []),
            { field: 'content', operator: OPERATOR.contains, value },
          ],
        });
        break;
      default:
        break;
    }
  });

  return newFilter;
};

const TabReview = ({ playlistId }) => {
  const { querySearch, onSearch } = useFilter(['search']);
  const filters = useMemo(() => generateFilter({ search: querySearch?.search }), [querySearch]);
  const { data: dataGetReviews, loading } = useQuery(ADMIN_GET_REVIEWS, {
    variables: {
      skip: !playlistId,
      filter: [{ field: 'playlistId', operator: OPERATOR.eq, value: playlistId }, ...filters],
    },
    onError: (error) => {
      const resultError = error?.networkError?.result?.errors?.[0]?.message;
      message.error(resultError || error?.message || 'Something went wrong');
    },
  });
  const [adminDeleteReview, { loading: deleting }] = useMutation(ADMIN_DELETE_REVIEWS, {
    onCompleted: () => message.success('Deleted successfully'),
    onError: (error) => {
      if (error.message === 'REVIEW_NOT_FOUND') message.error('Review not found');
      else message.error('Something went wrong');
    },
  });

  const data = dataGetReviews?.[getOperationName(ADMIN_GET_REVIEWS)] || {};

  const handleDeleteReview = (reviewId) => {
    adminDeleteReview({
      variables: { adminDeleteReviewId: reviewId },
      update(cache, { data: dataAdminDeleteReview }) {
        if (dataAdminDeleteReview?.adminDeleteReview?.id) {
          const normalizedId = cache.identify({ id: reviewId, __typename: 'Review' });
          if (normalizedId) {
            cache.evict({ id: normalizedId });
            cache.gc();
          }
        }
      },
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator',
      render: (val) => <Link to={`/report?page=1&creatorId=${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Content',
      dataIndex: 'content',
      key: 'content',
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      render: (val) => <Rate value={val} disabled style={{ fontSize: 16 }} />,
    },
    {
      title: 'Helpful',
      dataIndex: 'helpfulCount',
      key: 'helpfulCount',
    },
    {
      title: 'Created',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (val) => <span>{moment(val).format('DD/MM/YYYY, HH:mm')}</span>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (val, record) => (
        <Popconfirm
          title="Are you sure to delete this review?"
          onConfirm={() => handleDeleteReview(record.id)}
          okText="Delete"
          cancelText="No"
          okButtonProps={{ loading: deleting }}
        >
          <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <div className="flex">
        <Input.Search
          placeholder="id, content"
          defaultValue={querySearch?.search}
          style={{ width: 400 }}
          onPressEnter={(event) => onSearch({ search: event.target.value, page: 1 })}
          onSearch={(event) => onSearch({ search: event.target.value, page: 1 })}
        />
        <div className="p-2 py-1 rounded-sm border border-gray-200 text-gray-500">Total: {data?.total}</div>
      </div>
      <StandardTable
        rowKey="id"
        loading={loading}
        dataSource={data?.results || []}
        columns={columns}
        pagination={{
          reset: true,
          defaultPageSize: 20,
          total: data?.total || 0,
        }}
      />
    </div>
  );
};

export default TabReview;
