import { Layout } from 'antd';
import AuthorizedTabs from 'components/Authorized/AuthorizedTabs';
import React from 'react';
import BorderDecoration from './BorderDecoration';

const tablist = [
  {
    key: 'border',
    tab: 'Border',
    component: <BorderDecoration />,
  },
];

const Decoration = () => {
  return (
    <Layout.Content>
      <AuthorizedTabs tabs={tablist} />
    </Layout.Content>
  );
};

export default Decoration;
