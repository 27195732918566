import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePrevious } from './usePrevious';

export const useScrollToTop = ({ ref }) => {
  const { pathname } = useLocation();
  const previousPathname = usePrevious(pathname);

  useLayoutEffect(() => {
    if (pathname === previousPathname || !ref?.current) {
      return;
    }

    window.scrollTo(0, 0);

    const clearTimer = setTimeout(() => {
      ref.current.focus();
    }, 100);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(clearTimer);
    };
  }, [pathname, previousPathname, ref]);
};
