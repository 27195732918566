import React from 'react';
import { Layout } from 'antd';
import AuthorizedTabs from 'components/Authorized/AuthorizedTabs';
import Default from './default';
import Category from './category';
import Series from './series';
import Author from './author';

const Playlist = () => {
  const tabs = [
    {
      key: 'playlist',
      tab: 'Playlist',
      component: <Default />,
    },
    {
      key: 'series',
      tab: 'Series',
      component: <Series />,
    },
    {
      key: 'category',
      tab: 'Category',
      component: <Category />,
    },
    {
      key: 'author',
      tab: 'Author',
      component: <Author />,
    },
  ];

  return (
    <Layout.Content>
      <AuthorizedTabs tabs={tabs} />
    </Layout.Content>
  );
};

export default Playlist;
