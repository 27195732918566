import { Button } from 'antd';
import { NumberInput, SelectInput, TextFieldInput } from 'components/Input';
import { FastField, Formik } from 'formik';
import React, { useState } from 'react';
import { tts } from 'utils/request';
import * as Yup from 'yup';
import { DownloadOutlined } from '@ant-design/icons';

const voices = ['alloy', 'echo', 'fable', 'onyx', 'nova', 'shimmer'];
const models = ['tts-1', 'tts-1-hd'];
const DEFAULT_VALUES = {
  voice: voices[0],
  speed: 1,
  model: models[0],
};

const validationSchema = Yup.object().shape({
  text: Yup.string().required(),
});

const TTS = () => {
  const [audioUrl, setAudioUrl] = useState(null);

  const onSubmit = async (values) => {
    const blob = await tts(values);
    console.log({ type: blob.type });
    if (audioUrl) URL.revokeObjectURL(audioUrl);
    if (blob) {
      const url = URL.createObjectURL(blob);
      setAudioUrl(url);
    }
  };

  const onDowload = () => {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', audioUrl);
    anchor.setAttribute('download', 'audio.mp3');
    anchor.click();
  };

  return (
    <div>
      <Formik initialValues={DEFAULT_VALUES} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ handleSubmit, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FastField
                title="Model"
                name="model"
                options={models.map((i) => ({ label: i, value: i }))}
                component={SelectInput}
              />
              <FastField
                title="Speed"
                name="speed"
                placeholder="0.25 - 4.0"
                min={0.25}
                max={4}
                component={NumberInput}
              />
              <FastField
                title="Voice"
                name="voice"
                options={voices.map((i) => ({ label: i, value: i }))}
                component={SelectInput}
              />
              <FastField
                placeholder="Press text here..."
                title="Text"
                rows={10}
                maxLength={4000}
                name="text"
                component={TextFieldInput}
              />
              <Button loading={isSubmitting} type="primary" htmlType="submit">
                Export
              </Button>

              {audioUrl && !isSubmitting && (
                <div className="mt-12 flex items-center gap-3">
                  <audio src={audioUrl} controls />
                  <Button onClick={onDowload} icon={<DownloadOutlined />} />
                </div>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default TTS;
