import { GiftOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, message, Typography, Button } from 'antd';
import AddPoinModal from 'containers/Point/components/AddPointModal';
import moment from 'moment';

import { OPERATOR, POINT_HISTORY_TYPE } from 'utils/constant';
import { gql, useMutation, useQuery } from '@apollo/client';

const DATE_FORMAT = 'DD/MM/YYYY';

const ADMIN_GET_POINT_HISTORY = gql`
  query adminGetPointHistoryList($filter: JSON, $page: Int, $pageSize: Int) {
    adminGetPointHistoryList(filter: $filter, page: $page, pageSize: $pageSize) {
      results {
        id
        description
        type
        amount
        created
      }
      total
      currentPage
      pageSize
    }
  }
`;

const ADMIN_ADD_POINT = gql`
  mutation adminAddPoint($userId: Int!, $amount: Int!, $type: PointHistoryType!, $description: String) {
    adminAddPoint(userId: $userId, amount: $amount, type: $type, description: $description) {
      id
      description
      type
      amount
      created
    }
  }
`;

const generateFilter = (filters) => {
  const newFilter = [];
  Object.keys(filters)
    .filter((v) => filters[v])
    .forEach((filter) => {
      const value = filters[filter];
      switch (filter) {
        case 'type':
          newFilter.push({ field: filter, operator: OPERATOR.in, value });
          break;
        case 'userId':
          newFilter.push({ field: filter, operator: OPERATOR.eq, value });
          break;
        default:
          break;
      }
    });

  return newFilter;
};

const PointTable = ({ user }) => {
  const [visible, setVisible] = useState(false);

  const { data, refetch } = useQuery(ADMIN_GET_POINT_HISTORY, {
    variables: {
      filter: generateFilter({ userId: user.id }),
    },
    skip: !user?.id,
  });

  const [addPoint, { loading: mutationLoading }] = useMutation(ADMIN_ADD_POINT);
  const { results, ...paginationData } = data?.adminGetPointHistoryList || {};
  const { total = 30, currentPage = 0, pageSize = 30 } = paginationData;

  const handleAddPoint = ({ amount, description, type }) => {
    addPoint({
      variables: {
        userId: user.id,
        amount,
        type,
        description,
      },
      refetchQueries: [ADMIN_GET_POINT_HISTORY, 'adminGetPointHistoryList'],
    });
    setVisible(false);
  };
  const columns = [
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      filters: Object.keys(POINT_HISTORY_TYPE).map((key) => ({
        text: POINT_HISTORY_TYPE[key],
        value: key,
      })),
      render: (type) => POINT_HISTORY_TYPE[type],
    },
    {
      title: 'Point',
      dataIndex: 'amount',
      render: (value) => (
        <div style={{ color: `${value > 0 ? '#27AE60' : '#EB5757'}` }}>{`${value > 0 ? '+' : ''}${value}`}</div>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'created',
      render: (value) => moment(value).format(DATE_FORMAT),
    },
  ];

  const handleChangePage = (pagination, filter) => {
    const { current, pageSize: pS } = pagination;
    const currentFilter = generateFilter({ ...filter, userId: user.id });
    refetch({ page: current - 1, pageSize: pS, filter: currentFilter });
  };

  return (
    <>
      <div className="flex justify-between mb-2 items-center">
        <Typography.Title level={5} className="mt-2">
          Total point: {user?.point || 0}
        </Typography.Title>
        <Button icon={<GiftOutlined />} onClick={() => setVisible(true)} className="flex justify-center items-center">
          Add point
        </Button>
      </div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={results}
        pagination={{
          total,
          showSizeChanger: false,
          defaultPageSize: pageSize,
          current: currentPage + 1,
        }}
        onChange={handleChangePage}
      />
      {visible && (
        <AddPoinModal
          headerTitle="Add Point"
          isMulti={false}
          visible={visible}
          user={user}
          isLoading={mutationLoading}
          onCancel={() => {
            setVisible(false);
          }}
          onSubmit={handleAddPoint}
        />
      )}
    </>
  );
};

PointTable.propTypes = {
  user: PropTypes.object,
};

export default PointTable;
