import React, { useMemo } from 'react';
import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import AuthorizedTabs from 'components/Authorized/AuthorizedTabs';
import { PERMISSION_LIST } from 'utils/constant';
import IntimateStranger from './components/IntimateStranger';
import Topic from './components/Topic';
import Exchange from './components/Exchange';
import Gift from './components/Gift';
import Banner from './components/Banner';
import { GET_MASTER_SETTING_LIST } from './schema';
import BookCategory from './components/BookCategory';

export const TABS = {
  banner: 'banner',
  intimate: 'intimate',
  topic: 'topic',
  exchange: 'exchange',
  gift: 'gift',
  bookCategory: 'book-catergory',
};

const Tabs = (props) => {
  const { data, loading, error } = useQuery(GET_MASTER_SETTING_LIST, {
    variables: { keys: ['enable_intimate_stranger', 'banners'] },
    pollInterval: 5000,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const intimate = useMemo(() => data?.getMasterSettingList.find((ms) => ms.key === 'enable_intimate_stranger'), [
    data?.getMasterSettingList,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const banners = useMemo(() => data?.getMasterSettingList.find((ms) => ms.key === 'banners'), [
    data?.getMasterSettingList,
  ]);

  const tablist = [
    {
      key: TABS.banner,
      tab: 'Banner',
      component: loading && !banners ? <Spin /> : <Banner {...props} data={banners} error={error} />,
    },
    {
      key: TABS.intimate,
      tab: 'Intimate Stranger',
      component: <IntimateStranger {...props} loading={loading} error={error} intimate={intimate} />,
    },
    { key: TABS.topic, tab: 'Topic', component: <Topic {...props} /> },
    { key: TABS.exchange, tab: 'Exchange', component: <Exchange {...props} /> },
    { key: TABS.gift, tab: 'Gift', component: <Gift {...props} />, authority: PERMISSION_LIST.MANAGE_GIFT },
    {
      key: TABS.bookCategory,
      tab: 'Book category',
      component: <BookCategory {...props} />,
      authority: PERMISSION_LIST.MANAGE_PLAYLIST,
    },
  ];

  return (
    <Layout.Content>
      <AuthorizedTabs tabs={tablist} />
    </Layout.Content>
  );
};

Tabs.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default Tabs;
