import React from 'react';
import { Upload, Button } from 'antd';
import { SoundOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { customRequest } from 'utils/helpers';

const UploadAudioFormik = ({ form, field, fieldNameDuration, required, className = '', title }) => {
  const { name, value } = field;
  const { errors, setFieldValue } = form || {};

  const onAddAudio = async (info) => {
    if (info?.file?.status === 'done') {
      if (fieldNameDuration) {
        setFieldValue(fieldNameDuration, info?.file?.response?.duration);
      }
      setFieldValue(name, info?.file?.response?.recordingUrl);
    }
  };

  const onRemoveItem = () => setFieldValue(name, null);
  return (
    <div className={classNames('relative', className)}>
      {title && (
        <div className="text-gray mb-2 font-bold h-5 text-18">
          {title} {required && <span className="text-red-400">*</span>}
        </div>
      )}
      <Upload
        accept="audio/*"
        maxCount={1}
        name="recording"
        multiple={false}
        defaultFileList={
          value && [
            {
              uid: value,
              name: value,
              status: 'done',
              url: value,
            },
          ]
        }
        customRequest={customRequest}
        onChange={onAddAudio}
        onRemove={onRemoveItem}
      >
        <Button style={{ display: 'flex', alignItems: 'center' }} icon={<SoundOutlined />}>
          Upload audio
        </Button>
      </Upload>

      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">{errors[name]}</div>
    </div>
  );
};

export default UploadAudioFormik;
