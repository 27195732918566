import React, { useState, useEffect } from 'react';
import { Layout, Card, Input, Form, Button, Select, message } from 'antd';
import { gql, useQuery, useMutation } from '@apollo/client';
import qs from 'qs';
import { Link, useParams, useHistory } from 'react-router-dom';
import { OPERATOR } from 'utils/constant';

const { Search } = Input;
const { Option } = Select;

const CRE_USER = gql`
  mutation adminCreateUser($input: AdminUpdateUserInput!) {
    adminCreateUser(input: $input) {
      id
      username
      status
      avatar
    }
  }
`;

const ALL_USERS = gql`
  query adminGetUserList($filter: JSON, $pageSize: Int, $page: Int) {
    adminGetUserList(filter: $filter, pageSize: $pageSize, page: $page) {
      results {
        id
        username
        status
        avatar
      }
      total
      currentPage
      pageSize
    }
  }
`;

const CreateUser = ({ location }) => {
  const history = useHistory();
  const { id } = useParams();

  const [addUser, { data }] = useMutation(CRE_USER);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    addUser({
      variables: { input: values },
      refetchQueries: [{ query: ALL_USERS, variables: { page: 0, pageSize: 30 } }],
    });
    form.resetFields();
    message.success('User created!');
    history.push('/user');
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Layout.Content>
      <Card>
        <Form {...layout} form={form} name="user-form" onFinish={onFinish}>
          <Form.Item name="username" label="Username" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: true }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="email" label="Email" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="firstName" label="Firstname" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="lastName" label="Lastname" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone" rules={[{ required: false }]}>
            <Input />
          </Form.Item>
          <Form.Item name="gender" label="Gender" rules={[{ required: false }]}>
            <Select placeholder="Select a option and change input text above" allowClear>
              <Option value="male">male</Option>
              <Option value="female">female</Option>
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button htmlType="button" onClick={onReset} style={{ marginRight: 8 }}>
              Reset
            </Button>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout.Content>
  );
};

export default CreateUser;
