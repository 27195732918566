import { useRecoilValue } from 'recoil';
import { authState } from 'store/atom';

export const useCheckPermission = (permission) => {
  const { permissions = [] } = useRecoilValue(authState);

  if (permission) {
    const found = permissions.find((item) => item.code === permission);

    if (found && found.allow) {
      return true;
    }
  }
  return false;
};

export const useSetPermission = (permissionsRequestList) => {
  const { permissions = [] } = useRecoilValue(authState);
  const permissionList = {};

  permissions?.forEach((permission) => {
    for (const permissionRequest in permissionsRequestList) {
      if (permission.code === permissionsRequestList[permissionRequest])
        permissionList[permission.code] = permission.allow;
    }
  });
  return permissionList;
};
