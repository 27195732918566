import React from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';
import AuthorizedTabs from 'components/Authorized/AuthorizedTabs';
import { PERMISSION_LIST } from 'utils/constant';
import TabEdit from './TabEdit';
import TabReview from './TabReview';

const PlaylistDetail = () => {
  const { id } = useParams();
  const TAB_LIST = [
    {
      key: 'edit',
      tab: 'Edit',
      component: <TabEdit playlistId={Number(id)} />,
      authority: PERMISSION_LIST.MANAGE_PLAYLIST,
    },
    {
      key: 'review',
      tab: 'Review',
      component: <TabReview playlistId={Number(id)} />,
      authority: PERMISSION_LIST.MANAGE_PLAYLIST,
    },
  ];

  return (
    <Layout className="flex flex-col px-4 gap-y-8">
      <AuthorizedTabs tabs={TAB_LIST} />
    </Layout>
  );
};

export default PlaylistDetail;
