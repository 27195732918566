import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import cl from 'classnames';
import { ErrorMessage } from 'formik';

const { TextArea } = Input;

const TextFieldInput = ({ field, form, title = '', className = '', required, ...rest }) => {
  const { errors } = form;
  const { name } = field;
  return (
    <div className="flex flex-col">
      <span className="text-gray mb-2 font-bold h-5 text-18">
        {title} {required && <span className="text-red-400">*</span>}
      </span>
      <TextArea
        {...field}
        {...rest}
        style={errors[name] ? { border: '2px solid red' } : {}}
        className={cl(className)}
      />
      <div className="text-red-500 mt-0.5 mb-1 font-normal h-5">
        <ErrorMessage name={name} />
      </div>
    </div>
  );
};

TextFieldInput.prototype = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default TextFieldInput;
