import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { authState } from 'store/atom';
import { login, loginSet, logoutUnset, forgot, recover } from 'utils/request';
import client from 'store/apolloClient';

export const useLogin = () => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const setLoginState = useSetRecoilState(authState);

  const loginUser = async ({ username, password }) => {
    try {
      setSubmitting(true);
      const rel = await login(username, password);
      const { token, admin, permissions } = rel;
      loginSet({ token, admin });
      setLoginState({ token, admin, permissions });
      setSubmitting(false);
      history.push('/dashboard', history.location);
    } catch (err) {
      setSubmitting(false);
      setError(err);
    }
  };

  const logoutUser = async () => {
    try {
      setSubmitting(true);
      client.resetStore();
      logoutUnset();
      setSubmitting(false);
      history.push('/login');
    } catch (err) {
      setSubmitting(false);
      setError(err);
    }
  };

  return { loginUser, logoutUser, submitting, error };
};

export const useForgot = () => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState(null);

  const forgotPassword = async ({ username }) => {
    try {
      setSuccess(null);
      setSubmitting(true);
      const result = await forgot(username);
      setSuccess(result.success);
      setMessage(result.message);
      setSubmitting(false);
    } catch (err) {
      setSuccess(false);
      const result = await err.response.json();
      setMessage(result.message);
      setSubmitting(false);
    }
  };

  return { forgotPassword, submitting, message, success };
};

export const useRecover = () => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState(null);

  const recoverPassword = async ({ token, password }) => {
    try {
      setSuccess(null);
      setSubmitting(true);
      const result = await recover(token, password);
      setSuccess(result.success);
      setMessage(result.message);
      setSubmitting(false);
    } catch (err) {
      setSuccess(false);
      const result = await err.response.json();
      setMessage(result.message);
      setSubmitting(false);
    }
  };

  return { recoverPassword, submitting, message, success };
};
