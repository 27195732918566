import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { message, Popconfirm, Table } from 'antd';
import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { OPERATOR } from 'utils/constant';

const USER_REPORT = gql`
  query getReports($filter: JSON, $pageSize: Int, $page: Int) {
    getReports(filter: $filter, pageSize: $pageSize, page: $page) {
      total
      currentPage
      results {
        id
        reason
        category
        userId
        created
        creator {
          id
          username
        }
        user {
          id
          username
        }
        room {
          id
          title
        }
      }
    }
  }
`;

const ADMIN_DELETE_REPORT = gql`
  mutation adminDeleteReport($id: Int!) {
    deleteReport(id: $id) {
      id
    }
  }
`;

const ReportTable = ({ userId }) => {
  const { data, error, loading, refetch } = useQuery(USER_REPORT, {
    variables: {
      page: 0,
      pageSize: 30,
      filter: [{ field: 'userId', operator: OPERATOR.eq, value: userId }],
    },
  });

  const [adminDeleteReport] = useMutation(ADMIN_DELETE_REPORT, {
    onCompleted: () => {
      message.success('Xóa thành công');
    },
    onError: (err) => {
      message.error(err.message);
    },
    refetchQueries: [USER_REPORT, 'getReports'],
  });

  if (!data || loading) {
    return null;
  }

  const handleChangePage = (page, pageS) => {
    refetch({ page: page - 1, pageSize: pageS });
  };

  const handleDeleteReport = (id) => {
    adminDeleteReport({ variables: { id } });
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator',
      render: (val, record) => <Link to={`/report?page=1&creatorId=${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Reason',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (val, record) => <Link to={`/report?page=1&userId=${val?.id}`}>{val?.username}</Link>,
    },
    {
      title: 'Room',
      dataIndex: 'room',
      key: 'room',
      render: (val, record) => <Link to={`/report?page=1&roomId=${val?.id}`}>{val?.title}</Link>,
    },
    {
      title: 'Day report',
      dataIndex: 'created',
      key: 'created',
      sorter: (a, b) => new Date(a.created) - new Date(b.created),
      render: (val) => <span>{moment(val).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'room',
      key: 'action',
      render: (val, record) => (
        <>
          <Link key="edit" to={record?.user?.id ? `/user/info/${record?.user.id}` : `/room/edit/${val?.id}`}>
            <EditOutlined />
          </Link>
          <Popconfirm
            title="Are you sure to delete this report?"
            onConfirm={() => handleDeleteReport(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ fontSize: 20, color: 'red', marginLeft: 15 }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  const { results, total, currentPage } = data?.getReports || {};

  return (
    <div>
      <Table
        rowKey="id"
        columns={columns}
        dataSource={results}
        pagination={{
          defaultPageSize: 30,
          showSizeChanger: false,
          total: total || 0,
          current: currentPage + 1,
          onChange: handleChangePage,
        }}
      />
    </div>
  );
};

export default ReportTable;
